<div class="headline">
  <label>{{ 'ERROR_DIALOG_CUSTOMER.HEADLINE' | translate }}</label>
</div>
<div class="[ content ]">
  <div *ngIf="error.status">
    <h5>{{ 'ERROR_DIALOG_CUSTOMER.TEXT' | translate }}</h5>
  </div>
</div>
<div class="[ form-content-modal ]">
  <button (click)="abbrechen()" class="[ c-mat-button no-color-button ]" mat-button>
    <i aria-hidden="true"></i>
    {{'BUTTON.SCHLIESSEN' | translate}}
  </button>
  <button (click)="showDetails()" class="[ c-mat-button green-button right-button ]" mat-flat-button>
    {{'ERROR_DIALOG_CUSTOMER.BUTTON' | translate}}
  </button>
</div>
