import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { DeviceConfiguration } from '../../model/deviceConfiguration';
import { TechnikerKey } from '../../model/techniker-key';
import { ServerPaginationService } from '../serverPaginationService';

@Injectable({
  providedIn: 'root'
})
export class DeviceConfigurationService implements ServerPaginationService<DeviceConfiguration> {

  private url = '/api/v1/deviceConfiguration';

  constructor(private http: HttpClient) {
  }

  /**
   * Liefert den QR-Code fuer den angegebenen Techniker.
   * @param technikerKey TechnikerKey
   *
   * @return Device-QR
   */
  getDeviceQR(technikerKey: TechnikerKey): Observable<any> {
    return this.http.get(`${this.url}/deviceQR/${JSON.stringify(technikerKey)}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource alle fuer den angemeldeten Benutzer sichtbaren
   * Devices.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   *
   * @return Observable<Page<DeviceConfiguration>>
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<DeviceConfiguration>> {
    return this.http.post<Page<DeviceConfiguration>>(this.url + '/listPage', searchFilterPagingResource);
  }

  /**
   * Aktualisiert eine DeviceConfiguration.
   * @param deviceConfiguration DeviceConfiguration
   */
  update(deviceConfiguration: DeviceConfiguration): Observable<DeviceConfiguration> {
    return this.http.put<DeviceConfiguration>(this.url, deviceConfiguration, {
      headers: new HttpHeaders().set('generateJwt', 'false')
    });
  }

  /**
   * Aktualisiert den QR-Code.
   * @param deviceConfiguration DeviceConfiguration
   */
  updateQRCode(deviceConfiguration: DeviceConfiguration): Observable<DeviceConfiguration> {
    return this.http.put<DeviceConfiguration>(this.url, deviceConfiguration, {
      headers: new HttpHeaders().set('generateJwt', 'true')
    });
  }
}
