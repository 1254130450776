<div class="[ page-count ]">
  <div>
    <span class="[ c-data-list__text-italic ]">{{ 'TABLE.PAGING.ANZAHL' | translate }} &nbsp;</span>
    <span class="[ c-data-list__text-bold ]">{{ selected.length ? selected.length : 0 }}</span>
    <span
      class="[ c-data-list__text-italic ]">&nbsp; {{ 'TABLE.PAGING.RESULT_TO' | translate }} &nbsp;</span>
    <span class="[ c-data-list__text-bold ]">{{ rows.length ? rows.length : 0}}</span>
  </div>
</div>

