import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomErrorHandlerService } from '../custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../moda-translate.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private readonly modaTranslateService: ModaTranslateService,
              private readonly customErrorHandlerService: CustomErrorHandlerService,
              private toastrService: ToastrService) {
  }

  /**
   * Zeigt eine Errorrmeldung an.
   *
   * @param messageKey Message-Key
   * @param params Parameters
   */
  showErrorNotification(messageKey: string, params?: any) {
    this.toastrService.error(this.modaTranslateService.translate(messageKey.toString(), params));

  }

  /**
   * Zeigt eine Info-Meldung an.
   *
   * @param messageKey Message-Key
   * @param params Parameters
   */
  showInfoNotification(messageKey: string, params?: any) {
    this.toastrService.info(this.modaTranslateService.translate(messageKey.toString(), params));
  }

  /**
   * Zeigt eine Erfolgsmeldung an.
   *
   * @param messageKey Message-Key
   * @param params Parameters
   */
  showSuccessNotification(messageKey: string, params?: any) {
    this.toastrService.success(this.modaTranslateService.translate(messageKey.toString(), params));
  }
}
