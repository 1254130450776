import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractDialog } from '../../core/component/dialog/abstract-dialog';
import { FilterType } from '../../core/filter/filterType';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { DatexPipe } from '../../core/pipe/datex.pipe';
import { Farm } from '../../model/farm';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { FarmService } from '../../service/farm/farm.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { Utils } from '../../utils/utils';
import { FarmDetailModalComponent } from '../farm/farm-detail-modal/farm-detail-modal.component';
import { SearchFieldDefinition } from '../search/search-field-definition';
import { VerlustuebersichtComponent } from '../verlustuebersicht/verlustuebersicht.component';

@Component({
  selector: 'portal-farmuebersicht-modal',
  templateUrl: './farmuebersicht-modal.component.html',
  styleUrls: ['./farmuebersicht-modal.component.scss']
})
export class FarmuebersichtModalComponent extends AbstractDialog<Farm> implements OnInit, OnDestroy {

  isBesamungstechniker: boolean;

  @ViewChild('searchFarmField', { static: true }) searchFarmField: any;

  sortField = [
    {
      prop: 'oldShortNumber',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly farmService: FarmService, private datexPipe: DatexPipe, private router: Router,
              private readonly route: ActivatedRoute, private readonly dialog: MatDialog,
              private readonly dialogRef: MatDialogRef<VerlustuebersichtComponent>,
              private readonly modaKeycloakService: ModaKeycloakService,
              @Inject(MAT_DIALOG_DATA) private readonly dialogData) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Schliesst den Modalen Dialog.
   */
  public abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Besteatigen Button.
   */
  bestaetigen() {
    this.dialogRef.close(this.selectedElement);
  }

  /**
   * Sortierung der Felder.
   * @param event Event
   */
  determineSortField(event): string {
    switch (event.column.prop) {
      case 'name':
        return 'adresse.nachname';
      case 'oldShortNumber':
        return 'oldShortNumber';
      case 'betriebsschluessel':
        return 'betriebsschluessel';
      default:
        return event.column.prop;
    }
  }

  /**
   * Updatet die Tabelle.
   *
   * @param data PageData
   */
  doAfterLoadData(data: Page<Farm>) {
    this.onSelect(null);
    this.calculateData(data);
    this.updatePage(data);
    this.tableIsLoading = false;
    if (data.content.length === 1) {
      this.selectedElement = data.content[0];
      this.bestaetigen();
    }
  }

  /**
   *  {@inheritDoc}.
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    if (this.isBesamungstechniker) {
      this.loadDataSubscription = this.farmService.loadDataForUser(searchFilterPagingResource).subscribe(data => {
        this.doAfterLoadData(data);
      }, error => this.customErrorHandlerService.handleError(error));
    } else {
      this.loadDataSubscription = this.farmService.loadData(searchFilterPagingResource).subscribe(data => {
        this.doAfterLoadData(data);
      }, error => this.customErrorHandlerService.handleError(error));
    }
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('oldShortNumber', FilterType.STRING),
      new SearchFieldDefinition('betriebsschluessel', FilterType.STRING),
      new SearchFieldDefinition('nachname', FilterType.STRING),
      new SearchFieldDefinition('vorname', FilterType.STRING),
      new SearchFieldDefinition('strasseHausnummer', FilterType.STRING),
      new SearchFieldDefinition('ort', FilterType.STRING),
      new SearchFieldDefinition('shortAddress', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  public getService(): ServerPaginationService<Farm> {
    return this.farmService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return [
      {
        prop: 'oldShortNumber',
        dir: SortDirection.ASC.frontendkuerzel
      },
      {
        prop: 'betriebsschluessel',
        dir: SortDirection.ASC.frontendkuerzel
      }
    ];
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.loadDataSubscription);
  }

  ngOnInit() {
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.initPagination();
    if (this.dialogData.filterCriteriaResourceList) {
      this.searchFarmField.searchField.nativeElement.value =
        this.dialogData.filterCriteriaResourceList[this.dialogData.filterCriteriaResourceList.length - 1].value;
      this.searchFilterPagingResource.orFilterCriteriaResourceList = this.dialogData.filterCriteriaResourceList;
    }
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * {@inheritDoc}
   */
  onDoubleClick(row: Farm) {
    this.bestaetigen();
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row Farm
   */
  rowIdentity(row: Farm) {
    return row.id;
  }

  /**
   * Oeffnet den Detaildialog.
   * @param farm Betrieb.
   */
  showBetriebDetails(farm: Farm) {
    this.dialog.open(FarmDetailModalComponent, {
      minWidth: '1261px',
      panelClass: 'vost-modal-dialog',
      data: {
        betriebsschluessel: farm.betriebsschluessel
      }
    });
  }
}
