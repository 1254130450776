<portal-farmuebersicht (selection)="setBetriebsschluessel($event)" *ngIf="!betriebsschluessel"
  [selectionOnlyMode]="true"></portal-farmuebersicht>
<div *ngIf="betriebsschluessel" class="[ panel cattleuebersicht ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{'TIERUEBERSICHT.TIER' | translate}}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]" id="data-list">
          <ngx-datatable
              #tieruebersichtTabelle
            (activate)="onActivate($event)"
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="30" name="{{'TIERUEBERSICHT.ALPHA_LOM' | translate}}" prop="alphaLom">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="40" name="{{'TIERUEBERSICHT.NAME' | translate}}" prop="name">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              [sortable]="true"
              [width]="10" name="{{'TIERUEBERSICHT.STALL_NUMMER' | translate}}" prop="stallNummer">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                  <span (click)="sort()" class="pointer right-cell-header"
                    title="{{'TIERUEBERSICHT.STALL_NUMMER' | translate}}">
                    {{'TIERUEBERSICHT.STALL_NUMMER' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="100" name="{{'TIERUEBERSICHT.RASSE' | translate}}" prop="rasse.shortLabel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="20" name="{{'TIERUEBERSICHT.STATUS' | translate}}" prop="status">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="50" name="{{'TIERUEBERSICHT.GEBURTSDATUM' | translate}}" prop="animalBirthdate">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L'}}">{{value | datexpipe: 'L'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="50" name="{{'TIERUEBERSICHT.LETZTE_BESAMUNG' | translate}}"
              prop="letzteBesamung.inseminationDate">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L'}}">{{value | datexpipe: 'L'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="50" name="{{'TIERUEBERSICHT.LETZTE_KALBUNG' | translate}}">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.lastCalvingDate"
                  title="{{row.lastCalvingDate | datexpipe: 'L'}}, {{row.calvingCount}}">
                  {{row.lastCalvingDate | datexpipe: 'L'}}, {{row.calvingCount}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="50"
              [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i (click)="showTierDetails(row)" aria-hidden="true" class="[ icon-ic_inspect icon-btn ]"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="tieruebersichtTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div class="[ form-content-2 ]">
      <button (click)="onZurueck()" class="[ c-mat-button green-button ]" mat-button>
        <i aria-hidden="true" class="[ icon-baseline-chevron_left-24px ]"></i>
        {{'BUTTON.BETRIEBSAUSWAHL' | translate}}
      </button>
    </div>
  </div>
</div>
