<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{'LEISTUNGSPROTOKOLL.POS_HISTORIE' | translate}}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <div class="[ buttons ]">
          <button (click)="exportPdf()" [disabled]="this.selected.length === 0"
            class="[ c-mat-button green-button ]" mat-flat-button>
            <i aria-hidden="true" style="margin-right: 10px"></i>
            {{'LEISTUNGSPROTOKOLL.PRINT' | translate}}
          </button>
        </div>
        <mat-label class="[ label ]">{{'LEISTUNGSPROTOKOLL.TYP' | translate}}:</mat-label>
        <div id="type">
          <mat-checkbox (change)="loadData(searchFilterPagingResource)" [(ngModel)]="typeBesamung"
            [disabled]="useBesamungsgsdatumForDateFilter"
            checked="true">{{ 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG' | translate }}</mat-checkbox>
          <mat-checkbox (change)="loadData(searchFilterPagingResource)" [(ngModel)]="typeDienstleistung"
            [disabled]="useBesamungsgsdatumForDateFilter"
            checked="true">{{ 'ENUM.AUFTRAGSPOSITION_TYPE.DIENSTLEISTUNG' | translate }}</mat-checkbox>
          <mat-checkbox (change)="loadData(searchFilterPagingResource)" [(ngModel)]="typeAgrarartikel"
            [disabled]="useBesamungsgsdatumForDateFilter"
            checked="true">{{ 'ENUM.AUFTRAGSPOSITION_TYPE.AGRARARTIKEL' | translate }}</mat-checkbox>
        </div>
        <portal-search #searchTechnikerInput (search)="doAndSearch($event)" [fields]="getSearchFieldTechniker()"
          [placeholder]="'TOOLBAR.SUCHBEGRIFF_TECHNIKER_NR' | translate"
          class="[ date-search ]"></portal-search>
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldKunde()"
          [placeholder]="'TOOLBAR.SUCHBEGRIFF_KUNDE' | translate"
          class="[ date-search ]"></portal-search>
        <portal-date-search (search)="doAndSearch($event)" [field]="getSearchFieldDate()" [interval]="true"
          [minDateFrom]="minDate" [takeMinDateOnClear]="true"
          class="[ date-search ]"></portal-date-search>
        <div class="[ checkbox ]">
          <mat-checkbox (change)="onChangeOfDatumsfilter()" [(ngModel)]="useBesamungsgsdatumForDateFilter"
            checked="true">{{ 'TOOLBAR.FILTER_AUFTRAGSDATUM_SUCHE' | translate }}</mat-checkbox>
        </div>
        <div *ngIf="isBesamungstechniker || isBapBerater" class="[ checkbox ]">
          <mat-checkbox (change)="changeCheckbox()"
            [(ngModel)]="nurEigene">{{'LEISTUNGSPROTOKOLL.EIGENE_ANZEIGEN' | translate}}</mat-checkbox>
        </div>
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ c-data-list ]">
        <ngx-datatable
            #leistungsprotokollTabelle
          (page)="setPage($event)"
          (sort)="onSort($event)"
          [columnMode]="'force'"
          [count]="currentPage.totalElements"
          [cssClasses]="customClasses"
          [externalPaging]="true"
          [externalSorting]="true"
          [footerHeight]="57"
          [headerHeight]="57"
          [limit]="currentPage.size"
          [loadingIndicator]="tableIsLoading"
          [messages]="tableMessages"
          [offset]="currentPage.number"
          [rowHeight]="25.68"
          [rowIdentity]="rowIdentity"
          [rows]="data"
          [selectAllRowsOnPage]="false"
          [selected]="selected"
          [selectionType]="'multiClick'"
          [sorts]="sortField"
          class="[ c-data-list__table ] "
          style="height:604px">
          <ngx-datatable-column [canAutoResize]="false" [cellClass]="'c-data-list__column-first'" [draggable]="false"
            [resizeable]="false" [sortable]="true" [width]="40">
            <ng-template let-value="value" ngx-datatable-header-template>
              <div class="[ c-data-list__checkbox-column--header ]">
                <span class="[ c-data-list__checkbox-label ]"
                  title="{{ 'LEISTUNGSPROTOKOLL.ALL' | translate }}"
                  translate="LEISTUNGSPROTOKOLL.ALL"></span>
                <input (change)="selectAll()" [checked]="selectAllOnPage[currentPage.number]" type="checkbox" />
              </div>
            </ng-template>
            <ng-template
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
              let-value="value"
              ngx-datatable-cell-template>
              <input
                (change)="onCheckboxChangeFn($event)"
                [checked]="isSelected"
                type="checkbox"
              />
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [maxWidth]="55" name="{{'LEISTUNGSPROTOKOLL.TYP' | translate}}" prop="type">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span
                title="{{value +'_TITLE' | translate }}">{{ value | translate }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="70"
            name="{{'LEISTUNGSPROTOKOLL.POSITIONSNUMMER' | translate}}" prop="receiptNumber">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'LEISTUNGSPROTOKOLL.POSITIONSNUMMER_TITLE' | translate}}">
                {{'LEISTUNGSPROTOKOLL.POSITIONSNUMMER' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [maxWidth]="70" name="{{'LEISTUNGSPROTOKOLL.TECHNIKER' | translate}}">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'LEISTUNGSPROTOKOLL.TECHNIKER_TITLE' | translate}}">
                {{'LEISTUNGSPROTOKOLL.TECHNIKER' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="right-cell" title="{{row.technikerKey.number}}">{{row.technikerKey.number}}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="90" name="{{'LEISTUNGSPROTOKOLL.DATUM' | translate}}" prop="auftragsdatum">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span title="{{value | datexpipe: 'L'}}">{{value | datexpipe: 'L'}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="90" name="{{'LEISTUNGSPROTOKOLL.BESAMUNGSDATUM' | translate}}" prop="inseminationDate">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <ng-container *ngIf="row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG'">
                <span title="{{value | datexpipe: 'L'}}">{{value | datexpipe: 'L'}}</span>
              </ng-container>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
            [width]="60"
            name="{{'LEISTUNGSPROTOKOLL.SHORTNUMBER' | translate}}" prop="shortNumber">
            <ng-template ngx-datatable-header-template>
                  <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL.SHORTNUMBER' | translate}}">
                    {{'LEISTUNGSPROTOKOLL.SHORTNUMBER' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="200" name="{{'LEISTUNGSPROTOKOLL.NAME' | translate}}" prop="name">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.adresse"
                  title="{{ row.adresse.nachname}}, {{ row.adresse.vorname }} - {{ row.adresse.ort}}, {{row.adresse.ortsteil}}">
                  {{ row.adresse.nachname}}, {{ row.adresse.vorname }} - {{ row.adresse.ort}}, {{row.adresse.ortsteil}}
                </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [maxWidth]="70"
            [sortable]="false" name="{{'LEISTUNGSPROTOKOLL.STALL_NR' | translate}}" prop="stallnummer">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL.STALL_NR' | translate}}">
                {{'LEISTUNGSPROTOKOLL.STALL_NR' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
            [width]="100" name="{{'LEISTUNGSPROTOKOLL.LOM' | translate}}" prop="cattleKey.lom">
            <ng-template ngx-datatable-header-template>
               <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL.LOM' | translate}}">
                 {{'LEISTUNGSPROTOKOLL.LOM' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="55"
            [sortable]="false" prop="wvb">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL.WVB_TITLE' | translate}}">
                {{'LEISTUNGSPROTOKOLL.WVB' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="40"
            [sortable]="false" name="{{'LEISTUNGSPROTOKOLL.BOOKING_TYPE' | translate}}">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL.BOOKING_TYPE_TITLE' | translate}}">
                {{'LEISTUNGSPROTOKOLL.BOOKING_TYPE' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span *ngIf="row.bookingType"
                title="{{ row.bookingType | bookingType }}">{{  row.bookingType | bookingType }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="40"
            [sortable]="false">
            <ng-template ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL.ANFAHRT_TITLE' | translate}}">
                {{'LEISTUNGSPROTOKOLL.ANFAHRT' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <i *ngIf="row.specialCharge?.includes(besamungsdienstleistungenService.getVitArtikelnummerAnfahrt())"
                class="[ icon-baseline-done-24px ]"></i>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="40"
            [sortable]="false">
            <ng-template ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL.SPERMAPREIS_BERECHNEN_TITLE' | translate}}">
                {{'LEISTUNGSPROTOKOLL.SPERMAPREIS_BERECHNEN' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <i *ngIf="row.berechneSpermapreis"
                class="[ icon-baseline-done-24px ]"></i>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
            [width]="65" prop="itemNumber">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL.ARTIKELNUMMER_TITLE' | translate}}">
                {{'LEISTUNGSPROTOKOLL.ARTIKELNUMMER' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
            [width]="140" prop="bezeichnung">
            <ng-template ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL.BEZEICHNUNG_TITLE' | translate}}">
                {{'LEISTUNGSPROTOKOLL.BEZEICHNUNG' | translate}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="70" prop="bulle.label">
            <ng-template ngx-datatable-header-template>
                <span title="{{'LEISTUNGSPROTOKOLL.BEHANDLUNGSART_TITLE' | translate}}">
                  {{'LEISTUNGSPROTOKOLL.BEHANDLUNGSART' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span *ngIf="row.bulle"
                title="{{ row.bulle.label | spermaart }}">{{  row.bulle.label | spermaart }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="70"
            [sortable]="false"
            name="{{'LEISTUNGSPROTOKOLL.MENGE' | translate}}" prop="quantity">
            <ng-template ngx-datatable-header-template>
                  <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL.MENGE' | translate}}">
                    {{'LEISTUNGSPROTOKOLL.MENGE' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
              <portal-table-footer-without-paging [rows]="data" [selected]="selected"
                [table]="leistungsprotokollTabelle" class="[ table-footer ]">
              </portal-table-footer-without-paging>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
        <div class="position-counter">
          <span class="counter-item">{{'LEISTUNGSPROTOKOLL.GESAMTE_BESAMUNGEN' | translate}}: {{positionCounter
              ? (positionCounter.besamungEB + positionCounter.besamungWB) : 0}}
            (EB:{{positionCounter?.besamungEB}};WB:{{positionCounter?.besamungWB}})</span>
          <span class="counter-item">{{'LEISTUNGSPROTOKOLL.AGRAR_ARTIKEL'|translate}}: {{positionCounter?.agrar}}</span>
          <span class="counter-item">{{'LEISTUNGSPROTOKOLL.DIENSTLEISTUNGEN'|translate}}:
            {{positionCounter?.dienstleistung}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
