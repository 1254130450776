<div class="[ min-max-search ]">
  <mat-form-field class="[ search ]">
    <input #minSearchField (change)="minValue=minSearchField.value" (keydown)="onKeyDown($event)"
      (keyup)="onKeyUp($event, minSearchField.value, true)"
      [placeholder]="minPlaceholder || ('TOOLBAR.SUCHBEGRIFF' | translate)"
      [validateRegex]="'^-?\\d{1,3}$|^-?\\d{1,3},\\d{1,1}$|^-?$'" [value]="defMinValue" matInput type="text">
    <i (click)="minSearchField.value=null; minValue=null; doSearch()" *ngIf="hasValue(minSearchField)"
      class="search-clear" matSuffix>x
    </i>
    <i (click)="doSearch()" aria-hidden="true" class="[ icon-magnify ]" matSuffix></i>
  </mat-form-field>
  <mat-form-field class="[ search margin-left-20 ]">
    <input #maxSearchField (change)="maxValue=maxSearchField.value" (keydown)="onKeyDown($event)"
      (keyup)="onKeyUp($event, maxSearchField.value, false)"
      [placeholder]="maxPlaceholder || ('TOOLBAR.SUCHBEGRIFF' | translate)"
      [validateRegex]="'^-?\\d{1,3}$|^-?\\d{1,3},\\d{1,1}$|^-?$'" [value]="defMaxValue" matInput type="text">
    <i (click)="maxSearchField.value=null; maxValue=null; doSearch()" *ngIf="hasValue(maxSearchField)"
      class="search-clear" matSuffix>x
    </i>
    <i (click)="doSearch()" aria-hidden="true" class="[ icon-magnify ]" matSuffix></i>
  </mat-form-field>
</div>
