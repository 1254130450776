import { AuftragspositionType } from '../enumeration/auftragsposition-type.enum';
import { Auftragspositionsstatus } from '../enumeration/auftragspositionsstatus.enum';
import { OrderSaleType } from '../enumeration/order-sale-type.enum';
import { Auftragsposition } from '../model/auftragsposition';

export class AuftragspositionenUtils {

  static AUFTRAGSPOSITIONSSTATUS = 'auftragspositionsstatus';

  /**
   * Erzeugt die kommulierten Agrar-Auftragspositionen.
   */
  static calculateDirektUndBestellung(auftragspositionen: Array<Auftragsposition>): Array<Auftragsposition> {
    const agrarartikel: Map<string, Auftragsposition> = new Map();
    auftragspositionen.forEach(position => {
      if (position.type === AuftragspositionType.AGRARARTIKEL && position.auftragspositionsstatus
        === Auftragspositionsstatus.VERSENDET) {
        if (position.bookingType === OrderSaleType.SALE) {
          position.anzahlDirektverkauf = position.quantity;
        } else {
          position.anzahlBestellung = position.quantity;
        }
        const auftragsposition = agrarartikel.get(position.itemNumber);
        if (auftragsposition) {
          auftragsposition.anzahlDirektverkauf = position.bookingType === OrderSaleType.SALE
            ? position.anzahlDirektverkauf
            : auftragsposition.anzahlDirektverkauf;
          auftragsposition.anzahlBestellung = position.bookingType === OrderSaleType.ORDERING
            ? position.anzahlBestellung
            : auftragsposition.anzahlBestellung;
          auftragsposition.quantity += position.quantity;
        } else {
          agrarartikel.set(position.itemNumber, position);
        }
      }
    });
    auftragspositionen = auftragspositionen.filter(
      position => !(position.type === AuftragspositionType.AGRARARTIKEL && position.auftragspositionsstatus
        === Auftragspositionsstatus.VERSENDET));
    for (const position of agrarartikel.values()) {
      auftragspositionen.push(position);
    }
    return auftragspositionen;
  }
}
