import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationInfo } from '../../model/ApplicationInfo';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInfoService {

  private url = '/unsecure-api/v1/applicationInfo';

  constructor(private http: HttpClient) {
  }

  /**
   * Liefert Informationen ueber die Applikation.
   *
   * @return ApplicationInfo
   */
  getApplicationInformation(): Observable<any> {
    return this.http.get<any>(this.url);
  }
}
