import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DateValidation } from '../../../model/dateValidation';

/**
 * Datumskomponente.
 * default minDate ist aktuelles Datum - 6 Monate
 * default maxDate ist aktuelles Datum
 */
@Component({
  selector: 'portal-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

  @Input() datePlaceholder: string;

  @Input() dateValue: Moment;

  @Input() minDate: Moment = moment().subtract(6, 'months');

  @Input() maxDate: Moment = moment();

  @Output() public dateChange = new EventEmitter<DateValidation>();

  @Output() public dateClear = new EventEmitter<DateComponent>();

  @Input() readonly = false;

  @Input() required = false;

  dateForm: UntypedFormControl;

  /**
   * Konstruktor
   */
  constructor(private adapter: DateAdapter<any>) {
    this.adapter.setLocale('de');
  }

  /**
   * Wird ausgefuehrt wenn sich das Datum geaendert hat.
   */
  changeDate() {
    if (this.dateForm.value) {
      this.dateChange.emit({
        date: this.dateForm.value.startOf('day').add(moment.duration(10, 'hours')),
        isValid: this.isValid()
      });
    }
  }

  /**
   * Leert das Feld Datum.
   * @param inputField InputField
   */
  public clearDate(inputField: HTMLInputElement) {
    this.dateForm.setValue(undefined);
    inputField.value = null;
    this.dateClear.emit(this);
  }

  /**
   * Prueft, ob sich das Datum in dem richtigen Zeitraum befindet und ob es valide ist.
   */
  isValid() {
    return this.readonly || (this.dateForm.value && this.dateForm.value.isValid() && this.dateForm.value.isSameOrAfter(
      this.minDate) && this.dateForm.value.isSameOrBefore(this.maxDate));
  }

  ngOnInit() {
    if (this.required) {
      this.dateForm = new UntypedFormControl(this.dateValue, [
        Validators.required
      ]);
    } else {
      this.dateForm = new UntypedFormControl(this.dateValue);
    }
  }

  /**
   * Eingabenpruefung.
   *
   * @param $event KeyboardEvent
   */
  onKeyDownDate($event: KeyboardEvent) {
    if ($event.code === 'Enter' || $event.code === 'NumpadEnter') {
      this.changeDate();
    } else {
      if ($event.code && $event.code !== 'Backspace' && $event.code !== 'Delete' && !$event.code.startsWith('Arrow')
        && $event.code !== 'Tab') {
        const value = (($event.target) as HTMLInputElement).value + $event.key;
        const selectionStart = (($event.target) as HTMLInputElement).selectionStart;
        switch (selectionStart) {
          case 0:
            if ('0123'.indexOf($event.key) === -1) {
              $event.preventDefault();
            }
            break;
          case 3:
            if ('01'.indexOf($event.key) === -1) {
              $event.preventDefault();
            }
            break;
          case 1:
            if (value[0] === '3') {
              if ('01'.indexOf($event.key) === -1) {
                $event.preventDefault();
              }
            } else {
              if ('0123456789'.indexOf($event.key) === -1) {
                $event.preventDefault();
              }
            }
            break;
          case 4:
            if (value[3] === '0') {
              if ('0123456789'.indexOf($event.key) === -1) {
                $event.preventDefault();
              }
            } else {
              if ('012'.indexOf($event.key) === -1) {
                $event.preventDefault();
              }
            }
            break;
          case 2:
          case 5:
            if ($event.key !== '.') {
              $event.preventDefault();
            }
            break;
          case 6:
            if ('12'.indexOf($event.key) === -1) {
              $event.preventDefault();
            }
            break;
          case 7:
            if ('09'.indexOf($event.key) === -1) {
              $event.preventDefault();
            }
            break;
          case 8:
          case 9:
            if ('0123456789'.indexOf($event.key) === -1) {
              $event.preventDefault();
            }
            break;
          default:
            $event.preventDefault();
        }
      }
    }
  }

  /**
   * Markieren und loeschen
   * @param $event Keyevent
   */
  public onKeyUpDate($event: KeyboardEvent) {
    const target: any = $event.target;
    if (target.value.length === 0) {
      this.clearDate(target);
    }
  }

  /**
   * Setzt das Datum
   * @param date Datum
   */
  public setDate(date: Moment) {
    this.dateForm.setValue(date);
  }
}
