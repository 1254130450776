import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Job } from '../../model/job';
import { ClientPaginationService } from '../client-pagination-service';

@Injectable({
  providedIn: 'root'
})
export class Jobervice implements ClientPaginationService<Job> {

  private readonly url = '/api/v1/job';

  constructor(private http: HttpClient) {
  }

  /**
   * Aktualisiert die Job-Cron-Expression.
   * @param job Job
   * @param cronExpression cronExpression
   */
  changeCronExpression(job: Job, cronExpression: string): Observable<any> {
    const params = new HttpHeaders()
      .set('jobType', job.jobType)
      .set('cronExpression', cronExpression.toString());
    return this.http.post(this.url + '/changeCronExpression', null, {
      headers: params
    });
  }

  /**
   * {@inheritDoc}
   */
  public loadData(): Observable<Array<Job>> {
    return this.http.get<Array<Job>>(this.url);
  }

  /**
   * Startet alle Jobs.
   *
   */
  startAllJobs(): Observable<any> {
    return this.http.post(this.url + '/startAll', null);
  }

  /**
   * Startet einen Job.
   * @param job Job
   *
   */
  startJob(job: Job): Observable<any> {
    const params = new HttpHeaders()
      .set('jobType', job.jobType);
    return this.http.post(this.url + '/start', null, {
      headers: params
    });
  }

  /**
   * Startet einen Job sofort.
   * @param job Job
   *
   */
  startJobImmediate(job: Job): Observable<any> {
    const params = new HttpHeaders()
      .set('jobType', job.jobType);
    return this.http.post(this.url + '/startImmediate', null, {
      headers: params
    });
  }

  /**
   * Stoppt alle Jobs.
   *
   */
  stopAllJobs(): Observable<any> {
    return this.http.post(this.url + '/stopAll', null);
  }

  /**
   * Stoppt einen Job.
   * @param job Job
   */
  stopJob(job: Job): Observable<any> {
    const params = new HttpHeaders()
      .set('jobType', job.jobType);
    return this.http.post(this.url + '/stop', null, {
      headers: params
    });
  }
}
