import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class MonetaryPipe implements PipeTransform {

  NUMBER_FORMAT_REGEXP = /^(\d+)?[\.\,]((\d+)(-(\d+))?)?$/;

  private readonly CURRENCYPIPE = new CurrencyPipe('EUR');

  /**
   * Transformiert den Wert in einen Eurowert mit 2 Nachkommastellen und einem Eurozeichen.
   *
   * @param value Wert
   * @param currencyCode Currency Code
   */
  transform(value: any, currencyCode: string): string | null {
    if (typeof value === 'number' || this.NUMBER_FORMAT_REGEXP.test(value)) {
      if (typeof value === 'string') {
        value = value.replace(',', '.');
      }
      return this.CURRENCYPIPE.transform(value, currencyCode
        ? currencyCode
        : 'EUR', 'symbol', '.2-2', 'de');
    } else {
      return value;
    }
  }
}
