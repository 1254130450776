import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DeviceConfiguration } from '../../../../model/deviceConfiguration';
import { CustomErrorHandlerService } from '../../../../service/custom-error-handler/custom-error-handler.service';
import { DeviceConfigurationService } from '../../../../service/device-configuration/device-configuration.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'portal-device-configuration-detail',
  templateUrl: './device-configuration-detail.component.html',
  styleUrls: ['./device-configuration-detail.component.scss']
})
export class DeviceConfigurationDetailComponent implements OnInit, OnDestroy {

  deviceConfiguration: DeviceConfiguration;

  imageUrl: SafeUrl;

  updateSubscription: Subscription;

  getDeviceQRSubscription: Subscription;

  updateQRCodeSubscription: Subscription;

  enabled: boolean;

  accountLocked: boolean;

  constructor(private readonly deviceConfigurationService: DeviceConfigurationService,
              private readonly customErrorHandlerService: CustomErrorHandlerService,
              private readonly sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) private readonly dialogData: any,
              private readonly dialogRef: MatDialogRef<DeviceConfigurationDetailComponent>,
              private readonly notificationService: NotificationService) {
  }

  /**
   * Schliesst den modalen Dialog.
   */
  clickAbbrechenButton() {
    this.dialogRef.close();
  }

  /**
   * Aktualisiert die DeviceConfiguration.
   */
  clickBestaetigenButton() {
    this.deviceConfiguration.accountLocked = this.accountLocked;
    this.deviceConfiguration.enabled = this.enabled;
    this.updateSubscription = this.deviceConfigurationService.update(this.deviceConfiguration).subscribe(model => {
      this.deviceConfiguration = model;
      this.dialogRef.close();
    }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Aktualisiert den QR-Code.
   */
  clickQRCodeAktualisierenButton() {
    this.updateQRCodeSubscription =
      this.deviceConfigurationService.updateQRCode(this.deviceConfiguration).subscribe(() => {
        this.getQRCode(true);
      }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Erzeugung eines QR-Codes.
   *
   * @param withNotifications true mit Notifications, false ohne Notifications
   */
  getQRCode(withNotifications: boolean) {
    if (this.deviceConfiguration.defaultConfiguration === true) {
      this.getDeviceQRSubscription =
        this.deviceConfigurationService.getDeviceQR(this.deviceConfiguration.technikerKey).subscribe(res => {
          const blob = new Blob([res.body], {
            type: res.headers.get('Content-Type')
          });
          this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
          if (withNotifications) {
            this.notificationService.showSuccessNotification('DEVICE_CONFIGURATION_DETAIL.MESSAGE.QR_CODE.SUCCESS');
          }
        }, error => {
          this.customErrorHandlerService.handleError(error);
          if (withNotifications) {
            this.notificationService.showErrorNotification('DEVICE_CONFIGURATION_DETAIL.MESSAGE.QR_CODE.ERROR');
          }
        });
    }
  }

  ngOnDestroy(): void {
    Utils.unsubscribeSubscription(this.getDeviceQRSubscription);
    Utils.unsubscribeSubscription(this.updateSubscription);
    Utils.unsubscribeSubscription(this.updateQRCodeSubscription);
  }

  ngOnInit(): void {
    this.deviceConfiguration = this.dialogData.deviceConfiguration;
    this.enabled = this.deviceConfiguration.enabled;
    this.accountLocked = this.deviceConfiguration.accountLocked;
    this.getQRCode(false);
  }
}
