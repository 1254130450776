import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateSearchComponent } from '../../../core/component/date-search/date-search.component';
import { AbstractClientPaginationTable } from '../../../core/component/table/abstract-client-pagination-table';
import { SortDirection } from '../../../core/filter/sort-direction';
import { AuftragspositionType } from '../../../enumeration/auftragsposition-type.enum';
import { Auftragspositionsstatus } from '../../../enumeration/auftragspositionsstatus.enum';
import { OrderSaleType } from '../../../enumeration/order-sale-type.enum';
import { Auftrag } from '../../../model/auftrag';
import { Auftragsposition } from '../../../model/auftragsposition';
import { DateValidation } from '../../../model/dateValidation';
import { AuftragspositionService } from '../../../service/auftrag/auftragsposition.service';
import { BesamungsdienstleistungenService } from '../../../service/besamungsdienstleistungen/besamungsdienstleistungen.service';
import { BesamungskommentarService } from '../../../service/besamungskommentar/besamungskommentar.service';
import { ClientPaginationService } from '../../../service/client-pagination-service';
import { CustomErrorHandlerService } from '../../../service/custom-error-handler/custom-error-handler.service';
import { DataService } from '../../../service/data/data.service';
import { FarmService } from '../../../service/farm/farm.service';
import { ModaKeycloakService } from '../../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../../service/moda-translate.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { AuftragspositionenUtils } from '../../../utils/auftragspositionen-utils';
import { DialogUtils } from '../../../utils/dialog-utils';
import { Utils } from '../../../utils/utils';
import { WarenauswahlComponent } from './warenauswahl/warenauswahl.component';

@Component({
  selector: 'portal-warenkorb',
  templateUrl: './warenkorb.component.html',
  styleUrls: ['./warenkorb.component.scss']
})
export class WarenkorbComponent extends AbstractClientPaginationTable<Auftragsposition> implements OnInit,
  AfterViewInit, OnDestroy {

  sortField = [
    {
      prop: 'type',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  auftrag: Auftrag;

  farmSubscription: Subscription;

  createOrUpdateSubscription: Subscription;

  deleteSubscription: Subscription;

  selectedAuftragsdatum: DateValidation;

  oldAuftragspositionen: Array<Auftragsposition> = [];

  @ViewChild(DateSearchComponent, { static: true }) dateSearch: DateSearchComponent;

  tableMessages = {
    emptyMessage: 'Noch keine Artikel ausgewählt'
  };

  betriebsbezeichnung: string;

  technikernummer: number;

  private dialogRef: MatDialogRef<WarenauswahlComponent>;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly dataService: DataService, private readonly dialog: MatDialog,
              private readonly router: Router, private readonly modaKeycloakService: ModaKeycloakService,
              private readonly notificationService: NotificationService,
              private readonly auftragspositionService: AuftragspositionService,
              private readonly besamungsdienstleistungenService: BesamungsdienstleistungenService,
              private readonly farmService: FarmService,
              private readonly besamungskommentarService: BesamungskommentarService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Senkt die Anzahl der markierten Zeile um 1.
   *
   * @param row Zeile
   * @param direkt true, wenn anzahlDirektverkauf, false wenn anzahlBestellung
   */
  decrementAnzahl(row: Auftragsposition, direkt: boolean) {
    if (direkt) {
      if (row.anzahlDirektverkauf > 0) {
        row.anzahlDirektverkauf--;
      }
    } else {
      if (row.anzahlBestellung > 0) {
        row.anzahlBestellung--;
      }
    }
    this.setAnzahl(direkt
      ? row.anzahlDirektverkauf
      : row.anzahlBestellung, row, direkt);
  }

  /**
   * Senkt die Anzahl der markierten Zeile um 1.
   *
   * @param row Zeile
   */
  decrementQuantity(row: Auftragsposition) {
    row.quantity--;
  }

  /**
   * {@inheritDoc}
   */
  getService(): ClientPaginationService<Auftragsposition> {
    return undefined;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return this.sortField;
  }

  /**
   * Erhoeht die Anzahl der markierten Zeile um 1.
   *
   * @param row Zeile
   * @param direkt true, wenn anzahlDirekt, false wenn anzahlBestellung
   */
  incrementAnzahl(row: Auftragsposition, direkt: boolean) {
    if (direkt) {
      if (row.anzahlDirektverkauf < row.quantity) {
        row.anzahlDirektverkauf++;
      }
    } else {
      if (row.anzahlBestellung < row.quantity) {
        row.anzahlBestellung++;
      }
    }
    this.setAnzahl(direkt
      ? row.anzahlDirektverkauf
      : row.anzahlBestellung, row, direkt);
  }

  /**
   * Erhoeht die Anzahl der markierten Zeile um 1.
   *
   * @param row Zeile
   */
  incrementQuantity(row: Auftragsposition) {
    row.quantity++;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.auftrag && this.auftrag.positionen.length === 0) {
        this.onArtikelHinzufuegen();
      }
    });
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.farmSubscription);
    Utils.unsubscribeSubscription(this.createOrUpdateSubscription);
    Utils.unsubscribeSubscription(this.deleteSubscription);
  }

  ngOnInit() {
    this.technikernummer = this.modaKeycloakService.getTechnikernummer();
    this.auftrag = JSON.parse(sessionStorage.getItem('auftrag'));
    const betriebsschluessel = this.auftrag.betriebsschluessel
      ? this.auftrag.betriebsschluessel
      : this.auftrag.positionen && this.auftrag.positionen.length > 0
        ? this.auftrag.positionen[0].betriebsschluessel
        : null;
    if (betriebsschluessel) {
      this.farmSubscription = this.farmService.loadByBetriebsschluessel(betriebsschluessel).subscribe(betrieb => {
        // Beispielstring der zusammengebaut wird: 887 - Lueken, Peter - Leerer Landstr. 140, Aurich
        const vorname = (betrieb && betrieb.adresse && betrieb.adresse.vorname);
        const nachname = (betrieb && betrieb.adresse && betrieb.adresse.nachname);
        const name = nachname
          ? (nachname
          ? (nachname + ', ')
          : '') + (vorname || '')
          : '';
        const strasseNr = (betrieb && betrieb.adresse && betrieb.adresse.strasseHausnummer
          ? betrieb.adresse.strasseHausnummer + ', '
          : '');
        const ort = (betrieb && betrieb.adresse && betrieb.adresse.ort
          ? betrieb.adresse.ort
          : '');
        const adresse = strasseNr + ort;
        const shortNr = betrieb && betrieb.oldShortNumber
          ? betrieb.oldShortNumber + ' - '
          : '';
        this.betriebsbezeichnung = shortNr + name + ' - ' + adresse;
      }, error => this.customErrorHandlerService.handleError(error));
    }
    this.auftrag.positionen = AuftragspositionenUtils.calculateDirektUndBestellung(this.auftrag.positionen);
    for (const pos of this.auftrag.positionen) {
      this.oldAuftragspositionen.push(pos);
    }
    this.data = this.auftrag.positionen;
    this.selectedAuftragsdatum = new DateValidation(this.auftrag.auftragsdatum, true);
  }

  /**
   * Bricht die Auftragserstellung ab und leitet den Benutzer zurueck zur Auftragshistorie.
   */
  onAbbrechen() {
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('WARENKORB.ABBRECHEN_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate('WARENKORB.ABBRECHEN_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        sessionStorage.clear();
        this.router.navigate(['/auftraege'], { replaceUrl: true });
      }
    });
  }

  /**
   * Loeschen aller Auftragspositionen mit Sicherheitsdialog.
   */
  onAlleLoeschen() {
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('WARENKORB.CLEAR_ALL_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate('WARENKORB.CLEAR_ALL_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auftrag.positionen = this.auftrag.positionen.filter(
          positon => Auftragspositionsstatus.VERSENDET === positon.auftragspositionsstatus);
        this.data = this.data.filter(positon => Auftragspositionsstatus.VERSENDET === positon.auftragspositionsstatus);
        sessionStorage.setItem('auftrag', JSON.stringify(this.auftrag));
      }
    });
  }

  /**
   * Oeffnet einen Modalen Dialog zum hinzufuegen eines Artikels.
   */
  onArtikelHinzufuegen() {
    const dialogRef = this.dialog.open(WarenauswahlComponent, {
      minWidth: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        auftrag: this.auftrag
      },
      disableClose: true
    });

    this.selected = [];
    this.selectedElement = null;

    this.closeDialog(dialogRef);
  }

  /**
   * Button Bestaetigen.
   */
  onBestaetigen() {
    if (!this.auftrag.auftragsnummer && this.auftrag.positionen.length === 0) {
      this.router.navigate([
        'auftraege'
      ]);
      return;
    }
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate(this.auftrag.auftragsstatus === Auftragspositionsstatus.ERSTELLT
        ? 'WARENKORB.AKTUALISIEREN_DIALOG.HEADLINE'
        : 'WARENKORB.BESTAETIGEN_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate(this.auftrag.auftragsstatus === Auftragspositionsstatus.ERSTELLT
        ? 'WARENKORB.AKTUALISIEREN_DIALOG.MESSAGE'
        : 'WARENKORB.BESTAETIGEN_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auftrag.auftragsstatus = Auftragspositionsstatus.ERSTELLT;
        const positionen = this.auftrag.positionen;
        this.auftrag.positionen = [];
        this.auftrag.technikerKey = this.auftrag.technikerKey || this.modaKeycloakService.getTechnikerKey();
        for (const pos of positionen) {
          this.auftrag.positionen.push(pos);
        }
        const agrarartikelBestellungen: Array<Auftragsposition> = [];
        this.auftrag.positionen.forEach(position => {
          if (Auftragspositionsstatus.VERSENDET !== position.auftragspositionsstatus) {
            position.betriebsschluessel = this.auftrag.betriebsschluessel;
            position.adresse = this.auftrag.adresse;
            position.shortNumber = this.auftrag.shortNumber;
            position.technikerKey = this.auftrag.technikerKey;
            position.auftragspositionsstatus = Auftragspositionsstatus.ERSTELLT;
            if (position.type === AuftragspositionType.AGRARARTIKEL) {
              if (position.anzahlDirektverkauf > 0) {
                position.bookingType = OrderSaleType.SALE;
                position.quantity = position.anzahlDirektverkauf;
                if (position.anzahlBestellung > 0) {
                  const copy = Utils.copy(position);
                  copy.bookingType = OrderSaleType.ORDERING;
                  copy.quantity = position.anzahlBestellung;
                  agrarartikelBestellungen.push(copy);
                }
              } else {
                position.bookingType = OrderSaleType.ORDERING;
                position.quantity = position.anzahlBestellung;
              }
            }
          }
        });
        agrarartikelBestellungen.forEach(position => this.auftrag.positionen.push(position));
        if (this.auftrag.positionen.length !== 0) {
          this.createOrUpdateSubscription = this.auftragspositionService.createOrUpdate(this.auftrag).subscribe(() => {
            sessionStorage.clear();
            this.notificationService.showSuccessNotification('WARENKORB.BESTAETIGEN.SUCCESS');
            this.router.navigate([
              'auftraege'
            ]);
          }, error => {
            this.auftrag.auftragsstatus = Auftragspositionsstatus.IM_WARENKORB;
            this.notificationService.showErrorNotification('WARENKORB.BESTAETIGEN.ERROR');
            this.customErrorHandlerService.handleError(error);
          });
        } else {
          this.deleteSubscription =
            this.auftragspositionService.deleteByAuftragsnummer(this.auftrag.auftragsnummer).subscribe(() => {
              this.notificationService.showSuccessNotification('WARENKORB.DELETE.SUCCESS');
              this.router.navigate([
                'auftraege'
              ]);
            }, error => {
              this.notificationService.showErrorNotification('WARENKORB.DELETE.ERROR');
              this.customErrorHandlerService.handleError(error);
            });
        }
      }
    });
  }

  /**
   * Loescht eine Auftragsposition mit Sicherheitsabfrage.
   *
   * @param auftragsposition Auftragsposition
   */
  onDeletePosition(auftragsposition: Auftragsposition) {
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('WARENKORB.DELETE_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate('WARENKORB.DELETE_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auftrag.positionen =
          this.auftrag.positionen.filter(position => position.itemNumber !== auftragsposition.itemNumber);
        this.data = [...this.auftrag.positionen];
      }
    });
  }

  /**
   * {@inheritDoc}
   */
  onDoubleClick(row: Auftragsposition) {
    if (row.type !== AuftragspositionType.AGRARARTIKEL && (row.auftragspositionsstatus
      === Auftragspositionsstatus.ERSTELLT || row.auftragspositionsstatus === Auftragspositionsstatus.IM_WARENKORB)) {
      this.showPositions(row);
    }
  }

  /**
   * Navigiert zu Farmuebersicht.
   */
  onZurueck() {
    if (this.auftrag.positionen.filter(
      position => position.auftragspositionsstatus === Auftragspositionsstatus.VERSENDET
        || position.auftragspositionsstatus === Auftragspositionsstatus.VERSANDBEREIT).length > 0) {
      this.notificationService.showInfoNotification('WARENKORB.ZURUECK.INFO');
    } else {
      if (this.auftrag.positionen.filter(position => position.type === AuftragspositionType.BESAMUNG).length > 0) {
        const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
          headline: this.modaTranslateService.translate('WARENKORB.BETRIEBSAUSWAHL_DIALOG.HEADLINE'),
          message: this.modaTranslateService.translate('WARENKORB.BETRIEBSAUSWAHL_DIALOG.MESSAGE')
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate([
              'auftraege',
              'farmuebersicht'
            ]);
          }
        });
      } else {
        this.router.navigate([
          'auftraege',
          'farmuebersicht'
        ]);
      }
    }
  }

  public rowIdentity(row: Auftragsposition) {
    return row.id || row.itemNumber || row.cattleKey;
  }

  /**
   * Setzt die Anzahl der markierten Zeile auf den eingegebenen Wert.
   *
   * @param row Zeile
   * @param direkt true, wenn anzahlDirektverkauf, false wenn anzahlBestellung
   */
  setAnzahl(newValue, row: Auftragsposition, direkt: boolean) {
    const value = Math.max(Math.min(typeof newValue === 'string'
      ? parseInt(newValue, 10)
      : newValue, row.quantity), 0);
    if (direkt) {
      row.anzahlDirektverkauf = value;
      this.updateAnzahlDirektverkauf(row);
      row.anzahlBestellung = row.quantity - row.anzahlDirektverkauf;
    } else {
      row.anzahlBestellung = value;
      this.updateAnzahlBestellung(row);
      row.anzahlDirektverkauf = row.quantity - row.anzahlBestellung;
    }
  }

  /**
   * Zeigt die Positionen
   * @param auftragsposition selektierte Auftragsposition
   */
  showPositions(auftragsposition: Auftragsposition) {
    if (auftragsposition.type === AuftragspositionType.BESAMUNG) {
      this.dialogRef = this.dialog.open(WarenauswahlComponent, {
        minWidth: '1216px',
        panelClass: 'vost-modal-dialog',
        data: {
          auftrag: this.auftrag
        },
        disableClose: true
      });
      this.dialogRef.afterOpened().subscribe(() => {
        if (this.dialogRef && this.dialogRef.componentInstance) {
          this.besamungskommentarService.loadData().subscribe(data => {
            this.dialogRef.componentInstance.besamungComp.assignFromAuftragspositionToForm(auftragsposition, data);
          }, error => this.customErrorHandlerService.handleError(error));
        }
      });
    } else if (auftragsposition.type === AuftragspositionType.DIENSTLEISTUNG) {
      this.dialogRef = this.dialog.open(WarenauswahlComponent, {
        minWidth: '1216px',
        panelClass: 'vost-modal-dialog',
        data: {
          auftrag: this.auftrag,
          artikelnummer: auftragsposition.itemNumber,
          currentIndex: 1
        },
        disableClose: true
      });
    } else {
      this.dialogRef = this.dialog.open(WarenauswahlComponent, {
        minWidth: '1216px',
        panelClass: 'vost-modal-dialog',
        data: {
          auftrag: this.auftrag,
          artikelnummer: auftragsposition.itemNumber,
          currentIndex: 2
        },
        disableClose: true
      });
    }
    this.closeDialog(this.dialogRef);
  }

  /**
   * Aktualisiert die Anzahl der Bestellungen.
   * @param row Auftragsposition
   */
  updateAnzahlBestellung(row: Auftragsposition) {
    if (!row.anzahlBestellung) {
      row.anzahlBestellung = 0;
    }
    if (row.anzahlBestellung < 0 || row.anzahlBestellung > row.quantity || row.anzahlBestellung > 999) {
      console.error('Es duerfen nur Zahlen zwischen 0 und 999 eingegeben werden');
      return;
    }
  }

  /**
   * Aktualisiert die Anzahl der Direktverkaeufe.
   * @param row Auftragsposition
   */
  updateAnzahlDirektverkauf(row: Auftragsposition) {
    if (!row.anzahlDirektverkauf) {
      row.anzahlDirektverkauf = 0;
    }
    if (row.anzahlDirektverkauf < 0 || row.anzahlDirektverkauf > row.quantity || row.anzahlDirektverkauf > 999) {
      console.error('Es duerfen nur Zahlen zwischen 0 und 999 eingegeben werden');
      return;
    }
  }

  /**
   * Wird ausgefuehrt wenn sich der Dialog schliesst.
   *
   * @param dialogRef DialogRef
   */
  private closeDialog(dialogRef) {
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auftrag = result;
        this.data = [];
        this.oldAuftragspositionen = [];
        for (const pos of this.auftrag.positionen) {
          this.data.push(pos);
          this.oldAuftragspositionen.push(pos);
        }
        sessionStorage.setItem('auftrag', JSON.stringify(this.auftrag));
      } else {
        this.data = [];
        for (const pos of this.oldAuftragspositionen) {
          this.data.push(pos);
        }
        this.auftrag.positionen = this.data;
      }
    }, error => {
      this.notificationService.showErrorNotification('WARENKORB.AKTUALISIEREN.ERROR');
      this.customErrorHandlerService.handleError(error);
    });
  }
}
