import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { AbstractDialog } from '../../../core/component/dialog/abstract-dialog';
import { FilterCriteria } from '../../../core/filter/filterCriteria';
import { FilterType } from '../../../core/filter/filterType';
import { Page } from '../../../core/filter/page';
import { SortDirection } from '../../../core/filter/sort-direction';
import { Aenderungstyp } from '../../../enumeration/aenderungstyp.enum';
import { Auftragspositionsstatus } from '../../../enumeration/auftragspositionsstatus.enum';
import { Spermaartikel } from '../../../model/spermaartikel';
import { Spermatausch } from '../../../model/spermatausch';
import { CustomErrorHandlerService } from '../../../service/custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../../../service/moda-translate.service';
import { ServerPaginationService } from '../../../service/serverPaginationService';
import { SpermaartikelService } from '../../../service/spermaartikel/spermaartikel.service';
import { Utils } from '../../../utils/utils';
import { WarenauswahlComponent } from '../../auftrag/warenkorb/warenauswahl/warenauswahl.component';
import { SearchFieldDefinition } from '../../search/search-field-definition';

@Component({
  selector: 'portal-spermaauswahl',
  templateUrl: './spermaauswahl.component.html',
  styleUrls: ['./spermaauswahl.component.scss']
})
export class SpermaauswahlComponent extends AbstractDialog<Spermaartikel> implements OnInit {

  @Input() bestellung: Array<Spermatausch>;

  sortField = [
    {
      prop: 'herdbookNumber',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  @ViewChild('searchRasseField', { static: true }) searchRasseField: any;

  private spermapreisFilterCriteria: FilterCriteria;

  private bestandFilterCriteria: FilterCriteria;

  private rasseFilterCriteria: FilterCriteria;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly spermaartikelService: SpermaartikelService,
              public dialogRef: MatDialogRef<WarenauswahlComponent>, @Inject(MAT_DIALOG_DATA) readonly dialogData) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Die Felder, die nicht aus der Db kommen, werden hier vorbereitet.
   * @param page Agrarartikel-Page
   */
  calculateData(page: Page<Spermaartikel>) {
    super.calculateData(page);
    for (const spermaartikel of page.content) {
      spermaartikel.fetchedBestand = spermaartikel.bestandStationssperma;
      spermaartikel.anzahl = 0;
      this.bestellung.forEach(position => {
        if (spermaartikel.itemNumber === position.itemNumber) {
          spermaartikel.anzahl = position.anzahl;
          this.updateSpermaartikel(spermaartikel);
        }
      });
    }
  }

  /**
   * Senkt die Anzahl der markierten Zeile um 10.
   *
   * @param row Zeile
   */
  decrementAnzahl(row: Spermaartikel) {
    if (row.anzahl > 9) {
      row.anzahl = +row.anzahl - 10;
    } else {
      row.anzahl = 0;
    }
    this.updateSpermaartikel(row);
    this.updateBestellung(row);
  }

  /**
   * Ermittelt die Anzahl der SpermaartikelBap.
   */
  determineAnzahlSpermaartikel(): number {
    return this.bestellung.filter(value => Utils.NUMBER_0 !== value.anzahl).length;
  }

  /**
   * Search Bestand durch Filtercriteria.
   * @param filterCriteria Filtercriteria
   */
  doAndSearchBestand(filterCriteria: FilterCriteria) {
    this.bestandFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   * Merkt sich die aktuelles Filtercriterium und ruft die Suche auf.
   * @param filterCriteria Filtercriteria
   */
  public doAndSearchRasse(filterCriteria: FilterCriteria) {
    this.rasseFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   * Search Spermapreis durch Filtercriteria.
   * @param filterCriteria Filtercriteria
   */
  doAndSearchSpermapreis(filterCriteria: FilterCriteria) {
    this.spermapreisFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   * Liefert das Suchfeld fuer den Min-Max-Filter.
   */
  getSearchFieldDefinitionBestand(): string {
    return 'spermagesamtbestandTechnikerList';
  }

  /**
   * Liefert das Suchfeld fuer den Rasse Filter.
   */
  getSearchFieldDefinitionRasse(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('rasse.shortLabel', FilterType.STRING)
    ];
  }

  /**
   * Liefert das Suchfeld fuer Spermapreis.
   */
  getSearchFieldDefinitionSpermapreis() {
    return 'price.amount';
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('name', FilterType.STRING),
      new SearchFieldDefinition('herdbookNumber', FilterType.STRING)
    ];
  }

  /**
   *  {@inheritDoc}.
   */
  public getService(): ServerPaginationService<Spermaartikel> {
    return this.spermaartikelService;
  }

  /**
   *  {@inheritDoc}.
   */
  public getSortFields(): any {
    return this.sortField;
  }

  /**
   * Schliesst den Modalen Dialog und uebergibt den Auftrag.
   */
  hinzufuegen() {
    this.dialogRef.close(this.bestellung);
  }

  /**
   * Erhoeht die Anzahl der markierten Zeile um 10.
   *
   * @param row Zeile
   */
  incrementAnzahl(row: Spermaartikel) {
    if (row.anzahl < 990) {
      row.anzahl = +row.anzahl + 10;
    } else {
      row.anzahl = 999;
    }
    this.updateSpermaartikel(row);
    this.updateBestellung(row);
  }

  /**
   * Fuegt die Filtercriteria von Spermapreis und Bestand zusammen.
   */
  joinedDoAndSearch() {
    const fc: FilterCriteria = new FilterCriteria();
    if (this.spermapreisFilterCriteria) {
      this.spermapreisFilterCriteria.getFilterCriteria().forEach(fcr => fc.addFilterCriteriaResource(fcr));
    }
    if (this.bestandFilterCriteria) {
      this.bestandFilterCriteria.getFilterCriteria().forEach(fcr => fc.addFilterCriteriaResource(fcr));
    }
    if (this.rasseFilterCriteria) {
      this.rasseFilterCriteria.getFilterCriteria().forEach(fcr => fc.addFilterCriteriaResource(fcr));
    }
    this.doAndSearch(fc);
  }

  ngOnInit() {
    this.spermapreisFilterCriteria = null;
    this.bestandFilterCriteria = null;
    this.rasseFilterCriteria = null;
    this.bestellung = this.dialogData.bestellung;
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   *  {@inheritDoc}.
   */
  public rowIdentity(row: Spermaartikel) {
    return row.id;
  }

  /**
   * Setzt die Anzahl der markierten Zeile auf den eingegebenen Wert.
   *
   * @param row Zeile
   */
  setAnzahl(row: Spermaartikel) {
    this.updateSpermaartikel(row);
    this.updateBestellung(row);
  }

  /**
   * Aktualisiert die Bestellung.
   *
   * @param row Spermaartikel
   */
  private updateBestellung(row: Spermaartikel) {
    const anzahl: number = row.anzahl;
    const filteredBestellpositionen = this.bestellung.filter(value => value.itemNumber === row.itemNumber);
    if (filteredBestellpositionen.length > 1) {
      console.error('Es wurde mehr als eine Auftragsposition gefunden');
      return;
    }
    if (filteredBestellpositionen.length === 0) {
      if (anzahl !== Utils.NUMBER_0) {
        const newBestellposition = new Spermatausch();
        newBestellposition.aenderungstyp = Aenderungstyp.ORDERING;
        newBestellposition.itemNumber = row.itemNumber;
        newBestellposition.anzahl = anzahl;
        newBestellposition.auftragsdatum = moment();
        newBestellposition.name = row.name;
        newBestellposition.rasse = row.rasse;
        newBestellposition.behandlungsart = row.label;
        newBestellposition.comment = '';
        newBestellposition.auftragspositionsstatus = Auftragspositionsstatus.IM_WARENKORB;
        this.bestellung.push(newBestellposition);
      }
    } else {
      filteredBestellpositionen[0].anzahl = anzahl;
    }
  }

  /**
   * Aktualisiert den Spermaartikel.
   *
   * @param row Spermaartikel
   */
  private updateSpermaartikel(row: Spermaartikel) {
    if (!row.anzahl) {
      row.anzahl = 0;
    }
    if (row.anzahl < 0 || row.anzahl > 999) {
      console.error('Es duerfen nur Zahlen zwischen 0 und 999 eingegeben werden');
      return;
    }
  }
}
