<div class="headline">
  <label *ngIf="headlineKey">{{ headlineKey | translate }}</label>
  <label *ngIf="headline">{{ headline }}</label>
</div>
<div *ngIf="messageKey" class="dialog-content">
  {{messageKey | translate}}
</div>
<div *ngIf="message" class="dialog-content">
  {{message}}
</div>
<div class="[ form-content-modal ]">
  <button class="[ c-mat-button no-color-button ]" mat-button mat-dialog-close>
    <i aria-hidden="true"></i>
    {{'INFORMATION_DIALOG.BUTTON.CLOSE' | translate}}
  </button>
</div>