import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Job } from '../../../../model/job';
import { CustomErrorHandlerService } from '../../../../service/custom-error-handler/custom-error-handler.service';
import { Jobervice } from '../../../../service/job/job.service';
import { ModaTranslateService } from '../../../../service/moda-translate.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'portal-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit, OnDestroy {

  cronExpression: string;

  job: Job;

  private changeJobSubscription: Subscription;

  constructor(private readonly jobService: Jobervice, private readonly notificationService: NotificationService,
              private readonly modaTranslateService: ModaTranslateService,
              private readonly customErrorHandlerService: CustomErrorHandlerService,
              @Inject(MAT_DIALOG_DATA) private readonly dialogData: any,
              private readonly dialogRef: MatDialogRef<JobDetailComponent>) {
  }

  /**
   * Schliesst den modalen Dialog.
   */
  clickAbbrechenButton() {
    this.dialogRef.close();
  }

  /**
   * Speichert die Aenderungen der Cron-Expression.
   */
  clickBestaetigenButton() {
    this.changeJobSubscription =
      this.jobService.changeCronExpression(this.job, this.job.cronExpression).subscribe(() => {
        this.notificationService.showSuccessNotification('JOB_DETAIL.MESSAGE.CHANGE_CRON_EXPRESSION.SUCCESS');
      }, error => {
        this.notificationService.showErrorNotification('JOB_DETAIL.MESSAGE.CHANGE_CRON_EXPRESSION.ERROR');
        this.customErrorHandlerService.handleError(error);
      });
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    Utils.unsubscribeSubscription(this.changeJobSubscription);
  }

  ngOnInit() {
    this.job = this.dialogData.job;
  }
}
