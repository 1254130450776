import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * EventService und Event zwischen Componenten.
 */
@Injectable({ providedIn: 'root' })
export class EventService {

  private data = new BehaviorSubject('');

  currentData = this.data.asObservable();

  updateMessage(item: any) {
    this.data.next(item);
  }
}
