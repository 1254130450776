<div class="headline">
  <label>{{ 'ERROR_DIALOG.HEADLINE' | translate }}</label>
</div>
<div *ngIf="error" class="content">
  <div *ngIf="error.status">
    <h3>{{ 'ERROR_DIALOG.STATUS' | translate }}</h3>
    <p>
      {{error.status}}
    </p>
  </div>
  <div *ngIf="error.statusText">
    <h3>{{ 'ERROR_DIALOG.STATUS_TEXT' | translate }}</h3>
    <p>
      {{error.statusText}}
    </p>
  </div>
  <div *ngIf="error.message">
    <h3>{{ 'ERROR_DIALOG.MESSAGE' | translate }}</h3>
    <p>
      {{error.message}}
    </p>
  </div>
</div>
