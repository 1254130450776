import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bulleLabel'
})
export class BulleLabelPipe implements PipeTransform {

  /**
   * Transformiert die uebergebene Wvb, Name und Herdbooknummer.
   *
   * @param wvb Wievielte Besamung
   * @param name Name
   * @param herdbooknumber Herdbuchnummer
   */
  transform(wvb: string, name: string, herdbooknumber: string): string {
    const result = [];
    if (wvb) {
      result.push(wvb + '.');
    }
    if (name) {
      result.push(name);
    }
    if (herdbooknumber) {
      result.push('(' + herdbooknumber + ')');
    }
    return result.join(' ');
  }

}
