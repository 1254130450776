<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'VERTRETUNG_TECHNIKER.TITLE' | translate }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ form-content ]">
      <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
        [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
      <div *ngIf="isBesamungstechniker" class="[ checkbox ]">
        <mat-checkbox (change)="loadData(searchFilterPagingResource)"
          [(ngModel)]="nurZugewiesene">{{'VERTRETUNG_TECHNIKER.EIGENE_ANZEIGEN' | translate}}</mat-checkbox>
      </div>
      <button (click)="vertreten()" class="[ c-mat-button green-button margin-right-left-auto ]" mat-flat-button>
        <i aria-hidden="true" style="margin-right: 10px"></i>
        {{'BUTTON.ZUWEISUNGEN_AENDERN' | translate}}
      </button>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable
              #vertretungTechnikerTabelle
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'multiClick'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [canAutoResize]="false" [cellClass]="'c-data-list__column-first'" [draggable]="false"
              [resizeable]="false" [sortable]="true"
              [width]="38">
              <ng-template ngx-datatable-header-template></ng-template>
              <ng-template
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
                let-value="value"
                ngx-datatable-cell-template>
                <input
                  (change)="onCheckboxChangeFn($event)"
                  [checked]="isSelected"
                  type="checkbox"
                />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="100" name="{{'VERTRETUNG_TECHNIKER.NUMMER' | translate}}" prop="technikerKey.number">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                  <span (click)="sort()" class="pointer right-cell-header"
                    title="{{'VERTRETUNG_TECHNIKER.NUMMER' | translate}}">
                    {{'VERTRETUNG_TECHNIKER.NUMMER' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="10" name="{{'VERTRETUNG_TECHNIKER.VORNAME' | translate}}" prop="firstname">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="10" name="{{'VERTRETUNG_TECHNIKER.NACHNAME' | translate}}" prop="lastname">
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage" [multiSelect]="true" [placeholder]="'TABLE.PAGING.ZUGEWIESEN'"
                  [selectedLength]="vertreterTechniker.length"
                  [selected]="selected" [table]="vertretungTechnikerTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
