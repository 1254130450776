<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{'JOBUEBERSICHT.TITLE'|translate}}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="'TOOLBAR.SUCHBEGRIFF' | translate"></portal-search>
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable
              #jobuebersichtTable
            [columnMode]="'force'"
            [cssClasses]="customClasses"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="limt"
            [messages]="tableMessages"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="filterdData"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'JOBUEBERSICHT.JOB_TYPE' | translate}}" prop="jobType">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{ value | translate }}">{{ value | translate }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'JOBUEBERSICHT.CRON_EXPRESSION' | translate}}" prop="cronExpression">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'JOBUEBERSICHT.STATUS' | translate}}" prop="jobstatus">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{ value | translate }}">{{ value | translate }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'JOBUEBERSICHT.PREVIOUS_FIRE_TIME' | translate}}" prop="previousFireTime">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L LTS'}}">{{value | datexpipe: 'L LTS'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'JOBUEBERSICHT.NEXT_FIRE_TIME' | translate}}" prop="nextFireTime">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L LTS'}}">{{value | datexpipe: 'L LTS'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="120" [sortable]="false" name="" prop="id">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <i (click)="startJobImmediate(row)" aria-hidden="true" class="[ fa fa-play-circle-o ]"
                  style="margin-right: 20px; font-size: 18px"
                  title="{{'JOBUEBERSICHT.AKTION.START_JOB_IMMEDIATE' | translate}}"></i>
                <i (click)="startJob(row)" aria-hidden="true" class="[ fa fa-play ]"
                  style="margin-right: 10px" title="{{'JOBUEBERSICHT.AKTION.START_JOB' | translate}}"></i>
                <i (click)="stopJob(row)" aria-hidden="true" class="[ fa fa-stop ]"
                  style="margin-right: 10px" title="{{'JOBUEBERSICHT.AKTION.STOP_JOB' | translate}}"></i>
                <i (click)="changeCronExpression(row)" aria-hidden="true" class="[ fa fa-edit ]"
                  title="{{'JOBUEBERSICHT.AKTION.CHANGE_CRON_EXPRESSION' | translate}}"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-client-pagination-footer [rows]="data" [selected]="selected"
                  [table]="jobuebersichtTable"
                  class="[ table-footer ]"></portal-table-client-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>