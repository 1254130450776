<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{'DEVICE_CONFIGURATION_UEBERSICHT.TITLE'|translate}}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="'TOOLBAR.SUCHBEGRIFF' | translate"></portal-search>
        <div class="[ checkbox ]">
          <mat-checkbox (change)="doAndSearch()" [(ngModel)]="nurDefaultConfiguration">
            {{'DEVICE_CONFIGURATION_UEBERSICHT.FILTER_DEFAULT_CONFIGURATION' | translate}}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable
              #deviceConfigurationUebersichtTable
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'DEVICE_CONFIGURATION_UEBERSICHT.DEVICENAME' | translate}}" prop="devicename">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="140"
              name="{{'DEVICE_CONFIGURATION_UEBERSICHT.TECHNIKER_KEY.NUMBER' | translate}}" prop="technikerKey.number">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="right-cell" title="{{row.technikerKey.number}}">{{row.technikerKey.number}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'DEVICE_CONFIGURATION_UEBERSICHT.TECHNIKERNAME' | translate}}" prop="technikername">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'DEVICE_CONFIGURATION_UEBERSICHT.DEFAULT_CONFIGURATION' | translate}}"
              prop="defaultConfiguration">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span
                  title="{{value | booleanTranslation | translate}}">{{value | booleanTranslation | translate}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'DEVICE_CONFIGURATION_UEBERSICHT.ACCOUNT_LOCKED' | translate}}" prop="accountLocked">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span
                  title="{{value | booleanTranslation | translate}}">{{value | booleanTranslation | translate}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'DEVICE_CONFIGURATION_UEBERSICHT.ENABLED' | translate}}" prop="enabled">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span
                  title="{{value | booleanTranslation | translate}}">{{value | booleanTranslation | translate}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'DEVICE_CONFIGURATION_UEBERSICHT.LAST_LOGIN' | translate}}" prop="lastLogin">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L LTS'}}">{{value | datexpipe: 'L LTS'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="150"
              name="{{'DEVICE_CONFIGURATION_UEBERSICHT.VERSIONSNUMMER' | translate}}" prop="versionsnummer">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{value}}">{{value}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="50"
              [sortable]="false" name="" prop="id">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i (click)="showDetail(row)" aria-hidden="true" class="[ icon-ic_inspect icon-btn ]"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="deviceConfigurationUebersichtTable"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>