import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Besamungskommentar } from '../../model/besamungskommentar';

@Injectable({
  providedIn: 'root'
})
export class BesamungskommentarService {

  private url = '/api/v1/besamungskommentar';

  constructor(private http: HttpClient) {
  }

  /**
   * Liefert alle Besamungskommentare.
   *
   * @return Observable<Array<Besamungskommentar>>
   */
  loadData(): Observable<Array<Besamungskommentar>> {
    return this.http.get<Array<Besamungskommentar>>(this.url + '/list');
  }
}
