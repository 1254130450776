<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{'BESTAND_BETRIEBSSPERMA.BESTANDUEBERSICHT' | translate}}
    </div>
  </div>
  <div class="bestand-anrechtsperma-container">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
        <portal-range-search (search)="doAndSearchBestand($event)"
          [defMinValue]="1" [field]="getSearchFieldDefinitionBestand()"
          [maxPlaceholder]="('BESTAND_BETRIEBSSPERMA.BESTAND_BS_BIS' | translate)"
          [minPlaceholder]="('BESTAND_BETRIEBSSPERMA.BESTAND_BS_VON' | translate)"
          class="[ margin-left-20 ] [ date-search ]"></portal-range-search>
        <mat-checkbox (change)="changeNichtAusreichendCheckbox()" *ngIf="isBesamungstechniker"
          [(ngModel)]="filterNichtAusreichend"
          class="[ margin-left-20 ]">{{'BESTAND_BETRIEBSSPERMA.NICHT_AUSREICHEND_VS' | translate}}</mat-checkbox>
      </div>
    </div>
    <div class="[ row ]">
      <div class="[ col-12 ]  [ c-data-list ]">
        <div class="[ content-table ]">
          <ngx-datatable
              #bestandAnrechtspermaTabelle
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="true" [width]="100"
              name="{{'BESTAND_BETRIEBSSPERMA.HB_NR' | translate}}" prop="herdbookNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_BETRIEBSSPERMA.HB_NR' | translate}}">
                  {{'BESTAND_BETRIEBSSPERMA.HB_NR' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              name="{{'BESTAND_BETRIEBSSPERMA.NAME' | translate}}" prop="name">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="100"
              name="{{'BESTAND_BETRIEBSSPERMA.RASSE' | translate}}" prop="rasse.shortLabel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="130"
              name="{{'BESTAND_BETRIEBSSPERMA.BEHANDLUNGSART' | translate}}" prop="label">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{ value | spermaart }} ">{{ value | spermaart }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'BESTAND_BETRIEBSSPERMA.KURZNUMMER' | translate}}" prop="spermabestandAnrechtList.oldShortNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_BETRIEBSSPERMA.KURZNUMMER' | translate}}">
                  {{'BESTAND_BETRIEBSSPERMA.KURZNUMMER' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="right-cell"
                  title="{{ row.spermabestandAnrechtList[0]?.oldShortNumber }}">{{ row.spermabestandAnrechtList[0]?.oldShortNumber }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="350"
              name="{{'BESTAND_BETRIEBSSPERMA.BETRIEB' | translate}}">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div
                  title="{{ row.spermabestandAnrechtList[0]?.adresse?.nachname}},{{row.spermabestandAnrechtList[0]?.adresse?.vorname}}-{{row.spermabestandAnrechtList[0]?.adresse?.ort}},{{row.spermabestandAnrechtList[0]?.adresse?.ortsteil}}">
                  {{ row.spermabestandAnrechtList[0]?.adresse?.nachname}}
                  ,{{row.spermabestandAnrechtList[0]?.adresse?.vorname}}
                  - {{row.spermabestandAnrechtList[0]?.adresse?.ort}}
                  ,{{row.spermabestandAnrechtList[0]?.adresse?.ortsteil}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="80" name="{{'FARMCATEGORY.TITLE' | translate}}" prop="spermabestandAnrechtList.farmCategory">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{'FARMCATEGORY.ART-'
              + row.spermabestandAnrechtList[0].farmCategory |translate: { default: 'FARMCATEGORY.DEFAULT' | translate } }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="100" name="{{'BESTAND_BETRIEBSSPERMA.BESTAND_BS' | translate}}"
              prop="spermabestandAnrechtList.amount">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_BETRIEBSSPERMA.BESTAND_BS' | translate}}">
                  {{'BESTAND_BETRIEBSSPERMA.BESTAND_BS' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ row.spermabestandAnrechtList[0]?.amount }}">
                  {{ row.spermabestandAnrechtList[0]?.amount}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="isBesamungstechniker" [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="100" name="{{'BESTAND_BETRIEBSSPERMA.BESTAND_VS' | translate}}"
              prop="spermagesamtbestandTechnikerList.amount">
              <ng-template ngx-datatable-header-template>
                <span class="right-cell-header"
                  title="{{'BESTAND_BETRIEBSSPERMA.BESTAND_VS' | translate}}">
                  {{'BESTAND_BETRIEBSSPERMA.BESTAND_VS' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ row.spermagesamtbestandTechnikerList[0]?.amount }}">
                  {{ row.spermagesamtbestandTechnikerList[0]?.amount}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="bestandAnrechtspermaTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>