import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AbstractDialog } from '../../core/component/dialog/abstract-dialog';
import { FilterType } from '../../core/filter/filterType';
import { Page } from '../../core/filter/page';
import { SortDirection } from '../../core/filter/sort-direction';
import { Farm } from '../../model/farm';
import { Spermaartikel } from '../../model/spermaartikel';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { FarmService } from '../../service/farm/farm.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SpermaartikelService } from '../../service/spermaartikel/spermaartikel.service';
import { Utils } from '../../utils/utils';
import { BesamungComponent } from '../auftrag/warenkorb/warenauswahl/besamung/besamung.component';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-bulle-modal',
  templateUrl: './bulle-modal.component.html',
  styleUrls: ['./bulle-modal.component.scss']
})
export class BulleModalComponent extends AbstractDialog<Spermaartikel> implements OnInit, OnDestroy {

  sortField = [
    {
      prop: 'herdbookNumber',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  @ViewChild('searchBulleField', { static: true }) searchBulleField: any;

  @ViewChild('searchRasseField', { static: true }) searchRasseField: any;

  betrieb: Farm;

  farmSubscription: Subscription;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly spermaartikelService: SpermaartikelService,
              private readonly dialogRef: MatDialogRef<BesamungComponent>,
              @Inject(MAT_DIALOG_DATA) private readonly dialogData, private readonly farmService: FarmService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Die ggf. veraenderten Bestaende werden hier berechnet.
   *
   * @param page Spermaartikel-Page
   */
  calculateData(page: Page<Spermaartikel>) {
    for (const spermaartikel of page.content) {
      this.dialogData.auftrag.positionen.forEach(position => {
        if (!position.id) {
          if (spermaartikel.itemNumber === position.itemNumber) {
            // Technikersperma
            if (position.berechneSpermapreis || this.betrieb.farmCategory === 1 || this.betrieb.farmCategory === 4) {
              --spermaartikel.bestandStationssperma;
              if (spermaartikel.bestandStationssperma < 0) {
                spermaartikel.bestandStationssperma = 0;
              }
            }
            // Anrechtssperma des Betriebes
            if (!position.berechneSpermapreis && (this.betrieb.farmCategory === 1 || this.betrieb.farmCategory === 4)) {
              --spermaartikel.bestandBetriebssperma;
              if (spermaartikel.bestandBetriebssperma < 0) {
                spermaartikel.bestandBetriebssperma = 0;
              }
            }
          }
        }
      });
    }
  }

  /**
   *  {@inheritDoc}.
   */
  doLoadData() {
    this.spermaartikelService.loadDataByBetriebsschluessel(this.dialogData.auftrag.betriebsschluessel,
      this.searchFilterPagingResource).subscribe(value => {
      this.doAfterLoadData(value);
    }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Liefert das Suchfeld fuer den Rasse Filter.
   */
  getSearchFieldDefinitionRasse(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('rasse.shortLabel', FilterType.STRING)
    ];
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('name', FilterType.STRING),
      new SearchFieldDefinition('herdbookNumber', FilterType.STRING)
    ];
  }

  public getService(): ServerPaginationService<Spermaartikel> {
    return this.spermaartikelService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return this.sortField;
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.farmSubscription);
  }

  ngOnInit() {
    this.farmSubscription =
      this.farmService.loadByBetriebsschluessel(this.dialogData.auftrag.betriebsschluessel).subscribe(
        farm => this.betrieb = farm, error => this.customErrorHandlerService.handleError(error));
    this.initPagination();
    if (this.dialogData.filterCriteriaResourceList) {
      this.searchBulleField.searchField.nativeElement.value = this.dialogData.filterCriteriaResourceList[0].value;
      this.searchFilterPagingResource.orFilterCriteriaResourceList = this.dialogData.filterCriteriaResourceList;
    }
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   *  {@inheritDoc}.
   */
  onDoubleClick(row: Spermaartikel) {
    this.dialogRef.close(row);
  }

  /**
   *  {@inheritDoc}.
   */
  rowIdentity(row: Spermaartikel) {
    return row.id;
  }
}
