import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogCustomerComponent } from '../../core/component/error-dialog-customer/error-dialog-customer.component';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandlerService implements ErrorHandler {

  constructor(private readonly dialog: MatDialog) {
  }

  handleError(error) {
    console.error('Handler caught an error', error);
    this.dialog.open(ErrorDialogCustomerComponent, {
      width: '600px',
      panelClass: 'vost-modal-dialog',
      data: {
        error
      }
    });
  }

}
