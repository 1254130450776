import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterCriteria } from '../../core/filter/filterCriteria';
import { FilterCriteriaResource } from '../../core/filter/filterCriteriaResource';
import { FilterType } from '../../core/filter/filterType';
import { SortDirection } from '../../core/filter/sort-direction';
import { Spermaartikel } from '../../model/spermaartikel';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SpermaartikelService } from '../../service/spermaartikel/spermaartikel.service';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-bestand-sperma',
  templateUrl: './bestand-spermaartikel.component.html',
  styleUrls: ['./bestand-spermaartikel.component.scss']
})
export class BestandSpermaartikelComponent extends AbstractServerPaginationTable<Spermaartikel> implements OnInit {

  sortField = [
    {
      prop: 'herdbookNumber',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  isBesamungstechniker: boolean;

  minValue = '1';

  @ViewChild('searchRasseField', { static: true }) searchRasseField: any;

  private bestandFilterCriteria: FilterCriteria;

  private technikerFilterCriteria: FilterCriteria;

  private rasseFilterCriteria: FilterCriteria;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly spermaartikelService: SpermaartikelService,
              private readonly modaKeycloakService: ModaKeycloakService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Merkt sich die aktuelles Filtercriterium und ruft die Suche auf.
   * @param filterCriteria Filtercriteria
   */
  public doAndSearchBestand(filterCriteria: FilterCriteria) {
    this.bestandFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   * Merkt sich die aktuelles Filtercriterium und ruft die Suche auf.
   * @param filterCriteria Filtercriteria
   */
  public doAndSearchRasse(filterCriteria: FilterCriteria) {
    this.rasseFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   * Merkt sich die aktuelles Filtercriterium und ruft die Suche auf.
   * @param filterCriteria Filtercriteria
   */
  public doAndSearchTechniker(filterCriteria: FilterCriteria) {
    this.technikerFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   *  {@inheritDoc}.
   */
  doLoadData() {
    this.spermaartikelService.loadDataBySperma(this.searchFilterPagingResource).subscribe(value => {
      this.doAfterLoadData(value);
    }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Filtern nach Von Bestand.
   */
  getSearchFieldAmount() {
    return [
      new SearchFieldDefinition('spermabestandTechnikerList.amount', FilterType.NUMBER)
    ];
  }

  /**
   * Liefert das Suchfeld fuer den Min-Max-Filter.
   */
  getSearchFieldDefinitionBestand(): string {
    return 'spermabestandTechnikerList.amount';
  }

  /**
   * Liefert das Suchfeld fuer den Rasse Filter.
   */
  getSearchFieldDefinitionRasse(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('rasse.shortLabel', FilterType.STRING)
    ];
  }

  /**
   * Liefert das Suchfeld fuer den Min-Max-Filter.
   */
  getSearchFieldDefinitionTechniker(): string {
    return 'spermabestandTechnikerList.spermabestandTechnikerKey.technikerKey.number';
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('name', FilterType.STRING),
      new SearchFieldDefinition('herdbookNumber', FilterType.STRING)
    ];
  }

  /**
   * Filtern nach Techniker
   */
  getSearchFieldTechniker() {
    return [
      new SearchFieldDefinition('spermabestandTechnikerList.spermabestandTechnikerKey.technikerKey.number',
        FilterType.NUMBER)
    ];
  }

  getService(): ServerPaginationService<Spermaartikel> {
    return this.spermaartikelService;
  }

  /**
   * {@inheritDoc}
   */
  getSortFields(): any {
    return [
      {
        prop: 'herdbookNumber',
        dir: SortDirection.ASC.frontendkuerzel
      },
      {
        prop: 'name',
        dir: SortDirection.ASC.frontendkuerzel
      }
    ];
  }

  ngOnInit() {
    this.bestandFilterCriteria = new FilterCriteria();
    const defaultBestandVonFilter = new FilterCriteriaResource(this.getSearchFieldDefinitionBestand(), {
      min: this.minValue
    }, FilterType.RANGE);
    this.bestandFilterCriteria.addFilterCriteriaResource(defaultBestandVonFilter);
    this.rasseFilterCriteria = new FilterCriteria();
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
    this.joinedDoAndSearch();
  }

  /**
   * {@inheritDoc}
   */
  rowIdentity(row: Spermaartikel) {
    return row.id;
  }

  /**
   * Fuegt alle  Filtercriteria Techniker und Bestand zusammen.
   */
  private joinedDoAndSearch() {
    const fc: FilterCriteria = new FilterCriteria();
    if (this.bestandFilterCriteria) {
      this.bestandFilterCriteria.getFilterCriteria().forEach(fcr => fc.addFilterCriteriaResource(fcr));
    }
    if (this.technikerFilterCriteria) {
      this.technikerFilterCriteria.getFilterCriteria().forEach(fcr => fc.addFilterCriteriaResource(fcr));
    }
    if (this.rasseFilterCriteria) {
      this.rasseFilterCriteria.getFilterCriteria().forEach(fcr => fc.addFilterCriteriaResource(fcr));
    }
    this.doAndSearch(fc);
  }
}
