import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Auftrag } from '../../../../model/auftrag';
import { ModaTranslateService } from '../../../../service/moda-translate.service';
import { DialogUtils } from '../../../../utils/dialog-utils';
import { BesamungComponent } from './besamung/besamung.component';

export interface DialogData {
  waren: any;
}

@Component({
  selector: 'portal-warenauswahl',
  templateUrl: './warenauswahl.component.html',
  styleUrls: ['./warenauswahl.component.scss']
})
export class WarenauswahlComponent implements OnInit {
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

  @ViewChild('besamungComp', { static: true }) besamungComp: BesamungComponent;

  auftrag: Auftrag;

  currentIndex = 0;

  constructor(@Inject(MAT_DIALOG_DATA) readonly dialogData, private readonly dialog: MatDialog,
              private readonly modaTranslateService: ModaTranslateService) {
  }

  /**
   * Bezeichnung fuer Title.
   */
  getTitle() {
    const name = (this.auftrag.adresse.nachname
      ? this.auftrag.adresse.nachname
      : '') + (this.auftrag.adresse.vorname
      ? ', ' + this.auftrag.adresse.vorname
      : '');
    const adresse = this.auftrag.adresse.strasseHausnummer + ', ' + this.auftrag.adresse.ort;
    return this.auftrag.shortNumber + ' - ' + name + ' - ' + adresse + this.getTitleFarmCategory();
  }

  getTitleFarmCategory() {
    const farmCategoryArt = this.modaTranslateService.translate('FARMCATEGORY.ART') + '-';
    if (this.auftrag.farmCategory === 1) {
      return '';
    }
    return ' - ' + this.modaTranslateService.translate(farmCategoryArt + this.auftrag.farmCategory,
      { default: 'unbekannt' });
  }

  ngOnInit() {
    this.auftrag = this.dialogData.auftrag;
    this.tabGroup.selectedIndex = this.dialogData.currentIndex;
  }

  /**
   * Tabwechsel Event.
   * @param $event Tabindex, zu dem gewechselt wird.
   */
  selectedIndexChange($event: any) {

    // Fix fuer den Tabellenbreite Bug
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);

    switch (this.currentIndex) {
      case 0:
        if (this.currentIndex === $event) {
          return;
        }
        if (this.besamungComp.checkHasFormDataChanged()) {
          this.tabGroup.selectedIndex = this.currentIndex;

          const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
            headline: this.modaTranslateService.translate('WARENAUSWAHL.TAB_WECHSEL_HEADLINE'),
            message: this.modaTranslateService.translate('WARENAUSWAHL.TAB_WECHSEL_MESSAGE')
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.tabGroup.selectedIndex = $event;
              this.currentIndex = $event;
            }
          });
        } else {
          this.currentIndex = $event;
        }
        break;
      case 1:
        this.currentIndex = $event;
        break;
      case 2:
        this.currentIndex = $event;
        break;
      default:
    }
  }
}
