export class FilterUtils {

  /**
   * Prueft, ob der Wert dem Filter-Value matcht.
   *
   * @param value Wert
   * @param filterValue Filter-Value
   * @return true, wenn der Wert der Filter-Value matcht.
   */
  static match(value: string, filterValue: any): boolean {
    if (filterValue) {
      return value && this.createRegExpIgnoreCase(filterValue.toString()).test(value.toString());
    }
    return true;
  }

  /**
   * Erstellt ein ReqExp aus dem uebergebenen String.
   * @param value Pattern
   */
  private static createRegExpIgnoreCase(value: string): RegExp {
    return new RegExp(value.toString(), 'i');
  }
}
