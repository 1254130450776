<div class="headline">
  <label>{{ 'WARENAUSWAHL.HEADLINE' | translate:{
      'betriebsschluessel': getTitle()
  } }}
  </label>
</div>
<mat-tab-group #tabGroup (selectedIndexChange)="selectedIndexChange($event)" mat-stretch-tabs>
  <mat-tab label="{{ 'WARENAUSWAHL.BESAMUNG' | translate }}">
    <portal-besamung #besamungComp [auftrag]="auftrag"></portal-besamung>
  </mat-tab>
  <mat-tab label="{{ 'WARENAUSWAHL.DIENSTLEISTUNGEN' | translate }}">
    <portal-tu-dienstleistungen [auftrag]="auftrag"></portal-tu-dienstleistungen>
  </mat-tab>
  <mat-tab label="{{ 'WARENAUSWAHL.AGRARARTIKEL' | translate }}">
    <portal-non-sperma-artikel [auftrag]="auftrag"></portal-non-sperma-artikel>
  </mat-tab>
</mat-tab-group>
