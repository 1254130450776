import { KeycloakService } from 'keycloak-angular';

import { environment } from '../environments/environment';

export const appInitializerFactory = (keycloakService: KeycloakService): () => Promise<any> => (): Promise<any> => new Promise(
  async (resolve, reject) => {
    try {
      await keycloakService.init({
        config: environment.keycloak,
        bearerPrefix: 'Bearer',
        bearerExcludedUrls: [
          '/assets',
          '/clients/public'
        ],
        initOptions: {
          checkLoginIframe: false
        }
      }).then(() => {
        if (!keycloakService.getKeycloakInstance().authenticated) {
          keycloakService.login();
        }
      });
      resolve(null);
    } catch (error) {
      reject(error);
    }
  });
