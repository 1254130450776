<header class="[ c-header ]">
  <div class="[ c-header__logo-container ]">
    <img alt="SVG" class="[ c-header__logo ]" src="{{ logoPath }}" />
  </div>
  <div class="[ c-header__text ]">
    <label>{{ 'HEADER.TITLE' | translate }}</label>
  </div>
  <nav class="[ c-header__nav-bar ]">
    <ul class="[ c-header__nav-container ]">
      <ng-container *ngFor="let menuItem of navigation; let index=index">
        <li (mouseenter)="mouseHovering('subMenu-' + index, 1)"
          (mouseleave)="mouseLeft(1)"
          *ngIf="checkAuthentication(menuItem['roles'])"
          class="[ c-header__menu-item ]">
          <a [ngClass]="{'c-header__item-link--noClick': !menuItem['link']}"
            class="[ c-header__item-link ]"
            routerLink="{{ menuItem['link'] }}"
            translate="{{ menuItem['item'] }}">
          </a>
          <ul *ngIf="menuItem['subMenu'] && hover && openSubMenu === 'subMenu-' + index"
            appResizeSubMenu
            class="[ c-header__sub-nav-container ]"
            id="subMenu-{{index}}">
            <li
              *ngFor="let subMenuItem of menuItem['subMenu']"
              [hidden]="!checkAuthentication(subMenuItem['roles'])"
              class="[ c-header__sub-menu-item ]">
              <a [ngClass]="{'c-header__item-link--noClick': !subMenuItem['link']}"
                [queryParams]="subMenuItem['queryParams']?subMenuItem['queryParams']:null"
                [routerLink]="subMenuItem['link']"
                class="[ c-header__item-link ]"
                translate="{{ subMenuItem['item'] }}">
              </a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
    <div *ngIf="isBesamungstechniker && user" class="[ c-header__text ]">
      <label>{{ (this.vertreter && this.vertreter.length) ?
          'V: ' + (this.vertreter.length <= 3
          ? this.vertreter
          : this.vertreter.slice(0, 3) + ',...') : ''}}</label>
    </div>
    <button (click)="login()" *ngIf="!authenticated" class="[ c-header__button ]"
      title="{{ 'HEADER.LOGIN' | translate }}">
      <i aria-hidden="true" class="[ icon-account ]  [ c-header__icon ]"></i>
    </button>
    <button (click)="logout()" *ngIf="authenticated" class="[ c-header__button ]"
      title="{{ 'HEADER.LOGOUT' | translate }}">
      <i aria-hidden="true" class="[ icon-account ]  [ c-header__icon ]"></i>
    </button>
    <div *ngIf="user" class="[ c-header__text ]">
      <label>{{ user }}</label>
    </div>
  </nav>
</header>
