<div class="[ panel cattleuebersicht ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{'TIERUEBERSICHT_GESAMT.TIER' | translate}}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]" id="data-list">
          <ngx-datatable
              #tieruebersichtGesamtTabelle
            (activate)="onActivate($event)"
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="150" name="{{'TIERUEBERSICHT_GESAMT.ALPHA_LOM' | translate}}" prop="alphaLom">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="180" name="{{'TIERUEBERSICHT_GESAMT.NAME' | translate}}" prop="name">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="true" [width]="80"
              name="{{'TIERUEBERSICHT_GESAMT.STALL_NUMMER' | translate}}"
              prop="stallNummer">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                  <span (click)="sort()" class="pointer right-cell-header"
                    title="{{'TIERUEBERSICHT_GESAMT.STALL_NUMMER' | translate}}">
                    {{'TIERUEBERSICHT_GESAMT.STALL_NUMMER' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="70"
              name="{{'TIERUEBERSICHT_GESAMT.RASSE' | translate}}" prop="rasse.shortLabel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'"
              [sortable]="true" [width]="100"
              name="{{'TIERUEBERSICHT_GESAMT.STATUS' | translate}}" prop="status">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="140" name="{{'TIERUEBERSICHT_GESAMT.GEBURTSDATUM' | translate}}" prop="animalBirthdate">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L'}}">{{value | datexpipe: 'L'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="140"
              name="{{'TIERUEBERSICHT_GESAMT.LETZTE_BESAMUNG' | translate}}"
              prop="letzteBesamung.inseminationDate">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L'}}">{{value | datexpipe: 'L'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="150" name="{{'TIERUEBERSICHT_GESAMT.LETZTE_KALBUNG' | translate}}">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.lastCalvingDate"
                  title="{{row.lastCalvingDate | datexpipe: 'L'}}, {{row.calvingCount}}">
                  {{row.lastCalvingDate | datexpipe: 'L'}}, {{row.calvingCount}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="90" name="{{'TIERUEBERSICHT_GESAMT.BETRIEB_KURZ_NR' | translate}}"
              prop="farmInfo.oldShortNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'TIERUEBERSICHT_GESAMT.BETRIEB_KURZ_NR' | translate}}">
                  {{'TIERUEBERSICHT_GESAMT.BETRIEB_KURZ_NR' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="170" name="{{'TIERUEBERSICHT_GESAMT.BETRIEB_NAME' | translate}}"
              prop="farmInfo.adresse.nachname">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.farmInfo"
                  title="{{ row.farmInfo.adresse?.nachname}}, {{ row.farmInfo.adresse?.vorname }}">
                  {{ row.farmInfo.adresse?.nachname}}, {{ row.farmInfo.adresse?.vorname }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="380" name="{{'TIERUEBERSICHT_GESAMT.BETRIEB_ADRESSE' | translate}}" prop="farmInfo.adresse">
              <ng-template let-row="row" ngx-datatable-cell-template>
                 <span *ngIf="row.farmInfo"
                   title="{{ row.farmInfo.adresse?.strasseHausnummer}}, {{ row.farmInfo.adresse?.zip }} {{ row.farmInfo.adresse?.ort}}, {{row.farmInfo.adresse?.ortsteil}}">
                   {{ row.farmInfo.adresse?.strasseHausnummer}}
                   , {{ row.farmInfo.adresse?.zip }} {{ row.farmInfo.adresse?.ort}}, {{row.farmInfo.adresse?.ortsteil}}
                 </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="50"
              [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i (click)="showTierDetails(row)" aria-hidden="true" class="[ icon-ic_inspect icon-btn ]"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="tieruebersichtGesamtTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
