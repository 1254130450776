import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spermaart'
})
export class SpermaartPipe implements PipeTransform {

  /**
   * Transformiert die uebergebene Spermaart in Kleinschrift und filtert tg raus.
   *
   * @param value Spermaart
   */
  transform(value: string): string {
    return (value == null || value.trim().toLowerCase() === 'tg')
      ? ''
      : value.toLowerCase();
  }

}
