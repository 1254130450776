import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Utils } from '../utils/utils';
import { CustomErrorHandlerService } from './custom-error-handler/custom-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ModaTranslateService {

  constructor(private readonly translateService: TranslateService,
              private readonly customErrorHandlerService: CustomErrorHandlerService) {
  }

  /**
   * Gets the translated value of a key (or an array of keys)
   * @returns the translated key, or an object of translated keys
   */
  get(key: string | Array<string>, interpolateParams?: any): Observable<string | any> {
    return this.translateService.get(key, interpolateParams);
  }

  /**
   * Gets the translated value of a key (or an array of keys)
   * @returns the translated key, or an object of translated keys
   */
  translate(key: string | Array<string>, interpolateParams?: any): string {
    let message;
    Utils.unsubscribeSubscription(this.get(key, interpolateParams).subscribe(msg => message = msg,
      error => this.customErrorHandlerService.handleError(error)));
    return message;
  }

}
