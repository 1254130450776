import { Component, OnInit } from '@angular/core';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterType } from '../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { Agrarartikel } from '../../model/agrarartikel';
import { AgrarartikelService } from '../../service/agrarartikel/agrarrartikel.service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-test',
  templateUrl: './bestanduebersicht.component.html',
  styleUrls: ['./bestanduebersicht.component.scss']
})
export class BestanduebersichtComponent extends AbstractServerPaginationTable<Agrarartikel> implements OnInit {

  sortField = [
    {
      prop: 'artikelnummer',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  searchFields: SearchFieldDefinition[];

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly agrarartikelService: AgrarartikelService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   *  {@inheritDoc}.
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    this.agrarartikelService.loadDataAktivArtikel(searchFilterPagingResource).subscribe(value => {
      this.doAfterLoadData(value);
    }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions() {
    if (!this.searchFields) {
      this.searchFields = [
        new SearchFieldDefinition('artikelnummer', FilterType.STRING),
        new SearchFieldDefinition('bezeichnung', FilterType.STRING)
      ];
    }
    return this.searchFields;
  }

  /**
   * {@inheritDoc}
   */
  getService(): ServerPaginationService<Agrarartikel> {
    return this.agrarartikelService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return this.sortField;
  }

  ngOnInit() {
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Row Identität
   * @param row Zeile
   */
  public rowIdentity(row: Agrarartikel) {
    return row.artikelnummer;
  }
}
