<div class="panel bestandsuebersicht">
  <div class="titlebar">
    <div class="title">
      {{'ARTIKELLISTE.TITLE' | translate}}
    </div>
  </div>
  <div class="panel-content">
    <div class="toolbar">
      <div class="toolbar-left">
        <portal-search (search)="doOrSearch($event)"
          [fields]="getSearchFieldDefinitions()"></portal-search>
      </div>
    </div>
    <div class="content-table">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable #bestandTable
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              [sortable]="true"
              name="{{'ARTIKELLISTE.ARTIKELNUMMER' | translate}}" prop="artikelnummer">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              name="{{'ARTIKELLISTE.BEZEICHNUNG' | translate}}" prop="bezeichnung">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              name="{{'ARTIKELLISTE.BEMERKUNG' | translate}}" prop="bemerkung">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              [sortable]="true"
              name="{{'ARTIKELLISTE.PREIS' | translate}}" prop="vkPreis">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell"
                  title="{{ value.amount | currency:value.currency }}">{{ value.amount | currency:value.currency }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="bestandTable"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>


