<div class="[ panel auftragdetail ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'AUFTRAG_DETAIL.UEBERSICHT' | translate }}
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="[ panel-content ]">
      <div class="[ betriebscontainer ]">
        <div class="[ c-info-panel ]">
          <div class="[ c-info-panel-left ]">
            <div class="[ c-output-container ]">
              <label for="betriebsschluessel">{{ 'AUFTRAG_DETAIL.BETRIEB' | translate }}</label>
              <output id="betriebsschluessel">{{ betriebsbezeichnung }}</output>
            </div>
          </div>
          <div class="[ c-info-panel-right ]">
            <div class="[ date ]">
              <portal-date
                [datePlaceholder]="('AUFTRAG_DETAIL.AUFTRAGSDATUM' | translate)"
                [dateValue]="dialogData.auftragspositionen.length > 0 ?
                  dialogData.auftragspositionen[0].auftragsdatum : null"
                [readonly]="true">
              </portal-date>
            </div>
          </div>
        </div>
      </div>
      <div class="[ content-table ]">
        <div class="[ c-data-list ]">
          <ngx-datatable
              #auftragspositionTabelle
            [columnMode]="'force'"
            [count]="10"
            [cssClasses]="customClasses"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="10"
            [rowHeight]="49"
            [rows]="dialogData.auftragspositionen"
            [selected]="selected"
            [selectionType]="'single'"
            class="[ c-data-list__table ] "
            style="height:604px">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="50" name="{{'AUFTRAG_DETAIL.TYP' | translate}}" prop="type">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{ value +'_TITLE' | translate }}">{{value | translate }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="85"
              prop="stallnummer">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAG_DETAIL.STALLNUMMER_TITLE' | translate}}">
                  {{'AUFTRAG_DETAIL.STALLNUMMER' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="80" name="{{'AUFTRAG_DETAIL.RASSE' | translate}}" prop="rasse.shortLabel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'AUFTRAG_DETAIL.LOM' | translate}}" prop="cattleKey.lom">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAG_DETAIL.LOM' | translate}}">
                  {{'AUFTRAG_DETAIL.LOM' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="40">
              <ng-template ngx-datatable-header-template>
                <span title="{{'AUFTRAG_DETAIL.ANFAHRT_TITLE' | translate}}">
                  {{'AUFTRAG_DETAIL.ANFAHRT' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i *ngIf="row.specialCharge?.includes(besamungsdienstleistungenService.getVitArtikelnummerAnfahrt())"
                  class="[ icon-baseline-done-24px ]"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="40">
              <ng-template ngx-datatable-header-template>
                <span title="{{'AUFTRAG_DETAIL.SPERMAPREIS_BERECHNEN_TITLE' | translate}}">
                  {{'AUFTRAG_DETAIL.SPERMAPREIS_BERECHNEN' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i *ngIf="row.berechneSpermapreis"
                  class="[ icon-baseline-done-24px ]"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              prop="itemNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAG_DETAIL.ARTIKELNUMMER_TITLE' | translate}}">
                  {{'AUFTRAG_DETAIL.ARTIKELNUMMER' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'AUFTRAG_DETAIL.BEZEICHNUNG' | translate}}" prop="bezeichnung">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="70" prop="bulle.label">
              <ng-template ngx-datatable-header-template>
                <span title="{{'AUFTRAG_DETAIL.BEHANDLUNGSART_TITLE' | translate}}">
                  {{'AUFTRAG_DETAIL.BEHANDLUNGSART' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.bulle" title="{{ row.bulle.label | spermaart }}">
                  {{  row.bulle.label | spermaart }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="70" name="{{'AUFTRAG_DETAIL.LAGER' | translate}}">
              <ng-template ngx-datatable-header-template>
                <span class="right-cell-header" title="{{'AUFTRAG_DETAIL.LAGER' | translate}}">
                  {{'AUFTRAG_DETAIL.LAGER' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngIf="row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG' && row.anrechtssperma" class="right-cell"
                  title="{{ row.shortNumber }}">{{ row.shortNumber }}</div>
                <div *ngIf="row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG' && !row.anrechtssperma"
                  class="right-cell" title="{{ row.technikerKey.number }}">{{ row.technikerKey.number }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="85" name="{{'AUFTRAG_DETAIL.CHARGE' | translate}}" prop="lotNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAG_DETAIL.CHARGE' | translate}}">
                  {{'AUFTRAG_DETAIL.CHARGE' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="80" name="{{'AUFTRAG_DETAIL.MENGE' | translate}}" prop="quantity">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header" title="{{'AUFTRAG_DETAIL.MENGE' | translate}}">
                  {{'AUFTRAG_DETAIL.MENGE' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="false" [width]="90" prop="anzahlDirektverkauf">
              <ng-template ngx-datatable-header-template>
                <span class="right-cell-header" title="{{'AUFTRAG_DETAIL.ANZAHL_D_TITLE' | translate}}">
                  {{'AUFTRAG_DETAIL.ANZAHL_D' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="false" [width]="105" prop="anzahlBestellung">
              <ng-template ngx-datatable-header-template>
                <span class="right-cell-header" title="{{'AUFTRAG_DETAIL.ANZAHL_B_TITLE' | translate}}">
                  {{'AUFTRAG_DETAIL.ANZAHL_B' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-client-pagination-footer #paginationFooter
                  [rows]="data" [selected]="selected" [table]="auftragspositionTabelle"
                  class="[ table-footer ]"></portal-table-client-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
      <div class="[ buttons-right button-panel ]">
        <button (click)="schliessen()" class="[ c-mat-button no-color-button ]" mat-button>
          <i aria-hidden="true"></i>
          {{'BUTTON.SCHLIESSEN' | translate}}
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
