<div class="[ page-count ]">
  <div>
    <span class="[ c-data-list__text-italic ]">{{ 'TABLE.PAGING.ANZAHL' | translate }} &nbsp;</span>
    <span class="[ c-data-list__text-bold ]">{{ table.rows.length }}</span>
  </div>
</div>
<datatable-pager
  (change)="onFooterPage($event)"
  [count]="table.rows.length"
  [pagerLeftArrowIcon]="'icon-baseline-chevron_left-24px'"
  [pagerNextIcon]="'icon-baseline-last_page-24px'"
  [pagerPreviousIcon]="'icon-baseline-first_page-24px'"
  [pagerRightArrowIcon]="'icon-Gruppe-maskieren-1'"
  [size]="selectedPageSize">
</datatable-pager>
<div class="[ result-changer ]">
  <span class="[ c-data-list__text-italic ]  [ c-data-list__result-text ]">{{ 'TABLE.PAGING.CHANGER' | translate }}
    &nbsp;</span>
  <div class="[ c-data-list__select-results-wrapper ]">
    <select (change)="setPageSize(selectedPageSize)" [(ngModel)]="selectedPageSize"
      class="[ c-data-list__select-results ]">
      <option *ngFor="let size of pageSizeList" [ngValue]="size">{{ size }}</option>
    </select>
    <i aria-hidden="true" class="[ fa fa-caret-down ]  [ c-icon ]  [ c-data-list__footer-icon ]"></i>
  </div>
</div>
