<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'VERTRETUNG_BETRIEB.TITLE' | translate }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ form-content ]">
      <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
        [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
      <div *ngIf="isBesamungstechniker" class="[ checkbox-panel ]">
        <mat-checkbox (change)="loadDataZugewiesene($event)"
          [(ngModel)]="nurZugewiesene">{{'VERTRETUNG_BETRIEB.EIGENE_ANZEIGEN' | translate}}</mat-checkbox>
        <mat-checkbox (change)="loadDataManuellZugewiesene($event)"
          [(ngModel)]="nurManuellZugewiesene"
          class="[ margin-left ]">{{'VERTRETUNG_BETRIEB.MANUELL_ZUGEWIESENE' | translate}}</mat-checkbox>
      </div>
      <div class="[ button-panel margin-right-left-auto ]">
        <button (click)="zuruecksetzen()" class="[ c-mat-button  green-button ]"
          mat-flat-button>
          <i aria-hidden="true" style="margin-right: 10px"></i>
          {{'BUTTON.ZURUECKSETZEN' | translate}}
        </button>
        <button (click)="vertreten()" class="[ c-mat-button green-button margin-left ]" mat-flat-button>
          <i aria-hidden="true" style="margin-right: 10px"></i>
          {{'BUTTON.ZUWEISUNGEN_AENDERN' | translate}}
        </button>
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable
              #vertretungBetriebTabelle
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'multiClick'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [canAutoResize]="false" [cellClass]="'c-data-list__column-first'" [draggable]="false"
              [resizeable]="false" [sortable]="true" [width]="38">
              <ng-template ngx-datatable-header-template></ng-template>
              <ng-template
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
                let-row="row"
                ngx-datatable-cell-template>
                <input
                  (change)="onCheckboxChangeFn($event)"
                  [checked]="isSelected"
                  [disabled]="row.isVitZuordnung"
                  type="checkbox"
                />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              [sortable]="true" name="{{'VERTRETUNG_BETRIEB.KURZNUMMER' | translate}}" prop="oldShortNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                  <span (click)="sort()" class="pointer right-cell-header"
                    title="{{'VERTRETUNG_BETRIEB.KURZNUMMER' | translate}}">
                    {{'VERTRETUNG_BETRIEB.KURZNUMMER' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="70" name="{{'VERTRETUNG_BETRIEB.BETRIEBSSTAETTE' | translate}}" prop="betriebsschluessel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="80" name="{{'VERTRETUNG_BETRIEB.NAME' | translate}}" prop="name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.adresse.nachname}}, {{ row.adresse.vorname }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="150" name="{{'VERTRETUNG_BETRIEB.ADRESSE' | translate}}" prop="adresse">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.adresse.strasseHausnummer}}, {{ row.adresse.zip }} {{ row.adresse.ort}}, {{row.adresse.ortsteil}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="80" name="{{'VERTRETUNG_BETRIEB.STAMMBESAMUNGSTECHNIKER' | translate}}"
              prop="stammtechnikername">
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [multiSelect]="true" [placeholder]="'TABLE.PAGING.ZUGEWIESEN'"
                  [selectedLength]="vertreterBetriebe.length"
                  [selected]="selected" [table]="vertretungBetriebTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
