import * as moment from 'moment';
import { Moment } from 'moment';

export class DateUtils {

  static getMinDate(): Moment {
    return moment('01.01.1970', 'L');
  }

  static getMaxDate(): Moment {
    return moment('31.12.9999', 'L');
  }
}
