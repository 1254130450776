import { Component, OnInit } from '@angular/core';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterCriteria } from '../../core/filter/filterCriteria';
import { FilterCriteriaResource } from '../../core/filter/filterCriteriaResource';
import { FilterType } from '../../core/filter/filterType';
import { SortDirection } from '../../core/filter/sort-direction';
import { Spermaartikel } from '../../model/spermaartikel';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SpermaartikelService } from '../../service/spermaartikel/spermaartikel.service';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-bestand-betriebssperma',
  templateUrl: './bestand-betriebssperma.component.html',
  styleUrls: ['./bestand-betriebssperma.component.scss']
})
export class BestandBetriebsspermaComponent extends AbstractServerPaginationTable<Spermaartikel> implements OnInit {

  sortField = [
    {
      prop: 'name',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  filterNichtAusreichend: boolean;

  isBesamungstechniker: boolean;

  minValue = '1';

  private bestandFilterCriteria: FilterCriteria;

  private technikerFilterCriteria: FilterCriteria;

  private readonly SPERMA_BESTAND_BS_GREATER_THAN_VS = 'spermaBestandBSGreaterThanVS';

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly spermaartikelService: SpermaartikelService,
              private readonly modaKeycloakService: ModaKeycloakService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  changeNichtAusreichendCheckbox(): void {
    if (this.filterNichtAusreichend) {
      this.bestandFilterCriteria.addFilter(this.SPERMA_BESTAND_BS_GREATER_THAN_VS, 'true', FilterType.STRING);
      this.searchFilterPagingResource.page = 0;
    } else {
      const indexOfFilterToRemove = this.bestandFilterCriteria.getFilterCriteria().findIndex(
        filterCriteria => filterCriteria.key === this.SPERMA_BESTAND_BS_GREATER_THAN_VS);
      if (indexOfFilterToRemove > -1) {
        this.bestandFilterCriteria.getFilterCriteria().splice(indexOfFilterToRemove, 1);
      }
    }
    this.joinedDoAndSearch();
  }

  /**
   * Merkt sich die aktuelles Filtercriterium und ruft die Suche auf.
   * @param filterCriteria Filtercriteria
   */
  public doAndSearchBestand(filterCriteria: FilterCriteria) {
    this.bestandFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   *  {@inheritDoc}.
   */
  doLoadData() {
    this.spermaartikelService.loadDataByBetriebssperma(this.searchFilterPagingResource).subscribe(value => {
      this.doAfterLoadData(value);
    }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Filtern nach Von Bestand.
   */
  getSearchFieldAmount() {
    return [
      new SearchFieldDefinition('spermabestandAnrechtList.amount', FilterType.NUMBER)
    ];
  }

  /**
   * Liefert das Suchfeld fuer den Min-Max-Filter.
   */
  getSearchFieldDefinitionBestand(): string {
    return 'spermabestandAnrechtList.amount';
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('name', FilterType.STRING),
      new SearchFieldDefinition('herdbookNumber', FilterType.STRING),
      new SearchFieldDefinition('spermabestandAnrechtList.shortNumber', FilterType.STRING),
      new SearchFieldDefinition('spermabestandAnrechtList.adresse.nachname', FilterType.STRING),
      new SearchFieldDefinition('spermabestandAnrechtList.adresse.vorname', FilterType.STRING)
    ];
  }

  getService(): ServerPaginationService<Spermaartikel> {
    return this.spermaartikelService;
  }

  /**
   * {@inheritDoc}
   */
  getSortFields(): any {
    return [
      {
        prop: 'name',
        dir: SortDirection.ASC.frontendkuerzel
      },
      {
        prop: 'shortNumber',
        dir: SortDirection.ASC.frontendkuerzel
      }
    ];
  }

  ngOnInit() {
    this.bestandFilterCriteria = new FilterCriteria();
    const defaultBestandVonFilter = new FilterCriteriaResource(this.getSearchFieldDefinitionBestand(), {
      min: this.minValue
    }, FilterType.RANGE);
    this.bestandFilterCriteria.addFilterCriteriaResource(defaultBestandVonFilter);
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.initPagination();
    this.setDefaultSortCriteria();
    this.joinedDoAndSearch();
  }

  /**
   * {@inheritDoc}
   */
  rowIdentity(row: Spermaartikel) {
    return row.id + row.spermabestandAnrechtList[0].companyNumber + row.herdbookNumber;
  }

  /**
   * Fuegt alle  Filtercriteria Techniker und Bestand zusammen.
   */
  private joinedDoAndSearch() {
    const fc: FilterCriteria = new FilterCriteria();
    if (this.bestandFilterCriteria) {
      this.bestandFilterCriteria.getFilterCriteria().forEach(fcr => fc.addFilterCriteriaResource(fcr));
    }
    if (this.technikerFilterCriteria) {
      this.technikerFilterCriteria.getFilterCriteria().forEach(fcr => fc.addFilterCriteriaResource(fcr));
    }
    this.doAndSearch(fc);
  }
}
