import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterType } from '../../core/filter/filterType';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { Farm } from '../../model/farm';
import { TechnikerDetail } from '../../model/techniker-detail';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { FarmService } from '../../service/farm/farm.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { NotificationService } from '../../service/notification/notification.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { DialogUtils } from '../../utils/dialog-utils';
import { Utils } from '../../utils/utils';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-vertretung-betrieb',
  templateUrl: './vertretung-betrieb.component.html',
  styleUrls: ['./vertretung-betrieb.component.scss']
})
export class VertretungBetriebComponent extends AbstractServerPaginationTable<Farm> implements OnInit, OnDestroy {

  sortField = [
    {
      prop: 'oldShortNumber',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  isBesamungstechniker: boolean;

  nurZugewiesene = false;

  nurManuellZugewiesene = false;

  vertreterBetriebe: Array<string> = new Array<string>();

  farmSubscription: Subscription;

  initFarmSubscription: Subscription;

  changeVertretungSubscription: Subscription;

  resetSubscription: Subscription;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly farmService: FarmService, private readonly modaKeycloakService: ModaKeycloakService,
              private readonly notificationService: NotificationService, private readonly dialog: MatDialog) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Die Felder, die nicht aus der Db kommen, werden hier vorbereitet.
   *
   * @param page Farm-Page
   */
  calculateData(page: Page<Farm>) {
    const technikernummer = this.modaKeycloakService.getTechnikernummer();
    this.selected = [];
    for (const farm of page.content) {
      const technikerInBetrieb: TechnikerDetail = farm.technikerDetails.find(
        techniker => techniker.technikerKey.number === technikernummer);
      const vertreterInBetrieb = this.vertreterBetriebe.includes(farm.betriebsschluessel);
      if (technikerInBetrieb || vertreterInBetrieb) {
        if (vertreterInBetrieb && technikerInBetrieb && !technikerInBetrieb.vertretung) {
          farm.isVitZuordnung = true;
        }
        this.selected.push(farm);
      }
    }
  }

  /**
   * Sortierung der Felder.
   * @param event Event
   */
  determineSortField(event): string {
    switch (event.column.prop) {
      case 'name':
        return 'adresse.nachname';
      default:
        return event.column.prop;
    }
  }

  /**
   * {@inheritDoc}
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    if (this.nurZugewiesene) {
      this.loadDataSubscription = this.farmService.loadDataForUser(searchFilterPagingResource).subscribe(data => {
        this.doAfterLoadData(data);
      }, error => this.customErrorHandlerService.handleError(error));
    } else if (this.nurManuellZugewiesene) {
      this.loadDataSubscription = this.farmService.loadDataIsVertreter(searchFilterPagingResource).subscribe(data => {
        this.doAfterLoadData(data);
      }, error => this.customErrorHandlerService.handleError(error));
    } else {
      this.loadDataSubscription = this.farmService.loadData(searchFilterPagingResource).subscribe(data => {
        this.doAfterLoadData(data);
      }, error => this.customErrorHandlerService.handleError(error));
    }
  }

  /**
   * Selektiert bzw. deselektiert die Zeilen, wenn diese keine Zuordnung der Vit sind.
   *
   * @param selected Selektierte Zeilen
   */
  doSelect(selected: any) {
    this.selected = this.data.filter(farm => farm.isVitZuordnung);
    this.selected.push(...selected.filter(farm => !farm.isVitZuordnung));
    this.data.filter(farm => !farm.isVitZuordnung).forEach(value => {
      if (this.selected.includes(value)) {
        if (!this.vertreterBetriebe.includes(value.betriebsschluessel)) {
          this.vertreterBetriebe.push(value.betriebsschluessel);
        }
      } else {
        if (this.vertreterBetriebe.includes(value.betriebsschluessel)) {
          this.vertreterBetriebe.splice(
            this.vertreterBetriebe.findIndex(betriebsschluessel => betriebsschluessel === value.betriebsschluessel), 1);
        }
      }
    });
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('oldShortNumber', FilterType.STRING),
      new SearchFieldDefinition('betriebsschluessel', FilterType.STRING),
      new SearchFieldDefinition('nachname', FilterType.STRING),
      new SearchFieldDefinition('vorname', FilterType.STRING),
      new SearchFieldDefinition('strasseHausnummer', FilterType.STRING),
      new SearchFieldDefinition('ort', FilterType.STRING),
      new SearchFieldDefinition('shortAddress', FilterType.STRING),
      new SearchFieldDefinition('stammtechnikername', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  getService(): ServerPaginationService<Farm> {
    return this.farmService;
  }

  /**
   * {@inheritDoc}
   */
  getSortFields() {
    return [
      {
        prop: 'oldShortNumber',
        dir: SortDirection.ASC.frontendkuerzel
      },
      {
        prop: 'betriebsschluessel',
        dir: SortDirection.ASC.frontendkuerzel
      }
    ];
  }

  /**
   * Nur manuell zugewiesene Betriebe anzeigen
   * @param event Event
   */
  loadDataManuellZugewiesene(event: any) {
    if (event.checked) {
      this.nurZugewiesene = false;
    }
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Nur zugewiesene Betriebe anzeigen
   * @param event Event
   */
  loadDataZugewiesene(event: any) {
    if (event.checked) {
      this.nurManuellZugewiesene = false;
    }
    this.loadData(this.searchFilterPagingResource);
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.farmSubscription);
    Utils.unsubscribeSubscription(this.initFarmSubscription);
    Utils.unsubscribeSubscription(this.loadDataSubscription);
    Utils.unsubscribeSubscription(this.changeVertretungSubscription);
    Utils.unsubscribeSubscription(this.resetSubscription);
  }

  ngOnInit() {
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.initFarmSubscription = this.farmService.findAllVertreterbetriebe().subscribe(betriebsschluessel => {
      this.vertreterBetriebe = betriebsschluessel;
    }, error => this.customErrorHandlerService.handleError(error));
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Select Event.
   *
   * @param event Event
   */
  onSelect(event) {
    if (event) {
      this.doSelect(event.selected);
    }
  }

  /**
   * Reload.
   */
  refresh() {
    this.farmSubscription = this.farmService.findAllVertreterbetriebe().subscribe(betriebsschluessel => {
      this.vertreterBetriebe = betriebsschluessel;
      this.loadData(this.searchFilterPagingResource);
    }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * {@inheritDoc}
   */
  rowIdentity(row: Farm) {
    return row.betriebsschluessel;
  }

  /**
   * Setzt den angemeldeten Benutzer zu den Betrieben mit den uebergebenen Betriebsschluesseln oder entfernt diesen.
   */
  vertreten() {
    this.changeVertretungSubscription = this.farmService.changeVertretung(this.vertreterBetriebe).subscribe(() => {
      this.loadData(this.searchFilterPagingResource);
      this.notificationService.showSuccessNotification('VERTRETUNG_BETRIEB.MESSAGE.BETRIEBE_ZUWEISEN.SUCCESS');
    }, error => {
      this.customErrorHandlerService.handleError(error);
      this.notificationService.showErrorNotification('VERTRETUNG_BETRIEB.MESSAGE.BETRIEBE_ZUWEISEN.ERROR');
    });
  }

  /**
   * Entfernt die Vertretung von den manuell zugewiesenen Betrieben.
   */
  zuruecksetzen() {
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('VERTRETUNG_BETRIEB.MESSAGE.CONFIRMATION_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate('VERTRETUNG_BETRIEB.MESSAGE.CONFIRMATION_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetSubscription = this.farmService.resetAllManualVertretung().subscribe(() => {
          this.notificationService.showSuccessNotification('VERTRETUNG_BETRIEB.MESSAGE.BETRIEBE_ZUWEISEN.SUCCESS');
          this.refresh();
        }, error => {
          this.customErrorHandlerService.handleError(error);
          this.notificationService.showErrorNotification('VERTRETUNG_BETRIEB.MESSAGE.BETRIEBE_ZUWEISEN.ERROR');
        });
      }
    });
  }
}
