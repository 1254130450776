import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DateSearchComponent } from '../../core/component/date-search/date-search.component';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterCriteriaResource } from '../../core/filter/filterCriteriaResource';
import { FilterType } from '../../core/filter/filterType';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { BookingTypePipe } from '../../core/pipe/booking-type.pipe';
import { DatexPipe } from '../../core/pipe/datex.pipe';
import { SpermaartPipe } from '../../core/pipe/spermaart.pipe';
import { AuftragspositionType } from '../../enumeration/auftragsposition-type.enum';
import { Adresse } from '../../model/adresse';
import { Auftragsposition } from '../../model/auftragsposition';
import { AuftragspositionService } from '../../service/auftrag/auftragsposition.service';
import { BesamungsdienstleistungenService } from '../../service/besamungsdienstleistungen/besamungsdienstleistungen.service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SearchFieldDefinition } from '../search/search-field-definition';

/**
 * Zaehler fuer die Ausgabe Erste Besamung, weitere Besamung, Agrarartikeln und Dienstleistungen.
 */
interface PositionCounter {
  besamungEB: number;

  besamungWB: number;

  agrar: number;

  dienstleistung: number;
}

@Component({
  selector: 'portal-leistungsprotokoll',
  templateUrl: './leistungsprotokoll.component.html',
  styleUrls: ['./leistungsprotokoll.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeistungsprotokollComponent extends AbstractServerPaginationTable<Auftragsposition> implements OnInit,
  AfterViewInit {

  static readonly AUFTRAGSDATUM_DATE_KEY: string = 'auftragsdatum';

  static readonly BESAMUNGSDATUM_DATE_KEY: string = 'inseminationDate';

  sortField = [
    {
      prop: 'receiptNumber',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  minDate: Moment = moment().subtract(6, 'months');

  nurEigene = false;

  isBesamungstechniker: boolean;

  isBapBerater: boolean;

  selected = [];

  positionCounter: PositionCounter;

  @ViewChild(DateSearchComponent, { static: true }) dateSearch: DateSearchComponent;

  @ViewChild('searchTechnikerInput', { static: true }) searchTechnikerInput: any;

  typeAgrarartikel = false;

  typeBesamung = false;

  typeDienstleistung = false;

  useBesamungsgsdatumForDateFilter = false;

  constructor(private router: Router, modaTranslateService: ModaTranslateService,
              customErrorHandlerService: CustomErrorHandlerService,
              private auftragspositionService: AuftragspositionService, private datexPipe: DatexPipe,
              private readonly modaKeycloakService: ModaKeycloakService, private readonly dialog: MatDialog,
              private readonly besamungsdienstleistungenService: BesamungsdienstleistungenService,
              private readonly modaTranslationService: ModaTranslateService, private readonly datePipe: DatexPipe,
              private readonly spermaartPipe: SpermaartPipe, private readonly  bookingTypePipe: BookingTypePipe) {
    super(modaTranslateService, customErrorHandlerService);
    this.pageSize = 5000;
  }

  /**
   * Fuehrt die Berechnung der Attribute durch, die nicht aus dem Backend kommen, aber in der View angezeigt werden
   * sollen.
   *
   * @param page Page
   */
  calculateData(page: Page<Auftragsposition>) {
    this.positionCounter = {
      besamungWB: 0,
      besamungEB: 0,
      agrar: 0,
      dienstleistung: 0
    };
    for (const pos of page.content) {
      if (pos.type === AuftragspositionType.BESAMUNG) {
        if (pos.wvb !== undefined && pos.wvb > 1) {
          this.positionCounter.besamungWB++;
        } else {
          this.positionCounter.besamungEB++;
        }
      } else if (pos.type === AuftragspositionType.AGRARARTIKEL) {
        this.positionCounter.agrar++;
      } else if (pos.type === AuftragspositionType.DIENSTLEISTUNG) {
        this.positionCounter.dienstleistung++;
      }
    }
  }

  /**
   * Laegt die Daten bei der Aenderung der nurEigene Checkbox neu.
   */
  public changeCheckbox() {
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Laegt die Daten bei der Aenderung der Typ Checkbox neu.
   */
  public changeTyp() {
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * {@inheritDoc}
   */
  compareEntity(item1: Auftragsposition, item2: Auftragsposition): boolean {
    return item1.receiptNumber !== item2.receiptNumber;
  }

  /**
   * Sortierung der Felder.
   * @param event Event
   */
  determineSortField(event): string {
    switch (event.column.prop) {
      case 'tnr':
        return 'technikerKey.number';
      case 'name':
        return 'adresse.nachname';
      default:
        return event.column.prop;
    }
  }

  /**
   * {@inheritDoc}
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    // Make sure we are no longer selecting all
    this.selectAllOnPage[this.currentPage.number] = false;
    const filterCriteriaList = this.searchFilterPagingResource.andFilterCriteriaResourceList;
    this.initDateRange();
    filterCriteriaList.forEach((filter, index) => {
      if ((filter.key === LeistungsprotokollComponent.AUFTRAGSDATUM_DATE_KEY || filter.key
        === LeistungsprotokollComponent.BESAMUNGSDATUM_DATE_KEY) && this.dateSearch.dateFromValue === null) {
        filter.value.from = this.minDate;
      } else if (filter.key === 'type') {
        filterCriteriaList.splice(index, 1);
      } else if (filter.key === 'number') {
        filterCriteriaList.splice(index, 1);
      }
    });
    if (this.searchTechnikerInput.searchField.nativeElement.value) {
      const technikerNrFilter = new FilterCriteriaResource('number',
        +this.searchTechnikerInput.searchField.nativeElement.value, FilterType.NUMBER);
      filterCriteriaList.push(technikerNrFilter);
    }
    this.createFilterCriteriaTyp();
    if (this.nurEigene) {
      this.loadDataSubscription = this.auftragspositionService.loadData(searchFilterPagingResource).subscribe(data => {
        this.doAfterLoadData(data);
      }, error => this.customErrorHandlerService.handleError(error));
    } else {
      this.loadDataSubscription =
        this.auftragspositionService.loadBetriebData(searchFilterPagingResource).subscribe(data => {
          this.doAfterLoadData(data);
        }, error => this.customErrorHandlerService.handleError(error));
    }
  }

  /**
   * Leistungsprotokoll als PDF exportieren.
   */
  public exportPdf() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageOrientation: 'landscape',
      defaultStyle: {
        fontSize: 7
      },
      styles: {
        header: {
          fontSize: 12,
          bold: true
        }
      },
      content: [
        {
          text: this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.POS_HISTORIE') + '\n\n',
          style: 'header'
        },
        {
          layout: 'lightHorizontalLines', // optional
          width: 'auto',
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              '*',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto'
            ],
            body: this.generatePdfBody()
          }
        },
        `\n\nGesamt Besamungen: ${this.selected.filter(
          value => AuftragspositionType.BESAMUNG === value.type).length} (EB:${this.selected.filter(
          value => AuftragspositionType.BESAMUNG === value.type && (value.wvb === 1)).length};WB:${this.selected.filter(
          value => AuftragspositionType.BESAMUNG === value.type && (value.wvb
            > 1)).length})  Agrarartikel: ${this.selected.filter(
          value => AuftragspositionType.AGRARARTIKEL === value.type).length} Dienstleistungen: ${this.selected.filter(
          value => AuftragspositionType.DIENSTLEISTUNG === value.type).length}`
      ]
    };
    pdfMake.createPdf(docDefinition).download(
      this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.POS_HISTORIE') + '.pdf');
  }

  /**
   * Erzeugt den Body der Tabelle, die exportiert wird.
   */
  generatePdfBody() {
    const body = [
      [
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.TYP'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.POSITIONSNUMMER'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.TECHNIKER'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.DATUM'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.BESAMUNGSDATUM'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.SHORTNUMBER'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.NAME'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.STALL_NR'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.LOM'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.WVB'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.ANFAHRT'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.SPERMAPREIS_BERECHNEN'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.BOOKING_TYPE'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.ARTIKELNUMMER'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.BEZEICHNUNG'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.BEHANDLUNGSART'),
        this.modaTranslationService.translate('LEISTUNGSPROTOKOLL.MENGE')
      ]
    ];
    for (const row of this.selected) {
      body.push([
        this.modaTranslationService.translate(row.type),
        row.receiptNumber,
        row.technikerKey.number,
        this.datePipe.transform(row.auftragsdatum, 'L'),
        this.getInseminationDate(row),
        row.shortNumber
          ? row.shortNumber
          : '',
        row.adresse
          ? this.generateName(row.adresse)
          : '',
        row.stallnummer
          ? row.stallnummer
          : '',
        row.cattleKey
          ? row.cattleKey.lom
          : '',
        row.wvb
          ? row.wvb
          : '',
        row.specialCharge
          ? row.specialCharge.includes(this.besamungsdienstleistungenService.getVitArtikelnummerAnfahrt())
          ? 'ja'
          : ''
          : '',
        row.berechneSpermapreis
          ? 'ja'
          : '',
        row.bookingType
          ? this.bookingTypePipe.transform(row.bookingType)
          : '',
        row.itemNumber,
        row.bezeichnung
          ? row.bezeichnung
          : '',
        row.bulle
          ? this.spermaartPipe.transform(row.bulle.label)
          : '',
        row.quantity
          ? row.quantity
          : ''
      ]);
    }
    return body;
  }

  /**
   * Liefert das Feld für die Datumsuche zurueck.
   */
  getSearchFieldDate() {
    return this.useBesamungsgsdatumForDateFilter
      ? LeistungsprotokollComponent.BESAMUNGSDATUM_DATE_KEY
      : LeistungsprotokollComponent.AUFTRAGSDATUM_DATE_KEY;
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldKunde(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('vorname', FilterType.STRING),
      new SearchFieldDefinition('nachname', FilterType.STRING),
      new SearchFieldDefinition('ort', FilterType.STRING),
      new SearchFieldDefinition('ortsteil', FilterType.STRING)
    ];
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldTechniker(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('number', FilterType.NUMBER)
    ];
  }

  getService(): ServerPaginationService<Auftragsposition> {
    return this.auftragspositionService;
  }

  /**
   * {@inheritDoc}
   */
  getSortFields() {
    return this.sortField;
  }

  /**
   * Initialisierung der Min- und Maxwerte fuer den Datepicker.
   */
  initDateRange() {
    const filtercriteriaResourcen = this.searchFilterPagingResource.andFilterCriteriaResourceList;
    let alreadyExists = false;
    filtercriteriaResourcen.forEach((filtercriteria, index) => {
      if (!this.useBesamungsgsdatumForDateFilter && filtercriteria.key
        === LeistungsprotokollComponent.AUFTRAGSDATUM_DATE_KEY || this.useBesamungsgsdatumForDateFilter
        && filtercriteria.key === LeistungsprotokollComponent.BESAMUNGSDATUM_DATE_KEY) {
        alreadyExists = true;
      } else if ((this.useBesamungsgsdatumForDateFilter && filtercriteria.key
        === LeistungsprotokollComponent.AUFTRAGSDATUM_DATE_KEY) || (!this.useBesamungsgsdatumForDateFilter
        && filtercriteria.key === LeistungsprotokollComponent.BESAMUNGSDATUM_DATE_KEY)) {
        // entferne den nicht mehr benoetigten Filter, wenn die Datumsfilter-Checkbox geaendert wurde
        filtercriteriaResourcen.splice(index, 1);
      }
    });
    // wenn der gleiche Datumsfilter der vorheriges Suche verwendet wird, muss nicht neu initialisiert werden
    if (alreadyExists) {
      return;
    }
    const dateFilter = new FilterCriteriaResource(this.getSearchFieldDate(), {
      from: this.dateSearch.dateFromValue
        ? this.dateSearch.dateFromValue.startOf('day')
        : moment().startOf('day'),
      to: this.dateSearch.dateToValue
        ? this.dateSearch.dateToValue.endOf('day')
        : moment().endOf('day')
    }, FilterType.STRING);
    this.searchFilterPagingResource.andFilterCriteriaResourceList.push(dateFilter);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.selectAll();
    }, 500);
  }

  ngOnInit() {
    this.dateSearch.dateFromValue = moment();
    this.dateSearch.dateToValue = moment();
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.isBapBerater = this.modaKeycloakService.isBapBerater();
    if (this.isBesamungstechniker || this.isBapBerater) {
      this.nurEigene = true;
    }
    this.typeAgrarartikel = true;
    this.typeBesamung = true;
    this.typeDienstleistung = true;
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  public onChangeOfDatumsfilter(): void {
    if (this.useBesamungsgsdatumForDateFilter) {
      this.typeBesamung = true;
      this.typeDienstleistung = false;
      this.typeAgrarartikel = false;
    } else {
      this.typeDienstleistung = true;
      this.typeAgrarartikel = true;
    }
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row Auftrag
   */
  rowIdentity(row: Auftragsposition) {
    return row && row.receiptNumber;
  }

  /**
   * Für die Suche erstellt Filtercriteria mit Typen.
   */
  private createFilterCriteriaTyp() {
    const types = [];
    if (this.typeAgrarartikel) {
      types.push('AGRARARTIKEL');
    }
    if (this.typeBesamung) {
      types.push('BESAMUNG');
    }
    if (this.typeDienstleistung) {
      types.push('DIENSTLEISTUNG');
    }
    if (types.length) {
      const filter = new FilterCriteriaResource('type', types.join(';'), FilterType.SEPARATED_STRING);
      this.searchFilterPagingResource.andFilterCriteriaResourceList.push(filter);
    } else {
      this.searchFilterPagingResource.andFilterCriteriaResourceList.push(
        new FilterCriteriaResource('type', 'NONE', FilterType.STRING));
    }
  }

  /**
   * Generiert den Text fuer den Namen und die Adresse und ueberprueft, ob die Bauteile vorhanden sind.
   *
   * @param adresse Adresse
   */
  private generateName(adresse: Adresse) {
    let name = '';
    if (adresse.nachname) {
      name += adresse.nachname;
    }
    if (adresse.vorname) {
      if (adresse.nachname) {
        name += ', ';
      }
      name += adresse.vorname;
    }
    if (adresse.ort) {
      if (adresse.nachname || adresse.vorname) {
        name += ' - ';
      }
      name += adresse.ort;
    }
    if (adresse.ortsteil) {
      if (!adresse.ort && (adresse.nachname || adresse.vorname)) {
        name += ' - ';
      }
      if (adresse.ort) {
        name += ', ';
      }
      name += adresse.ortsteil;
    }
    return name;
  }

  private getInseminationDate(row: Auftragsposition): string {
    if (row.type === AuftragspositionType.BESAMUNG && row.inseminationDate !== undefined) {
      return this.datePipe.transform(row.inseminationDate, 'L');
    }
    return '';
  }
}
