import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DateSearchComponent } from '../../core/component/date-search/date-search.component';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterCriteriaResource } from '../../core/filter/filterCriteriaResource';
import { FilterType } from '../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { Aenderungstyp } from '../../enumeration/aenderungstyp.enum';
import { Spermatausch } from '../../model/spermatausch';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SpermatauschService } from '../../service/spermatausch/spermatausch.service';
import { DialogUtils } from '../../utils/dialog-utils';
import { Utils } from '../../utils/utils';
import { SearchFieldDefinition } from '../search/search-field-definition';
import { SpermaauswahlComponent } from './spermaauswahl/spermaauswahl.component';

@Component({
  selector: 'portal-bestelluebersicht',
  templateUrl: './bestelluebersicht.component.html',
  styleUrls: ['./bestelluebersicht.component.scss']
})
export class BestelluebersichtComponent extends AbstractServerPaginationTable<Spermatausch> implements OnInit,
  OnDestroy {

  sortField = [
    {
      prop: 'auftragsdatum',
      dir: SortDirection.DESC.frontendkuerzel
    }
  ];

  isBesamungstechniker: boolean;

  changeableDataSubscription: Subscription;

  routeQueryParamsSubscription: Subscription;

  @ViewChild(DateSearchComponent, { static: true }) dateSearch: DateSearchComponent;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly modaKeycloakService: ModaKeycloakService, private readonly dialog: MatDialog,
              private readonly spermatauschService: SpermatauschService, private readonly router: Router,
              private readonly route: ActivatedRoute) {
    super(modaTranslateService, customErrorHandlerService);
    this.routeQueryParamsSubscription = route.queryParams.subscribe(params => {
      if (params.neueBestellung) {
        this.neueBestellung();
      }
    });
  }

  /**
   * {@inheritDoc}
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    if (!this.searchFilterPagingResource.andFilterCriteriaResourceList.find(value => value.key === 'aenderungstyp')) {
      this.searchFilterPagingResource.andFilterCriteriaResourceList.push(
        new FilterCriteriaResource('aenderungstyp', 'ORDERING', FilterType.STRING));
    }
    this.loadDataSubscription = this.spermatauschService.loadData(searchFilterPagingResource).subscribe(data => {
      this.doAfterLoadData(data);
    }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Liefert das Feld für die Datumsuche zurueck.
   */
  public getSearchFieldDate() {
    return 'auftragsdatum';
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('receiptNumber', FilterType.STRING),
      new SearchFieldDefinition('name', FilterType.STRING)
    ];
  }

  /**
   *  {@inheritDoc}.
   */
  getService(): ServerPaginationService<Spermatausch> {
    return this.spermatauschService;
  }

  /**
   *  {@inheritDoc}.
   */
  public getSortFields(): any {
    return [
      {
        prop: 'auftragsdatum',
        dir: SortDirection.DESC.frontendkuerzel
      },
      {
        prop: 'receiptNumber',
        dir: SortDirection.DESC.frontendkuerzel
      }
    ];
  }

  /**
   * Neue Bestellung erfassen.
   */
  neueBestellung() {
    this.changeableDataSubscription = this.spermatauschService.loadChangeableData().subscribe(spermabestellungen => {
      const dialogRef = this.dialog.open(SpermaauswahlComponent, {
        minWidth: '1216px',
        panelClass: 'vost-modal-dialog',
        data: {
          bestellung: spermabestellungen.filter(value => value.aenderungstyp === Aenderungstyp.ORDERING)
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(results => {
        if (results) {
          this.createOrUpdateOrDeletePosition(results);
        }
        this.router.navigate(['.'], { relativeTo: this.route });
      });
    }, error => this.customErrorHandlerService.handleError(error));
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.changeableDataSubscription);
    Utils.unsubscribeSubscription(this.routeQueryParamsSubscription);
  }

  ngOnInit() {
    this.dateSearch.dateFromValue = moment();
    this.dateSearch.dateToValue = moment();
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.initPagination();
    const defaultDateFilter = new FilterCriteriaResource(this.getSearchFieldDate(), {
      from: moment().startOf('day'),
      to: moment().endOf('day')
    }, FilterType.STRING);
    this.searchFilterPagingResource.andFilterCriteriaResourceList.push(defaultDateFilter);
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
    if (this.route.snapshot.paramMap.get('neueBestellung')) {
      this.neueBestellung();
    }
  }

  /**
   * Loescht eine Spermabestellung mit Sicherheitsabfrage.
   *
   * @param spermatausch Spermatausch
   */
  onDeletePosition(spermatausch: Spermatausch) {
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('BESTELLUEBERSICHT.DELETE_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate('BESTELLUEBERSICHT.DELETE_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spermatauschService.delete(spermatausch).subscribe(() => this.loadData(this.searchFilterPagingResource),
          error => this.customErrorHandlerService.handleError(error));
      }
    });
  }

  /**
   *  {@inheritDoc}.
   */
  public rowIdentity(row: Spermatausch) {
    return row.id;
  }

  /**
   * Erzeugt, bearbeitet oder loescht die uebergebenen Spermabestellungen.
   *
   * @param results Spermabestellungen
   */
  private async createOrUpdateOrDeletePosition(results) {
    for (const result of results) {
      if (!result.id) {
        await this.spermatauschService.create(result).toPromise().catch(
          error => this.customErrorHandlerService.handleError(error));
      } else if (result.anzahl === 0) {
        await this.spermatauschService.delete(result).toPromise().catch(
          error => this.customErrorHandlerService.handleError(error));
      } else {
        await this.spermatauschService.update(result).toPromise().catch(
          error => this.customErrorHandlerService.handleError(error));
      }
    }
    this.loadData(this.searchFilterPagingResource);
  }

}
