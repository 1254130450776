<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{'BESTAND_AGRARARTIKEL.BESTANDUEBERSICHT' | translate}}
    </div>
  </div>
  <div class="bestand-agrarartikel-container">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
        <portal-search (search)="doAndSearchTechniker($event)" *ngIf="!isBesamungstechniker"
          [fields]="getSearchFieldTechniker()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF_TECHNIKER_NR' | translate)" class="[ date-search ]"></portal-search>
        <portal-range-search
          (search)="doAndSearchBestand($event)" [defMinValue]="1"
          [field]="getSearchFieldDefinitionBestand()"
          [maxPlaceholder]="('SPERMAAUSWAHL.BESTAND_BIS' | translate)"
          [minPlaceholder]="('SPERMAAUSWAHL.BESTAND_VON' | translate)"
          class="[ margin-left-20 ] [ date-search ]"></portal-range-search>
      </div>
    </div>
    <div class="[ row ]">
      <div class="[ col-12 ]  [ c-data-list ]">
        <div class="[ content-table ]">
          <ngx-datatable
              #bestandAgrarartikelTabelle
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="100" [sortable]="true" name="{{'BESTAND_AGRARARTIKEL.ARTIKEL_NR' | translate}}"
              prop="artikelnummer">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_AGRARARTIKEL.ARTIKEL_NR' | translate}}">
                  {{'BESTAND_AGRARARTIKEL.ARTIKEL_NR' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'BESTAND_AGRARARTIKEL.NAME' | translate}}" prop="bezeichnung">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              name="{{'BESTAND_AGRARARTIKEL.BEMERKUNG' | translate}}" prop="bemerkung">
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="!isBesamungstechniker" [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              [sortable]="true" name="{{'BESTAND_AGRARARTIKEL.TECHNIKER' | translate}}"
              prop="agrarartikelbestand.technikerKey.number">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_AGRARARTIKEL.TECHNIKER' | translate}}">
                  {{'BESTAND_AGRARARTIKEL.TECHNIKER' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="120"
              [sortable]="true" name="{{'BESTAND_AGRARARTIKEL.BESTAND' | translate}}"
              prop="agrarartikelbestand.istBestand">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_AGRARARTIKEL.BESTAND' | translate}}">
                  {{'BESTAND_AGRARARTIKEL.BESTAND' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value > 0 ? value : 0 }}">{{ value > 0 ? value : 0 }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="120"
              [sortable]="true" name="{{'BESTAND_AGRARARTIKEL.SOLL_BESTAND' | translate}}"
              prop="agrarartikelbestand.sollBestand">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_AGRARARTIKEL.SOLL_BESTAND' | translate}}">
                  {{'BESTAND_AGRARARTIKEL.SOLL_BESTAND' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value > 0 ? value : 0 }}">{{ value > 0 ? value : 0 }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="60"
              [sortable]="true" prop="staffelpreis">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer" title="{{'BESTAND_AGRARARTIKEL.STAFFELPREIS_TITLE' | translate}}">
                {{'BESTAND_AGRARARTIKEL.STAFFELPREIS' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span
                  title="{{value | booleanTranslation | translate}}">{{value | booleanTranslation | translate}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="120"
              [sortable]="true" name="{{'BESTAND_AGRARARTIKEL.PREIS' | translate}}" prop="vkPreis">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_AGRARARTIKEL.PREIS' | translate}}">
                  {{'BESTAND_AGRARARTIKEL.PREIS' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value.amount | currency:value.currency }}">
                  {{ value.amount | currency:value.currency }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="bestandAgrarartikelTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>