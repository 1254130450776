import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { AbstractClientDialog } from '../../core/component/dialog/abstract-client-dialog';
import { SortDirection } from '../../core/filter/sort-direction';
import { Besamung } from '../../model/besamung';
import { Cattle } from '../../model/cattle';
import { ClientPaginationService } from '../../service/client-pagination-service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { BesamungComponent } from '../auftrag/warenkorb/warenauswahl/besamung/besamung.component';

@Component({
  selector: 'portal-cattle-detail-modal',
  templateUrl: './cattle-detail-modal.component.html',
  styleUrls: ['./cattle-detail-modal.component.scss']
})
export class CattleDetailModalComponent extends AbstractClientDialog<Cattle> implements OnInit {

  ColumnMode = ColumnMode;

  SortType = SortType;

  sortField = [
    {
      prop: 'inseminationDate',
      dir: SortDirection.DESC.frontendkuerzel
    }
  ];

  rows: Array<Besamung> = [];

  constructor(private readonly dialogRef: MatDialogRef<BesamungComponent>, @Inject(MAT_DIALOG_DATA) readonly dialogData,
              modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Liefert den MutterVater mit Herdbooknumber
   */
  getMVLabel(): string {
    if (this.dialogData.cattle && this.dialogData.cattle.vorfahren.length >= 2) {
      return this.dialogData.cattle.vorfahren[1].name + ' (' + this.dialogData.cattle.vorfahren[1].herdbookNumber + ')';
    }
  }

  getService(): ClientPaginationService<Cattle> {
    return undefined;
  }

  /**
   * Liefert den Vater mit Herdbooknumber.
   */
  getVaterLabel(): string {
    if (this.dialogData.cattle && this.dialogData.cattle.vorfahren.length >= 1) {
      return this.dialogData.cattle.vorfahren[0].name + ' (' + this.dialogData.cattle.vorfahren[0].herdbookNumber + ')';
    }
  }

  ngOnInit() {
    if (this.dialogData && this.dialogData.cattle && this.dialogData.cattle.besamungen) {
      this.rows = this.dialogData.cattle.besamungen;
    }
  }

  /**
   * Dialog schliesssen.
   */
  schliessen() {
    this.dialogRef.close();
  }
}
