import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'portal-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit {

  messageKey: string;

  message: string;

  headlineKey: string;

  public headline: string;

  constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData: any) {
  }

  ngOnInit() {
    this.headlineKey = this.dialogData.headlineKey;
    this.headline = this.dialogData.headline;
    this.messageKey = this.dialogData.messageKey;
    this.message = this.dialogData.message;
  }

}
