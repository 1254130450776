import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Role } from '../../guard/role';
import { TechnikerKey } from '../../model/techniker-key';

@Injectable({
  providedIn: 'root'
})
export class ModaKeycloakService {

  constructor(private readonly keycloakService: KeycloakService) {
  }

  /**
   * Gibt den bevorzugten Benutzernamen des eingeloggten Benutzers zurueck.
   */
  getPreferredUsername(): string {
    return this.keycloakService.getKeycloakInstance().idTokenParsed['preferred_username'];
  }

  /**
   * Gibt den Techniker-Key des eingeloggten Benutzers zurueck.
   */
  getTechnikerKey(): TechnikerKey {
    const idTokenParsed = this.keycloakService.getKeycloakInstance().idTokenParsed;
    return new TechnikerKey(idTokenParsed['techniker_number'], idTokenParsed['ai_station_number']);
  }

  /**
   * Gibt die Technikernummer des eingeloggten Benutzers zurueck.
   */
  getTechnikernummer(): number {
    return this.keycloakService.getKeycloakInstance().idTokenParsed['techniker_number'];
  }

  /**
   * Gibt an, ob der Benutzer die Role.MODA_ADMIN hat.
   */
  isAdmin(): boolean {
    return this.keycloakService.isUserInRole(Role.MODA_ADMIN);

  }

  /**
   * Gibt an, ob der Benutzer die Role.BAP_BERATER hat.
   */
  isBapBerater(): boolean {
    return this.keycloakService.isUserInRole(Role.BAP_BERATER);
  }

  /**
   * Gibt an, ob der Benutzer die Role.BESAMUNGSSTATION hat.
   */
  isBesamungsstation(): boolean {
    return this.keycloakService.isUserInRole(Role.BESAMUNGSSTATION);
  }

  /**
   * Gibt an, ob der Benutzer die Role.BESAMUNGSTECHNIKER hat.
   */
  isBesamungstechniker(): boolean {
    return this.keycloakService.isUserInRole(Role.BESAMUNGSTECHNIKER);
  }

  /**
   * Gibt an, ob der Benutzer die Role.MODAREALMADMIN hat.
   */
  isRealmadmin(): boolean {
    return this.keycloakService.isUserInRole(Role.MODAREALMADMIN);
  }
}
