import { AfterViewInit, Directive } from '@angular/core';
import { AbstractClientPaginationTable } from '../table/abstract-client-pagination-table';

@Directive()
export abstract class AbstractClientDialog<T> extends AbstractClientPaginationTable<T> implements AfterViewInit {

  ngAfterViewInit(): void {
    // Fix fuer den Tabellenbreite Bug
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
  }
}
