import { Component, Input, OnInit } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'portal-table-client-pagination-footer',
  templateUrl: './table-client-pagination-footer.component.html',
  styleUrls: ['./table-client-pagination-footer.component.scss']
})
export class TableClientPaginationFooterComponent implements OnInit {
  pageSizeList = [
    10,
    20,
    50,
    100
  ];

  @Input() selectedPageSize = 10;

  @Input() selected = [];

  @Input() rows = [];

  @Input() table: DatatableComponent;

  constructor() {
  }

  ngOnInit() {
  }

  onFooterPage($event) {
    this.table.onFooterPage($event);
  }

  /**
   * Wechselt die Ergebnisse pro Seite ueber eine Checkbox.
   *
   * @param size Size
   */
  setPageSize(size: number) {
    this.table.pageSize = size;
    this.table.limit = size;
  }
}
