<div class="[ panel cattleuebersicht ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{'TIERUEBERSICHT.TIER' | translate}}
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="[ panel-content ]">
      <div class="[ toolbar ]">
        <div class="[ toolbar-left ]">
          <portal-search #searchCattleField (search)="doOrSearch($event)"
            [fields]="getSearchFieldDefinitions()" [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
        </div>
      </div>
      <div class="[ content-table ]">
        <div class="[ row ]">
          <div class="[ col-12 ]  [ c-data-list ]" id="data-list">
            <ngx-datatable
                #tieruebersichtTabelle
              (activate)="onActivate($event)"
              (page)="setPage($event)"
              (select)="onSelect($event)"
              (sort)="onSort($event)"
              [columnMode]="'force'"
              [count]="currentPage.totalElements"
              [cssClasses]="customClasses"
              [externalPaging]="true"
              [externalSorting]="true"
              [footerHeight]="57"
              [headerHeight]="57"
              [limit]="currentPage.size"
              [loadingIndicator]="tableIsLoading"
              [messages]="tableMessages"
              [offset]="currentPage.number"
              [rowHeight]="49"
              [rowIdentity]="rowIdentity"
              [rows]="data"
              [selectAllRowsOnPage]="false"
              [selected]="selected"
              [selectionType]="'single'"
              [sorts]="sortField"
              class="[ c-data-list__table ]"
              style="height: 604px;">
              <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="150"
                [sortable]="true" name="{{'TIERUEBERSICHT.ALPHA_LOM' | translate}}" prop="alphaLom">
              </ngx-datatable-column>
              <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
                [width]="200" name="{{'TIERUEBERSICHT.NAME' | translate}}" prop="name">
              </ngx-datatable-column>
              <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
                [sortable]="true" name="{{'TIERUEBERSICHT.STALL_NUMMER' | translate}}" prop="stallNummer">
                <ng-template let-sort="sortFn" ngx-datatable-header-template>
                  <span (click)="sort()" class="pointer right-cell-header"
                    title="{{'TIERUEBERSICHT.STALL_NUMMER' | translate}}">
                    {{'TIERUEBERSICHT.STALL_NUMMER' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <div class="right-cell" title="{{ value }}">{{ value }}</div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="80"
                [sortable]="true" name="{{'TIERUEBERSICHT.RASSE' | translate}}" prop="rasse.shortLabel">
              </ngx-datatable-column>
              <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
                [sortable]="true" name="{{'TIERUEBERSICHT.STATUS' | translate}}" prop="status">
              </ngx-datatable-column>
              <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="130"
                [sortable]="true" name="{{'TIERUEBERSICHT.GEBURTSDATUM' | translate}}" prop="animalBirthdate">
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span title="{{value | datexpipe: 'L'}}">{{value | datexpipe: 'L'}}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
                [sortable]="false"
                [width]="300" name="{{'TIERUEBERSICHT.LETZTE_BESAMUNG' | translate}}">
                <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.letzteBesamung"
                  title="{{row.letzteBesamung.inseminationCount}}, {{row.letzteBesamung.inseminationDate | datexpipe: 'L'}}, {{row.letzteBesamung.rind?.name}} ({{row.letzteBesamung.rind?.herdbookNumber}})">
                  {{row.letzteBesamung.inseminationCount}},
                  {{row.letzteBesamung.inseminationDate | datexpipe: 'L'}},
                  {{row.letzteBesamung.rind?.name}} ({{row.letzteBesamung.rind?.herdbookNumber}})
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="140"
                [sortable]="false" name="{{'TIERUEBERSICHT.LETZTE_KALBUNG' | translate}}">
                <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.lastCalvingDate"
                  title="{{row.lastCalvingDate | datexpipe: 'L'}}, {{row.calvingCount}}">
                  {{row.lastCalvingDate | datexpipe: 'L'}}, {{row.calvingCount}}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                  <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                    [currentPage]="currentPage"
                    [selected]="selected" [table]="tieruebersichtTabelle"
                    class="[ table-footer ]"></portal-table-server-pagination-footer>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
      <div class="[ form-content-modal ]">
        <button (click)="abbrechen()" class="[ c-mat-button no-color-button ]" mat-button>
          <i aria-hidden="true"></i>
          {{'BUTTON.ABBRECHEN' | translate}}
        </button>
        <button [disabled]="this.selected.length === 0" [mat-dialog-close]="selectedElement"
          class="[ c-mat-button green-button right-button ]" mat-flat-button>
          <i aria-hidden="true" class="[ icon-baseline-done-24px ]" style="font-size: 24px"></i>
          {{'BUTTON.BESTAETIGEN' | translate}}
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
