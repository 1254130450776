<div class="[ c-breadcrumb ]">
  <div class="[ c-breadcrumb__text ]">
    <a routerLink="">{{ 'HEADER.TITLE' | translate }}</a>
  </div>
  <div *ngFor="let breadcrumb of breadcrumbs" class="[ c-breadcrumb__text ]">
    <i *ngIf="breadcrumb.label !== ''" aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]"></i>
    <a [routerLink]="[breadcrumb.url, breadcrumb.params]">{{ breadcrumb.label | translate }}</a>
  </div>
  <div class="[ c-breadcrumb__text margin-right-left-auto ]">
    <label *ngIf="applicationInfo">{{ 'HEADER.VERSION' | translate }} {{ applicationInfo?.versionnummer }}</label>
  </div>
</div>
