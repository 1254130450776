<div class="[ date ]">
  <div *ngIf="readonly; then readonlyBlock else editableBlock">
  </div>
</div>
<ng-template #editableBlock>
  <mat-form-field>
    <input #inputField (dateChange)="changeDate()" (keydown)="onKeyDownDate($event)" (keyup)="onKeyUpDate($event)"
      [formControl]="dateForm" [matDatepicker]="picker" [max]="maxDate" [min]="minDate"
      [placeholder]="datePlaceholder" matInput>
    <i (click)="clearDate(inputField)" *ngIf="inputField.value"
      class="search-clear">x
    </i>
    <mat-datepicker-toggle [for]="picker" matSuffix>
      <i class="[ icon-baseline-event-24px ]" matDatepickerToggleIcon></i>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</ng-template>
<ng-template #readonlyBlock>
  <mat-form-field>
    <input #inputField [formControl]="dateForm" [matDatepicker]="pickerReadonly"
      [placeholder]="datePlaceholder" [readonly]="true" matInput>
    <mat-datepicker-toggle disabled matSuffix>
      <i class="[ icon-baseline-event-24px ]" matDatepickerToggleIcon></i>
    </mat-datepicker-toggle>
    <mat-datepicker #pickerReadonly></mat-datepicker>
  </mat-form-field>
</ng-template>
