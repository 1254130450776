<div class="headline">
  <label>{{ 'NEW_CATTLE.TITLE' | translate }}</label>
</div>
<mat-dialog-content class="mat-typography">
  <div class="new-cattle-container">
    <div>
      <mat-form-field class="[ width-100 ]">
        <i class="[ icon-tag ]" matPrefix></i>
        <input [formControl]="lomForm" [validateRegex]="'^\\d+$'" matInput maxlength="15" minlength="10"
          placeholder="{{ 'NEW_CATTLE.LOM' | translate }}" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="[ width-100 ]">
        <i class="[ icon-cow-1 ]" matPrefix></i>
        <input [(ngModel)]="name" matInput placeholder="{{ 'NEW_CATTLE.NAME' | translate }}">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="[ width-100 ]">
        <i class="[ icon-barn ]" matPrefix></i>
        <input [(ngModel)]="stallnummer" [validateRegex]="'^\\d+$'" matInput
          placeholder="{{ 'NEW_CATTLE.STALLNUMMER' | translate }}">
      </mat-form-field>
    </div>
    <div class="[ form-content ]">
      <button (click)="abbrechen()" class="[ c-mat-button no-color-button ]" mat-button>
        <i aria-hidden="true"></i>
        {{'BUTTON.ABBRECHEN' | translate}}
      </button>
      <button (click)="hinzufuegen()" class="[ c-mat-button green-button right-button ]" mat-flat-button>
        <i aria-hidden="true" class="[ icon-plus ]" style="margin-right: 10px"></i>
        {{'BUTTON.HINZUFUEGEN' | translate}}
      </button>
    </div>
  </div>
</mat-dialog-content>
