import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractDialog } from '../../../../../core/component/dialog/abstract-dialog';
import { FilterCriteriaResource } from '../../../../../core/filter/filterCriteriaResource';
import { FilterType } from '../../../../../core/filter/filterType';
import { Page } from '../../../../../core/filter/page';
import { SortDirection } from '../../../../../core/filter/sort-direction';
import { AuftragspositionType } from '../../../../../enumeration/auftragsposition-type.enum';
import { Auftragspositionsstatus } from '../../../../../enumeration/auftragspositionsstatus.enum';
import { Agrarartikel } from '../../../../../model/agrarartikel';
import { Auftrag } from '../../../../../model/auftrag';
import { Auftragsposition } from '../../../../../model/auftragsposition';
import { CustomErrorHandlerService } from '../../../../../service/custom-error-handler/custom-error-handler.service';
import { DienstleistungService } from '../../../../../service/dienstleistung/dienstleistung.service';
import { ModaTranslateService } from '../../../../../service/moda-translate.service';
import { ServerPaginationService } from '../../../../../service/serverPaginationService';
import { Utils } from '../../../../../utils/utils';
import { SearchFieldDefinition } from '../../../../search/search-field-definition';
import { WarenauswahlComponent } from '../warenauswahl.component';

@Component({
  selector: 'portal-tu-dienstleistungen',
  templateUrl: './tu-dienstleistungen.component.html',
  styleUrls: ['./tu-dienstleistungen.component.scss']
})
export class TuDienstleistungenComponent extends AbstractDialog<Agrarartikel> implements OnInit {

  @ViewChild('searchArtikel', { static: true }) searchArtikel: any;

  sortField = [
    {
      prop: 'artikelnummer',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  @Input() auftrag: Auftrag;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              public dialogRef: MatDialogRef<WarenauswahlComponent>, @Inject(MAT_DIALOG_DATA) public dataDialog: any,
              private readonly dienstleistungService: DienstleistungService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Die Felder, die nicht aus der Db kommen, werden hier vorbereitet.
   * @param page Agrarartikel-Page
   */
  calculateData(page: Page<Agrarartikel>) {
    super.calculateData(page);
    for (const agrarartikel of page.content) {
      agrarartikel.anzahl = 0;
      this.auftrag.positionen.forEach(position => {
        if (agrarartikel.artikelnummer === position.itemNumber) {
          agrarartikel.anzahl = position.quantity;
          agrarartikel.auftragsstatus = position.auftragspositionsstatus;
          this.updateArarartikel(agrarartikel);
        }
      });
    }
  }

  /**
   * Senkt die Anzahl der markierten Zeile um 1.
   *
   * @param row Zeile
   */
  decrementAnzahl(row: Agrarartikel) {
    row.anzahl--;
    this.updateArarartikel(row);
    this.updateAuftrag(row);
  }

  /**
   * Ermittelt die Anzahl der Dienstleistungen.
   */
  determineAnzahlDienstleistungen() {
    return this.auftrag.positionen.filter(value => value.type === AuftragspositionType.DIENSTLEISTUNG).length;
  }

  /**
   * Selektiert die Zeile nach dem Laden, die editiert werden soll.
   * @param data, die editierte Auftragsposition
   */
  doAfterLoadData(data: Page<Agrarartikel>): void {
    super.doAfterLoadData(data);
    if (this.dataDialog && this.dataDialog.artikelnummer && data && data.content) {
      this.selected = this.data.filter(farm => farm.artikelnummer === this.dataDialog.artikelnummer);
    }
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('artikelnummer', FilterType.STRING),
      new SearchFieldDefinition('bezeichnung', FilterType.STRING)
    ];
  }

  public getService(): ServerPaginationService<Agrarartikel> {
    return this.dienstleistungService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return this.sortField;
  }

  /**
   * Fuegt der Session den Auftrag hinzu.
   */
  hinzufuegen() {
    this.dialogRef.close(this.auftrag);
  }

  /**
   * Erhoeht die Anzahl der markierten Zeile um 1.
   *
   * @param row Zeile
   */
  incrementAnzahl(row: Agrarartikel) {
    row.anzahl++;
    this.updateArarartikel(row);
    this.updateAuftrag(row);
  }

  ngOnInit() {
    this.initPagination();
    this.setDefaultSortCriteria();
    this.searchFilterPagingResource.andFilterCriteriaResourceList.push();
    if (this.dataDialog && this.dataDialog.artikelnummer) {
      this.searchArtikel.searchField.nativeElement.value = this.dataDialog.artikelnummer;
      this.searchFilterPagingResource.orFilterCriteriaResourceList.push(
        new FilterCriteriaResource('artikelnummer', this.dataDialog.artikelnummer, FilterType.STRING));
    }
    this.loadData(this.searchFilterPagingResource);
  }

  public rowIdentity(row: Agrarartikel) {
    return row.artikelnummer;
  }

  /**
   * Setzt die Anzahl der markierten Zeile auf den eingegebenen Wert.
   *
   * @param row Zeile
   */
  setAnzahl(row: Agrarartikel) {
    this.updateArarartikel(row);
    this.updateAuftrag(row);
  }

  /**
   * Befuellt die Anzahl-Direkt und die Anzahl-Bestellung der Auftragsposition.
   *
   * @param row Markierte Zeile
   * @param anzahl Anzahl
   * @param auftragsposition Auftragsposition
   */
  private fillAnzahl(row: Agrarartikel, anzahl: number, auftragsposition: Auftragsposition) {
    if (row.fetchedBestand - anzahl < 0) {
      auftragsposition.anzahlDirektverkauf = row.fetchedBestand;
      auftragsposition.anzahlBestellung = anzahl - row.fetchedBestand;
    } else {
      auftragsposition.anzahlDirektverkauf = anzahl;
      auftragsposition.anzahlBestellung = 0;
    }
  }

  /**
   * Aktualisiert den Agrarartikel.
   *
   * @param row Agrarartikel
   */
  private updateArarartikel(row: Agrarartikel) {
    if (!row.anzahl) {
      row.anzahl = 0;
    }
    if (row.anzahl < 0 || row.anzahl > 99) {
      console.error('Es duerfen nur Zahlen zwischen 0 und 99 eingegeben werden');
      return;
    }
    row.agrarartikelbestand.istBestand = row.fetchedBestand - row.anzahl;
    if (row.agrarartikelbestand.istBestand < 0) {
      row.agrarartikelbestand.istBestand = 0;
    }
  }

  /**
   * Aktualisiert den Auftrag.
   *
   * @param row ArtikelBestand
   */
  private updateAuftrag(row: Agrarartikel) {
    const anzahl: number = row.anzahl;
    const filteredAuftragspositionen = this.auftrag.positionen.filter(value => value.itemNumber === row.artikelnummer);
    if (filteredAuftragspositionen.length > 1) {
      console.error('Es wurde mehr als eine Auftragsposition gefunden');
      return;
    }
    if (filteredAuftragspositionen.length === 0) {
      if (anzahl !== Utils.NUMBER_0) {
        const newAuftragspostion = new Auftragsposition();
        newAuftragspostion.type = AuftragspositionType.DIENSTLEISTUNG;
        newAuftragspostion.itemNumber = row.artikelnummer;
        newAuftragspostion.comment = '';
        newAuftragspostion.bezeichnung = row.bezeichnung;
        newAuftragspostion.quantity = anzahl;
        newAuftragspostion.auftragsdatum = this.auftrag.auftragsdatum;
        newAuftragspostion.auftragspositionsstatus = Auftragspositionsstatus.IM_WARENKORB;
        if (this.auftrag.auftragsnummer) {
          newAuftragspostion.auftragsnummer = this.auftrag.auftragsnummer;
        }
        this.fillAnzahl(row, anzahl, newAuftragspostion);
        this.auftrag.positionen.push(newAuftragspostion);
      }
    } else {
      if (anzahl === Utils.NUMBER_0) {
        this.auftrag.positionen = this.auftrag.positionen.filter(value => value.itemNumber !== row.artikelnummer);
      } else {
        filteredAuftragspositionen[0].quantity = anzahl;
        this.fillAnzahl(row, anzahl, filteredAuftragspositionen[0]);
      }
    }
  }
}
