import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractDialog } from '../../core/component/dialog/abstract-dialog';
import { FilterType } from '../../core/filter/filterType';
import { Page } from '../../core/filter/page';
import { SortDirection } from '../../core/filter/sort-direction';
import { Spermaartikel } from '../../model/spermaartikel';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SpermaartikelService } from '../../service/spermaartikel/spermaartikel.service';
import { SearchFieldDefinition } from '../search/search-field-definition';
import { VerlustuebersichtComponent } from '../verlustuebersicht/verlustuebersicht.component';

@Component({
  selector: 'portal-spermaauswahl-modal',
  templateUrl: './spermaauswahl-modal.component.html',
  styleUrls: ['./spermaauswahl-modal.component.scss']
})
export class SpermaauswahlModalComponent extends AbstractDialog<Spermaartikel> implements OnInit {

  sortField = [
    {
      prop: 'herdbookNumber',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  @ViewChild('searchBulleField', { static: true }) searchBulleField: any;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly spermaartikelService: SpermaartikelService,
              private readonly dialogRef: MatDialogRef<VerlustuebersichtComponent>,
              @Inject(MAT_DIALOG_DATA) private readonly dialogData) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Bestaetigen Button.
   */
  bestaetigen() {
    this.dialogRef.close(this.selectedElement);
  }

  /**
   * Updatet die Tabelle.
   *
   * @param data PageData
   */
  doAfterLoadData(data: Page<Spermaartikel>) {
    this.onSelect(null);
    this.calculateData(data);
    this.updatePage(data);
    this.tableIsLoading = false;
    if (data.content.length === 1) {
      this.selectedElement = data.content[0];
      this.bestaetigen();
    }
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('name', FilterType.STRING),
      new SearchFieldDefinition('herdbookNumber', FilterType.STRING)
    ];
  }

  public getService(): ServerPaginationService<Spermaartikel> {
    return this.spermaartikelService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return this.sortField;
  }

  ngOnInit() {
    this.initPagination();
    if (this.dialogData.filterCriteriaResourceList) {
      this.searchBulleField.searchField.nativeElement.value = this.dialogData.filterCriteriaResourceList[0].value;
      this.searchFilterPagingResource.orFilterCriteriaResourceList = this.dialogData.filterCriteriaResourceList;
    }
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   *  {@inheritDoc}.
   */
  onDoubleClick(row: Spermaartikel) {
    this.dialogRef.close(row);
  }

  /**
   *  {@inheritDoc}.
   */
  public rowIdentity(row: Spermaartikel) {
    return row.id;
  }
}
