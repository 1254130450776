import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AbstractClientPaginationTable } from '../../core/component/table/abstract-client-pagination-table';
import { FilterType } from '../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { TechnikerDocument } from '../../model/technikerDocument';
import { ClientPaginationService } from '../../service/client-pagination-service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { DocumentFileService } from '../../service/document/document-file.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { NotificationService } from '../../service/notification/notification.service';
import { Utils } from '../../utils/utils';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-dokumentuebersicht',
  templateUrl: './documentuebersicht.component.html',
  styleUrls: ['./documentuebersicht.component.scss']
})
export class DocumentuebersichtComponent extends AbstractClientPaginationTable<TechnikerDocument> implements OnInit {
  sortField = [
    {
      prop: 'filename',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  @ViewChild('dokumentTabelle', { static: true }) tabelle: DatatableComponent;

  @ViewChild('selectAllBox') selectAllBox: ElementRef<HTMLInputElement>;

  allSelectedPages = {};

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly notificationService: NotificationService,
              private readonly documentService: DocumentFileService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * {@inheritDoc}
   */
  compareEntity(selected: TechnikerDocument, item: TechnikerDocument) {
    return selected.filename !== item.filename;
  }

  /**
   * {@inheritDoc}
   */
  doLoadData() {
    this.documentService.findAll(new SearchFilterPagingResource()).subscribe({
      next: (data: TechnikerDocument[]) => {
        this.data = data;
        this.filterdData = data;
      },
      error: (err) => this.customErrorHandlerService.handleError(err)
    });
  }

  /**
   * Die selektierte Zeilen werden heruntergelanden.
   */
  download() {
    const files = this.selected.map(o => o.path);
    if (files && files.length) {
      this.documentService.downloadAsZip(files).subscribe(response => {
        Utils.downloadFile(response);
      }, errorRes => {
        this.customErrorHandlerService.handleError(errorRes);
      });
    } else {
      this.notificationService.showErrorNotification('DOKUMENTE.MESSAGE.KEIN_DOKUMENT');
    }
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen.
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('filename', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  getService(): ClientPaginationService<TechnikerDocument> {
    return this.documentService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return [
      {
        prop: 'fieldname',
        dir: SortDirection.ASC.frontendkuerzel
      }
    ];
  }

  ngOnInit() {
    this.doLoadData();
  }

  /**
   * Wechseln von Seiten.
   * @param evt Pageevent
   */
  onPageChange(evt: any) {
    this.selectAllBox.nativeElement.checked = this.allSelectedPages[this.tabelle.offset] || false;
  }

  /**
   *  Selektiert eine Zeile.
   *
   * @param event Selektierte Zeile
   */
  onSelect(event) {
    this.selected = [];
    if (event == null || event.selected == null) {
      this.selectedElement = null;
    } else {
      event.selected.forEach(s => this.selected.push(s));
    }
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row TechnikerDocument
   */
  rowIdentity(row: TechnikerDocument) {
    return row && row.filename;
  }

  /**
   * Selektierte alle Zeilen.
   * @param evt SelectEvent
   */
  selectAll(evt: any) {
    const offset = this.tabelle.offset;
    const start = offset * this.tabelle._limit;
    const end = Math.min(start + this.tabelle._limit, this.tabelle._internalRows.length);
    this.allSelectedPages[offset] = evt.currentTarget.checked;
    if (evt.currentTarget.checked) {
      for (let i = offset * this.tabelle._limit; i < end; i++) {
        this.selected.push(this.tabelle._internalRows[i]);
      }
    } else {
      const pageItems = {};
      for (let i = offset * this.tabelle._limit; i < end; i++) {
        pageItems[this.tabelle._internalRows[i].filename] = true;
      }
      this.selected = this.selected.filter((i: TechnikerDocument) => !pageItems[i.filename]);
    }
    this.tabelle.ngAfterViewInit();
  }
}
