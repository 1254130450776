import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AbstractClientDialog } from '../../../core/component/dialog/abstract-client-dialog';
import { SortDirection } from '../../../core/filter/sort-direction';
import { Auftragsposition } from '../../../model/auftragsposition';
import { AuftragspositionService } from '../../../service/auftrag/auftragsposition.service';
import { BesamungsdienstleistungenService } from '../../../service/besamungsdienstleistungen/besamungsdienstleistungen.service';
import { ClientPaginationService } from '../../../service/client-pagination-service';
import { CustomErrorHandlerService } from '../../../service/custom-error-handler/custom-error-handler.service';
import { FarmService } from '../../../service/farm/farm.service';
import { ModaTranslateService } from '../../../service/moda-translate.service';
import { AuftragspositionenUtils } from '../../../utils/auftragspositionen-utils';
import { AuftragsuebersichtComponent } from '../auftragsuebersicht.component';

@Component({
  selector: 'portal-cattle-detail-modal',
  templateUrl: './auftrag-detail-modal.component.html',
  styleUrls: ['./auftrag-detail-modal.component.scss']
})
export class AuftragDetailModalComponent extends AbstractClientDialog<Auftragsposition> implements OnInit {

  sortField = [
    {
      prop: 'type',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  betriebsbezeichnung: string;

  farmSubscription: Subscription;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly dialogRef: MatDialogRef<AuftragsuebersichtComponent>,
              private readonly auftragService: AuftragspositionService, @Inject(MAT_DIALOG_DATA) readonly dialogData,
              private readonly besamungsdienstleistungenService: BesamungsdienstleistungenService,
              private readonly farmService: FarmService) {
    super(modaTranslateService, customErrorHandlerService);
  }

  getService(): ClientPaginationService<Auftragsposition> {
    return undefined;
  }

  getSortFields(): any {
    return this.sortField;
  }

  ngOnInit() {
    const firstAdresse = this.dialogData.auftragspositionen[0].adresse;
    // Beispielstring der zusammengebaut wird: 887 - Lueken, Peter - Leerer Landstr. 140, Aurich
    const name = (firstAdresse.nachname
      ? firstAdresse.nachname
      : '') + (firstAdresse.vorname
      ? ', ' + firstAdresse.vorname
      : '');
    const adresse = firstAdresse.strasseHausnummer + ', ' + firstAdresse.ort;
    this.betriebsbezeichnung = this.dialogData.auftragspositionen[0].shortNumber
      ? this.dialogData.auftragspositionen[0].shortNumber + ' - ' + name + ' - ' + adresse
      : name + ' - ' + adresse;
    this.dialogData.auftragspositionen =
      AuftragspositionenUtils.calculateDirektUndBestellung(this.dialogData.auftragspositionen);
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row Auftragsposition
   */
  rowIdentity(row: Auftragsposition) {
    return row.receiptNumber;
  }

  /**
   * Schliesst den Dialog.
   */
  schliessen() {
    this.dialogRef.close();
  }
}
