import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DatatableComponent, DataTablePagerComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'portal-table-server-pagination-footer',
  templateUrl: './table-server-pagination-footer.component.html',
  styleUrls: ['./table-server-pagination-footer.component.scss']
})
export class TableServerPaginationFooterComponent {

  pageSizeList = [
    10,
    20,
    50,
    100
  ];

  @Input() selectedPageSize = 10;

  @Input() selected = [];

  @Input() table: DatatableComponent;

  @Input() currentPage;

  @Input() multiSelect = false;

  @Input() selectedLength = 0;

  @Input() placeholder = 'TABLE.PAGING.AUSGEWAEHLT';

  @Output() changeEvent = new EventEmitter<number>();

  @ViewChild(DataTablePagerComponent) dataPager: DataTablePagerComponent;

  constructor() {
  }

  /**
   * Liefert das DataTablePagerComponent zurueck.
   *
   * @return DataTablePagerComponent.
   */
  getDataPager(): DataTablePagerComponent {
    return this.dataPager;
  }

  /**
   * The footer triggered a page event.
   * @param $event Event
   */
  onFooterPage($event) {
    this.table.onFooterPage($event);
  }

  /**
   * Wechselt die Seite (Page).
   * @param page Page
   */
  setPage(page: number) {
    this.dataPager.selectPage(page);
  }

  /**
   * Wechselt die Ergebnisse pro Seite ueber eine Checkbox.
   *
   * @param size Size
   */
  setPageSize(size: number) {
    this.table.limit = size;
    this.changeEvent.emit(size);
  }
}
