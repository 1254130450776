import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Spermatausch } from '../../model/spermatausch';
import { Verlustgrund } from '../../model/Verlustgrund';
import { ModaKeycloakService } from '../moda-keycloak/moda-keycloak.service';
import { ServerPaginationService } from '../serverPaginationService';

@Injectable({
  providedIn: 'root'
})
export class SpermatauschService implements ServerPaginationService<Spermatausch> {

  private url = '/api/v1/spermatausch';

  constructor(private http: HttpClient, private readonly modaKeycloakService: ModaKeycloakService) {
  }

  /**
   * Erstellt einen neuen Spermatausch.
   * @param spermatausch Spermatausch
   *
   * @return Observable<Spermatausch>
   */
  create(spermatausch: Spermatausch): Observable<Spermatausch> {
    spermatausch.technikerKey = this.modaKeycloakService.getTechnikerKey();
    return this.http.post<Spermatausch>(this.url, spermatausch);
  }

  /**
   * Loescht einen Spermatausch.
   *
   * @param spermatausch Spermatausch
   */
  delete(spermatausch: Spermatausch): Observable<void> {
    return this.http.delete<void>(this.url + '/' + spermatausch.id);
  }

  /**
   *  Liefert alle Verlustgruende.
   */
  findAllVerlustgruende(): Observable<Array<Verlustgrund>> {
    return this.http.get<Array<Verlustgrund>>(this.url + '/verlustgruende');
  }

  /**
   * Liefert alle noch veraenderbaren Spermatausche als Liste zurueck.
   */
  loadChangeableData(): Observable<Array<Spermatausch>> {
    return this.http.get<Array<Spermatausch>>(this.url + '/listChangable');
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource alle fuer den angemeldeten Benutzer sichtbaren
   * Spermatausche.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Spermatausch>> {
    return this.http.post<Page<Spermatausch>>(this.url + '/listPage', searchFilterPagingResource);
  }

  /**
   * Aktualisiert einen Spermatausch.
   *
   * @param spermatausch Spermatausch
   */
  update(spermatausch: Spermatausch): Observable<Spermatausch> {
    return this.http.put<Spermatausch>(this.url, spermatausch);
  }
}
