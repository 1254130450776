import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractClientDialog } from '../../../core/component/dialog/abstract-client-dialog';
import { SortDirection } from '../../../core/filter/sort-direction';
import { EnumKeysPipe } from '../../../core/pipe/enum-keys.pipe';
import { Suchfokus } from '../../../enumeration/suchfokus.enum';
import { Farm } from '../../../model/farm';
import { CattleService } from '../../../service/cattle/cattle.service';
import { ClientPaginationService } from '../../../service/client-pagination-service';
import { CustomErrorHandlerService } from '../../../service/custom-error-handler/custom-error-handler.service';
import { FarmService } from '../../../service/farm/farm.service';
import { InseminatorService } from '../../../service/inseminator/inseminator.service';
import { ModaKeycloakService } from '../../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../../service/moda-translate.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { FarmuebersichtComponent } from '../farmuebersicht.component';

@Component({
  selector: 'portal-farm-detail-modal',
  templateUrl: './farm-detail-modal.component.html',
  styleUrls: ['./farm-detail-modal.component.scss']
})
export class FarmDetailModalComponent extends AbstractClientDialog<Farm> implements OnInit {

  sortField = [
    {
      prop: 'type',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  suchfokusValues = Suchfokus;

  selectedSuchfokus: Suchfokus;

  anzahlTiere: number;

  farm: Farm;

  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService,
              private readonly dialogRef: MatDialogRef<FarmuebersichtComponent>,
              private readonly modaKeycloakService: ModaKeycloakService,
              private readonly inseminatorService: InseminatorService, private readonly farmService: FarmService,
              private readonly cattleService: CattleService, private readonly notificationService: NotificationService,
              private readonly enumKeysPipe: EnumKeysPipe, @Inject(MAT_DIALOG_DATA) readonly dialogData) {
    super(modaTranslateService, customErrorHandlerService);
  }

  getService(): ClientPaginationService<Farm> {
    return undefined;
  }

  getSortFields(): any {
    return this.sortField;
  }

  ngOnInit() {
    this.farmService.loadByBetriebsschluessel(this.dialogData.betriebsschluessel).subscribe(farm => {
      this.farm = farm;
      const currentTechniker = this.modaKeycloakService.getTechnikerKey();
      const techniker = farm.technikerDetails.filter(
        (td) => td.technikerKey.aiStationNumber === currentTechniker.aiStationNumber && td.technikerKey.number
          === currentTechniker.number);
      if (techniker && techniker.length) {
        this.selectedSuchfokus = techniker[0].suchfokus;
      }
    }, error => this.customErrorHandlerService.handleError(error));
    this.cattleService.findCattleByBetriebsschluessel(this.dialogData.betriebsschluessel)
      .subscribe(data => {
        this.anzahlTiere = data.length;
      }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row Farm
   */
  rowIdentity(row: Farm) {
    return row.id;
  }

  /**
   * Schliesst den Dialog.
   */
  schliessen() {
    this.dialogRef.close();
  }

  /**
   * Speichert den Betrieb.
   */
  speichern() {
    this.farmService.changeSuchfokus(this.farm.betriebsschluessel, this.selectedSuchfokus).subscribe(value => {
      this.notificationService.showSuccessNotification('FARM_DETAIL.AKTUALISIEREN.SUCCESS');
      this.dialogRef.close(this.selectedSuchfokus);
    }, error => {
      this.notificationService.showErrorNotification('FARM_DETAIL.AKTUALISIEREN.ERROR');
      this.customErrorHandlerService.handleError(error);
    });
  }
}
