<mat-dialog-content class="mat-typography">
  <form *ngIf="besamungForm" [formGroup]="besamungForm" novalidate>
    <div class="margin-vorgang"></div>
    <div class="row">
      <div class="half-row">
        <div class="headline">
          <label>{{ 'WARENAUSWAHL.BESAMUNG_TAB.TIER' | translate }}</label>
          <button (click)="neuesTier()" class="[ c-mat-button no-color-button margin-right-left-auto header-button ]"
            mat-button style="margin-right: 10px">
            <i aria-hidden="true" class="[ icon-plus ]" style="margin-right: 5px"></i>
            {{'BUTTON.NEUES_TIER' | translate}}
          </button>
          <button (click)="sucheKuh()" class="[ c-mat-button no-color-button header-button ]"
            mat-button>{{'BUTTON.ALLE_ANZEIGEN' | translate}}
            <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]"></i>
          </button>
        </div>
        <div class="margin-vorgang">
          <portal-search #searchKuhInput (search)="searchKuh($event)" [automaticSearch]="false"
            [fields]="getSearchFieldDefinitionsKuh()"
            [placeholder]="('WARENAUSWAHL.BESAMUNG_TAB.' + (auftrag.suchfokus == 'ENUM.SUCHFOKUS.LOM' ? 'TIER_LOM_SEARCH' :
            (auftrag.suchfokus == 'ENUM.SUCHFOKUS.STALLNUMMER' ? 'TIER_STLNR_SEARCH' : 'TIER_SEARCH')) | translate)"
            id="searchKuhId"></portal-search>
          <button (click)="openFarmDetailDialog()" class="[ c-mat-button  header-button  margin-right-left-auto ]"
            mat-button>{{ 'BUTTON.SUCHFOKUS' | translate }}
          </button>
        </div>
        <div *ngIf="!selectedCattle"
          class="boxed-centered-text">{{ 'WARENAUSWAHL.BESAMUNG_TAB.KEIN_TIER_AUSGEWAEHLT' | translate }}</div>
        <div *ngIf="selectedCattle" class="boxed-cattle-infos">
          <i (click)="openCattleInfoDialog()" class="[ icon-info-circle cattle-info-btn ]"></i>
          <mat-grid-list cols="3" rowHeight="51px">
            <mat-grid-tile colspan="1" rowspan="1">
              <i class="icon-barn"></i>
              <span>{{ 'WARENAUSWAHL.BESAMUNG_TAB.STALLNUMMER' | translate }}</span>
              <output *ngIf="selectedCattle.stallNummer">{{ selectedCattle.stallNummer }}</output>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="1">
              <i class="icon-tag"></i>
              <span>{{ 'WARENAUSWAHL.BESAMUNG_TAB.LOM' | translate }}</span>
              <output *ngIf="selectedCattle?.alphaLom">{{ selectedCattle.alphaLom}}</output>
              <output *ngIf="!selectedCattle?.alphaLom">{{ selectedCattle.cattleKey.lom}}</output>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <i class="icon-cow"></i>
              <span>{{ 'WARENAUSWAHL.BESAMUNG_TAB.NAME' | translate }}</span>
              <output *ngIf="selectedCattle.name">{{ selectedCattle.name }}</output>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="1">
              <i class="icon-ic_eb-lb"></i>
              <span>{{ 'WARENAUSWAHL.BESAMUNG_TAB.EB' | translate }}</span>
              <output *ngIf="selectedCattle.ersteBesamung"
                title="{{selectedCattle.ersteBesamung.inseminationCount}}, {{selectedCattle.ersteBesamung.inseminationDate | datexpipe: 'L'}}, {{selectedCattle.ersteBesamung.rind?.name}} ({{selectedCattle.ersteBesamung.rind?.herdbookNumber}})">
                {{selectedCattle.ersteBesamung.inseminationCount}},
                {{selectedCattle.ersteBesamung.inseminationDate | datexpipe: 'L'}},
                {{selectedCattle.ersteBesamung.rind?.name}}
                ({{selectedCattle.ersteBesamung.rind?.herdbookNumber}})
              </output>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <i class="icon-gender-male"></i>
              <span>{{ 'WARENAUSWAHL.BESAMUNG_TAB.VATER' | translate }}</span>
              <output *ngIf="selectedCattle.vorfahren?.length > 0">{{ selectedCattle.vorfahren[0].name }}</output>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="1">
              <i class="icon-ic_eb-lb"></i>
              <span>{{ 'WARENAUSWAHL.BESAMUNG_TAB.LB' | translate }}</span>
              <output *ngIf="selectedCattle.letzteBesamung"
                title="{{selectedCattle.letzteBesamung.inseminationCount}}, {{selectedCattle.letzteBesamung.inseminationDate | datexpipe: 'L'}}, {{selectedCattle.letzteBesamung.rind?.name}} ({{selectedCattle.letzteBesamung.rind?.herdbookNumber}})">
                {{selectedCattle.letzteBesamung.inseminationCount}},
                {{selectedCattle.letzteBesamung.inseminationDate | datexpipe: 'L'}},
                {{selectedCattle.letzteBesamung.rind?.name}}
                ({{selectedCattle.letzteBesamung.rind?.herdbookNumber}})
              </output>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
      <div class="half-row">
        <div class="headline">
          <label>{{ 'WARENAUSWAHL.BESAMUNG_TAB.BULLE_TITLE' | translate }}</label>
          <button (click)="letzterBulle()" class="[ c-mat-button no-color-button margin-right-left-auto header-button ]"
            mat-button>
            {{'BUTTON.LETZTER' | translate}}
          </button>
          <button (click)="sucheBulle()" class="[ c-mat-button no-color-button header-button ]" mat-button>
            {{'BUTTON.ALLE_ANZEIGEN' | translate}}
            <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]"></i>
          </button>
        </div>
        <div *ngIf="!(selectedCattle && selectedCattle.anpaarungen?.length > 0)" class="centered-text">
          {{ 'WARENAUSWAHL.BESAMUNG_TAB.KEIN_BULLE_AUSGEWAEHLT' | translate }}</div>
        <div *ngIf="selectedCattle && selectedCattle.anpaarungen?.length > 0" class="bulle-button-container">
          <mat-grid-list cols="3" rowHeight="48px">
            <mat-grid-tile colspan="1" rowspan="1">
              <button (click)="selectAnpaarungsvorschlag(0)" *ngIf="selectedCattle.anpaarungen.length >= 1"
                [ngClass]="{'bulle-button-active': markAnpaarungsvorschlag(0)}" class="[ c-mat-button bulle-button ]"
                id="anpaarungsvorschlag0" mat-button>
                <i aria-hidden="true"></i>
                {{ selectedCattle.anpaarungen[0].name | uppercase }}
              </button>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <button (click)="selectAnpaarungsvorschlag(1)" *ngIf="selectedCattle.anpaarungen.length >= 2"
                [ngClass]="{'bulle-button-active': markAnpaarungsvorschlag(1)}"
                class="[ c-mat-button bulle-button ]" id="anpaarungsvorschlag1" mat-button>
                <i aria-hidden="true"></i>
                {{ selectedCattle.anpaarungen[1].name | uppercase }}
              </button>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <button (click)="selectAnpaarungsvorschlag(2)" *ngIf="selectedCattle.anpaarungen.length >= 3"
                [ngClass]="{'bulle-button-active': markAnpaarungsvorschlag(2)}"
                class="[ c-mat-button bulle-button ]" id="anpaarungsvorschlag2" mat-button>
                <i aria-hidden="true"></i>
                {{ selectedCattle.anpaarungen[2].name | uppercase }}
              </button>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <div class="margin-vorgang">
          <portal-search #searchBulleInput (search)="searchBulle($event)" [automaticSearch]="false"
            [fields]="getSearchFieldDefinitionsBulle()"
            [placeholder]="('WARENAUSWAHL.BESAMUNG_TAB.BULLE_SEARCH' | translate)"></portal-search>
        </div>
        <div class="bulle-container">
          <mat-form-field class="bulle-name-field">
            <i class="[ icon-cow-1 ]" matPrefix></i>
            <input formControlName="selectedBulleLabel" matInput
              placeholder="{{ 'WARENAUSWAHL.BESAMUNG_TAB.BULLE' | translate }}"
              readonly>
          </mat-form-field>
          <i class="dummy"></i>
          <mat-form-field class="charge-selection">
            <input #charge [matAutocomplete]="chargen" [validateRegex]="'^\\d+$'" formControlName="charge" id="charge"
              matInput placeholder="{{'WARENAUSWAHL.BESAMUNG_TAB.CHARGE' | translate}}">
            <mat-autocomplete #chargen="matAutocomplete">
              <mat-option *ngFor="let charge of selectableChargen" [value]="charge.spermabestandTechnikerKey.lotNumber">
                {{ charge.spermabestandTechnikerKey.lotNumber }}
              </mat-option>
            </mat-autocomplete>
            <i class="[ icon-baseline-arrow_drop_down-24px ]"
              matSuffix></i>
          </mat-form-field>
        </div>
        <div class="bulle-container">
          <div class="checkbox">
            <mat-checkbox formControlName="berechneSpermapreis">
              {{'WARENAUSWAHL.BESAMUNG_TAB.SPERMAPREIS_BERECHNEN' | translate}}</mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="half-row">
        <div class="dienstleistungentungen-container">
          <div class="headline">
            <label>{{ 'WARENAUSWAHL.BESAMUNG_TAB.BESAMUNGSDATEN' | translate }}</label>
          </div>
          <div class="besamungsdaten-container">
            <mat-form-field class="wvb-field" style="width: 130px">
              <i class="[ icon-cow-1 ]" matPrefix></i>
              <input [validateRegex]="'^([1-9]|[1][0-9])$'" formControlName="wvb"
                matInput
                placeholder="{{ 'WARENAUSWAHL.BESAMUNG_TAB.WVB' | translate }}">
            </mat-form-field>
            <i class="dummy"></i>
            <portal-date #dateComponent (dateChange)="selectedDate=$event"
              (dateClear)="selectedDate.date=null"
              [datePlaceholder]="('WARENAUSWAHL.BESAMUNG_TAB.DATUM' | translate)"
              [dateValue]="selectedDate && selectedDate.date"
              [minDate]="minDate"
              [required]="true"
              class="[ datum-field ]" style="width: 180px"></portal-date>
            <i class="dummy"></i>
            <mat-form-field class="kommentar-field" style="width: 180px">
              <i class="[ icon-comment ]" matPrefix></i>
              <mat-label>{{ 'WARENAUSWAHL.BESAMUNG_TAB.KOMMENTAR' | translate }}</mat-label>
              <mat-select formControlName="besamungskommentar">
                <mat-option></mat-option>
                <mat-option *ngFor="let besamungskommentar of besamungskommentare" [value]="besamungskommentar">
                  {{ besamungskommentar.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="checkbox-container">
            <mat-grid-list cols="2" rowHeight="35px">
              <mat-grid-tile *ngFor="let bd of besamungsdienstleistungenService.getVitDienstleistungen() | async"
                colspan="1"
                rowspan="1">
                <div class="mat-grid-tile-content uncentered">
                  <mat-checkbox formControlName="{{bd.vitArtikelnummer}}">{{bd.bezeichnung}}</mat-checkbox>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
        </div>
      </div>
      <div class="half-row-gray">
        <div class="centered-text-besamung">
          <label> {{ 'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.ERFASSTE_BESAMUNGEN' | translate }}</label>
          <label *ngIf="determineAnzahlBesamungen() > 0"> &nbsp;({{determineAnzahlBesamungen()}})</label>
        </div>
        <div class="[ panel-content ]">
          <div class="[ content-table ]">
            <div class="[ c-data-list ]">
              <ngx-datatable
                  #erfassteBesamungenTable
                (select)="onRowSelected($event)"
                [columnMode]="'force'"
                [footerHeight]="0"
                [headerHeight]="30"
                [rowHeight]="25"
                [rowIdentity]="rowIdentity"
                [rows]="determineAuftragspositionForBesamung(auftrag)"
                [selectAllRowsOnPage]="false"
                [selected]="selectedRow"
                [selectionType]="'single'"
                class="[  c-data-list__table  ]"
                style="height: 252px">
                <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
                  [maxWidth]="70" [minWidth]="70" [sortable]="false" [width]="70"
                  name="{{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.STALLNUMMER' | translate}}"
                  prop="stallNummer">
                  <ng-template ngx-datatable-header-template>
                    <span class="right-cell-header"
                      title="{{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.STALLNUMMER' | translate}}">
                      {{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.STALLNUMMER' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="right-cell" title="{{ row.cattle?.stallNummer}}">{{ row.cattle?.stallNummer}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
                  [maxWidth]="100" [minWidth]="100" [sortable]="false" [width]="100"
                  name="{{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.LOM' | translate}}"
                  prop="lom">
                  <ng-template ngx-datatable-header-template>
                    <span class="right-cell-header"
                      title="{{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.LOM' | translate}}">
                      {{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.LOM' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="right-cell" title="{{getLomKnz(row)}}">{{ getLomKnz(row) }}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
                  [maxWidth]="40" [minWidth]="40" [sortable]="false" [width]="40">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span
                      title="{{'WARENAUSWAHL.BESAMUNG_TAB.BESAMUNGSCHECKBOXEN.ANFAHRT'|translate}}">
                      {{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.ANFAHRT' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <i
                      *ngIf="row.specialCharge?.includes(besamungsdienstleistungenService.getVitArtikelnummerAnfahrt())"
                      class="[ icon-baseline-done-24px ]"></i>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
                  [maxWidth]="40" [minWidth]="40" [sortable]="false" [width]="40">
                  <ng-template let-column="column" ngx-datatable-header-template>
                    <span
                      title="{{'WARENAUSWAHL.BESAMUNG_TAB.BESAMUNGSCHECKBOXEN.DURCHFUEHRUNG'|translate}}">
                      {{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.DURCHFUEHRUNG' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <i
                      *ngIf="row.specialCharge?.includes(besamungsdienstleistungenService.getVitArtikelnummerDurchfuehrung())"
                      class="[ icon-baseline-done-24px ]"></i>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
                  [maxWidth]="80" [minWidth]="80" [sortable]="false" [width]="80"
                  name="{{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.BEHANDLUNGSART' | translate}}" prop="label">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{row.bulle?.label|spermaart}}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
                  [maxWidth]="175" [minWidth]="175" [sortable]="false" [width]="175"
                  name="{{'WARENAUSWAHL.BESAMUNG_TAB.ERFASSTE_BESAMUNGEN.BULLE' | translate}}" prop="name">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                  <span
                    title="{{ row.wvb | bulleLabel: row.bulle?.name: row.bulle?.herdbookNumber }}">{{ row.wvb | bulleLabel: row.bulle?.name: row.bulle?.herdbookNumber }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
                  [maxWidth]="80" [minWidth]="70" [sortable]="false" [width]="70"
                  name="" prop="actions">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="action-toolbar">
                      <i (click)="editAuftragsposition(row)" class="[ icon-baseline-create-24px action-icon ]"></i>
                      <i (click)="delete(row)" class="[ icon-baseline-delete-24px action-icon ]"></i>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="[ form-content ]">
      <div class="box-left">
        <button (click)="abbrechen()" class="[ c-mat-button no-color-button ]" mat-button>
          <i aria-hidden="true"></i>
          {{'BUTTON.ABBRECHEN' | translate}}
        </button>
      </div>
      <div class="box-center">
        <button (click)="felderLeeren()" class="[ c-mat-button no-color-button ]" mat-button>
          <i aria-hidden="true" class="[ icon-undo ]" style="margin-right: 10px"></i>
          {{'BUTTON.FELDER_LEEREN' | translate}}
        </button>
      </div>
      <div class="box-right">
        <button (click)="saveAuftragsposition()" [disabled]="!isRequiredFormDataAvailable()"
          class="[ c-mat-button dark-green-button ]" mat-flat-button>
          <i aria-hidden="true" class="[  icon-ic_save_plus ]" style="margin-right: 10px"></i>
          {{ (editingAuftragsposition ? 'BUTTON.UEBERNEHMEN' : 'BUTTON.HINZUFUEGEN') | translate}}
        </button>
        <button (click)="onFertig()"
          class="[ c-mat-button dark-green-button margin-left-25 ]" mat-flat-button>
          <i aria-hidden="true" class="[ icon-ic_save_done ]" style="margin-right: 10px"></i>
          {{'BUTTON.FERTIG' | translate}}
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
