import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../core/component/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '../core/component/information-dialog/information-dialog.component';

export class DialogUtils {

  /**
   * Oeffnet einen Confirmation-Dialog.
   *
   * @param dialog MatDialog
   * @param data Dialog-Data
   * @return MatDialogRef<ConfirmationDialogComponent, any>
   */
  static openConfirmationDialog(dialog: MatDialog, data: any): MatDialogRef<ConfirmationDialogComponent, any> {
    return dialog.open(ConfirmationDialogComponent, {
      minWidth: '400px',
      panelClass: 'vost-modal-dialog',
      data
    });
  }

  /**
   * Oeffnet einen Information-Dialog.
   *
   * @param dialog MatDialog
   * @param data Dialog-Data
   * @return MatDialogRef<InformationDialogComponent, any>
   */
  static openInformationDialog(dialog: MatDialog, data: any): MatDialogRef<InformationDialogComponent, any> {
    return dialog.open(InformationDialogComponent, {
      minWidth: '400px',
      panelClass: 'vost-modal-dialog',
      data
    });
  }
}
