import { Subscription } from 'rxjs';
import { ClientPaginationService } from '../../../service/client-pagination-service';
import { FilterUtils } from '../../../utils/filter-utils';
import { Utils } from '../../../utils/utils';
import { FilterCriteria } from '../../filter/filterCriteria';
import { FilterCriteriaResource } from '../../filter/filterCriteriaResource';
import { FilterType } from '../../filter/filterType';
import { AbstractTable } from './abstract-table';
import { Directive } from "@angular/core";

@Directive()
export abstract class AbstractClientPaginationTable<T> extends AbstractTable {
  limt = 10;

  tableIsLoading = true;

  data: Array<T> = [];

  filterdData: Array<T> = [];

  selected: Array<T> = [];

  selectedElement: T;

  loadDataSubscription: Subscription;

  /**
   * Updatet die Tabelle.
   *
   * @param data PageData
   */
  doAfterLoadData(data: Array<T>) {
    this.selected = [];
    this.data = [...data];
    this.filterdData = this.data;
    this.tableIsLoading = false;
  }

  /**
   * Fuehrt die Aktionwn nach der Selektion aus.
   *
   * @param selectedRow Selected Row
   */
  doAfterSelect(selectedRow: T) {
  }

  /**
   *  Laedt die Daten.
   */
  doLoadData() {
    this.loadDataSubscription = this.getService().loadData().subscribe(data => {
      this.doAfterLoadData(data);
    }, error => this.customErrorHandlerService.handleError(error));
  }

  /**
   * Or-Search mit dem eingegebenen Filter.
   * @param filterCriteria Filtercriteria
   */
  doOrSearch(filterCriteria: FilterCriteria) {
    this.filterdData = this.data;
    if (filterCriteria) {
      const filterCriterias: FilterCriteriaResource[] = filterCriteria.getFilterCriteria();
      if (filterCriterias.length > 0) {
        this.filterdData = [];
        this.data.forEach(this.filterData(filterCriterias));
      }
    }
  }

  /**
   * Selektiert eine Zeile.
   *
   * @param selectedElement Selektierte Zeile
   */
  doSelect(selectedElement: T) {
    this.selectedElement = selectedElement;
    this.selected = [];
    this.selected.push(selectedElement);
  }

  /**
   * Unsubscribe.
   */
  doUnsubscribe() {
    Utils.unsubscribeSubscription(this.loadDataSubscription);
    this.unsubscribeMessageSubscription();
  }

  /**
   * Liefert den Service, der die Daten ermittelt.
   */
  abstract getService(): ClientPaginationService<T>;

  /**
   * Laedt die Daten.
   *
   */
  loadData() {
    this.tableIsLoading = true;
    this.doLoadData();
  }

  /**
   * Aktiviert bzw. Selektiert die Zeile.
   *
   * @param event Event
   */
  onActivate(event) {
    if (event.type === 'dblclick') {
      this.onSelect({ selected: [event.row] });
      this.onDoubleClick(event.row);
    }
  }

  /**
   * Aktiviert bzw. Selektiert die Zeile bei Doppelklick.
   * Diese Methode ueberschreiben und (activate)="onActivate($event)" zu ngx-datatable hinzufuegen.
   *
   * @param row Selektierte Zeile
   */
  onDoubleClick(row: T) {
    console.error('Doppelklick is not implemented!');
  }

  /**
   * Die selektierte Tabellenzeile wird zum Detail-Objekt.
   *
   * @param event Tabellenzeile
   */
  onSelect(event) {
    if (event == null) {
      this.selected = [];
      this.selectedElement = null;
    } else {
      const element = this.selected[0];
      this.doSelect(element);
      this.doAfterSelect(element);
    }
  }

  /**
   * Unsubscribe.
   */
  unsubscribe() {
    this.doUnsubscribe();
  }

  /**
   * Filtert die Daten.
   * @param filterCriterias FilterCriteriaResources
   */
  private filterData(filterCriterias: FilterCriteriaResource[]) {
    return model => {
      for (const criteria of filterCriterias) {
        const filterFunc = this.filterModel(model);
        if (filterFunc(criteria)) {
          this.filterdData.push(model);
          break;
        }
      }
    };
  }

  /**
   * Prueft, ob das Model dem Filter entspricht.
   * @param model Model
   */
  private filterModel(model) {
    return criteria => {
      const modelValue = model[criteria.key];
      const criteriaValue = criteria.value;
      switch (criteria.filterType) {
        case FilterType.STRING:
        case FilterType.NUMBER:
        case FilterType.DATE:
        case FilterType.INTERVAL:
        case FilterType.SEPARATED_STRING:
          if (FilterUtils.match(modelValue, criteriaValue)) {
            return true;
          }
          break;
        case FilterType.ENUM:
          const traslatedModelValue = this.modaTranslateService.translate(modelValue);
          if (FilterUtils.match(criteriaValue, traslatedModelValue)) {
            return true;
          }
          break;
      }
      return false;
    };
  }
}
