import { Component, Input } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'portal-table-footer-without-paging',
  templateUrl: './table-footer-without-paging.component.html',
  styleUrls: ['./table-footer-without-paging.component.scss']
})
export class TableFooterWithoutPagingComponent {

  @Input() selected = [];

  @Input() rows = [];

  @Input() table: DatatableComponent;

  constructor() {
  }

  onFooterPage($event) {
    this.table.onFooterPage($event);
  }

  /**
   * Wechselt die Ergebnisse pro Seite ueber eine Checkbox.
   *
   * @param size Size
   */
  setPageSize(size: number) {
    this.table.pageSize = size;
    this.table.limit = size;
  }
}
